import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { update_cart_action } from '../../redux/actions/cartAction';
import url, { colors, appShareUrl, sendingCostValue } from '../../config';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import placeholder from '../../assets/img/placeholder.png';
import { RWebShare } from 'react-web-share'
import FavoriteIcon from '@material-ui/icons/Favorite';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { alpha, Button, CircularProgress, Modal, Snackbar, Switch, ThemeProvider } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';
import DeleteProductModal from './DeleteProductModal';
import LinesEllipsis from 'react-lines-ellipsis';
import { useEffect } from 'react';
import { Cancel, ShoppingCartOutlined } from '@material-ui/icons';
import {IoBagCheck} from 'react-icons/io5';
import { LocalOffer } from '@material-ui/icons';
import { update_login_action } from '../../redux/actions/loginAction';
import b2_truck from '../../assets/promotional_banner/b2_truck.png'
import promo from '../../assets/img/promo.png'
import userPlaceholder from '../../assets/img/userPlaceholder.png'

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '100%',
    },
    media: {
        width: '100%',
    },
    mediaPlaceholder: {
        width: '100%',
        height: '300px'
    },
    title:{
        color: '#1D1D1D',
        fontSize: '14px',
        fontWeight: '600',
        marginBottom: '5px',
        marginTop: '0px'
    },
    storeName:{
        color: '#1D1D1D',
        fontSize: '13px',
        fontWeight: '500',
        paddingLeft: '5px'
    },
    profilePicture: {
        height: '15px',
        width: '15px',
        borderRadius: '50%'
    },
    price:{
        color: colors.secondary,
        fontSize: '15px',
        padding: '12px 0px',
        fontWeight: '700',
        marginBottom: '0px'
    },
    sendingCost:{
        color: colors.secondary,
        opacity: '0.9',
        fontSize: '13px',
        marginTop: '-2px',
        fontWeight: '700',
        marginBottom: '2px'
    },
    points:{
        fontSize: '12px',
        fontWeight: '600',
        marginBottom: '0px'
    },
    productSecondaryInfo: {
        fontSize: '11px',
        fontWeight: '600',
    },
    group: {
        width: '100%',
        backgroundColor: '#f9f9f9',
        borderRadius: '10px'
    },
    favorite: {
        color: colors.redHeart,
        padding: '8px',
        '&:hover': {
            color: colors.redHeart
        },
        [theme.breakpoints.down('sm')]:{
            padding: '3px',
        }
     },
     addCart: {
        color: colors.greenCart,
        padding: '8px',
        '&:hover': {
            color: colors.greenCart
        },
        [theme.breakpoints.down('sm')]:{
            padding: '3px',
        }
     },
    editIcon: {
        color: colors.orangeEdit,
        padding: '8px',
        '&:hover': {
            color: colors.orangeEdit
        },
        [theme.breakpoints.down('sm')]:{
            padding: '3px',
        }
    },
    deleteIcon: {
        color: colors.redError,
        padding: '8px',
        '&:hover': {
            color: colors.redError
        },
        [theme.breakpoints.down('sm')]:{
            padding: '3px',
        }
    },
    share: {
        color: colors.blueShare,
        padding: '8px',
        '&:hover': {
            color: colors.blueShare
        },
        [theme.breakpoints.down('sm')]:{
            padding: '3px',
            marginRight: '2px',
        }
    },
    productCard: {
        borderRadius: '10px'
    },
    categoryIcon: {
        position: 'absolute',
        width: '30px',
        height: '30px',
        backgroundColor: alpha('#f9f9f9',0.85),
        padding: '5px',
        borderTopLeftRadius: '10px',
        borderBottomRightRadius: '10px'
    },
    promoIcon: {
        position: 'absolute',
        width: '30px',
        height: '30px',
        // backgroundColor: alpha('#f9f9f9',0.5),
        padding: '5px',
        borderRadius: '10px',
        // borderTopLeftRadius: '10px',
        // borderBottomRightRadius: '10px',
        right: '0px'
    },
    minusCart: { 
        border: `solid 1.9px ${colors.greenCart}`,
        marginLeft: 'auto',
        borderRadius: '10px',
        width: '35px',
        borderRight: `solid 1px ${colors.greenCart}`,
        borderTopRightRadius: '0px',
        borderBottomRightRadius: '0px',
        fontSize: '22px',
        textAlign: 'center',
        lineHeight: '23px',
        fontWeight: '800',
        color: colors.greenCart,
        cursor: 'pointer',
        userSelect: 'none',
        '&:hover': {
            backgroundColor: alpha(colors.greenCart, 0.2)
        }
    },
    sumCart: {
        border: `solid 1.9px ${colors.greenCart}`,
        marginRight: '10px',
        borderRadius: '10px',
        width: '35px',
        borderLeft: `solid 1px ${colors.greenCart}`,
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
        fontSize: '22px',
        textAlign: 'center',
        lineHeight: '28px',
        fontWeight: '800',
        userSelect: 'none',
        color: colors.greenCart,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: alpha(colors.greenCart, 0.2)
        }
    },
    modalPaper: {
        position: 'absolute',
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        width: 400,
        borderRadius: '10px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    mainText: { 
        fontWeight: '600', 
        fontSize: '18px',
        marginTop: '10px'
    },
    addCartButton: {
        color: 'white',
        backgroundColor: colors.secondary,
        border: `solid 1.5px ${colors.secondary}`,
        width: '200px',
        marginRight: 'auto',
        '&:hover': {
            backgroundColor: alpha(colors.secondary,0.8)
        }
    },
    backButton: {
        color: colors.secondary,
        border: `solid 1.5px ${colors.secondary}`,
        '&:hover': {
            backgroundColor: alpha(colors.secondary,0.2)
        }
    },
    checkIcon: {
    width: '28px',
    height: '28px',
    },
    fastDeliveryIcon: {
        height: '13px',
        width: 'auto',
        marginRight: '2px',
        marginBottom: '0px'
    },
    fastDeliveryText: {
        fontSize: '11px', 
        fontWeight: '600',
        [theme.breakpoints.down('xs')]: {
            fontSize: '9px', 
            fontWeight: '700',
        }
    }
}));

export default function ProductCard({product, isFavorite, fromFavorites, updateFromFavorites, userId, reloadMyProducts, homePage}) {
    const classes = useStyles()
    const history = useHistory();
    const [loaded, setLoaded] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [isProductFavorite, setIsProductFavorite] = useState(isFavorite)
    const [addingFavorite, setAddingFavorite] = useState(false)
    const [openLoginNeededSnack, setOpenLoginNeededSnack] = useState(false)
    const [openDeleteSuccessSnack, setOpenDeleteSuccessSnack] = useState(false)
    const [openDeleteApiErrorSnack, setOpenDeleteApiErrorSnack] = useState(false)
    const [openSuccessSnack, setOpenSuccessSnack] = useState(false)
    const [availableChecked, setAvailableChecked] = React.useState(product.available)
    const [availableLoading, setAvailableLoading] = React.useState(false)

    const userLogged = useSelector((state) => state.user_store.user)

    const dispatch = useDispatch()

    const mediaDisplay = () => {
        setLoaded(true)
    }

    const closeDeleteModal = () => {
        setOpenDeleteModal(false)
    }

    const OpenDeleteSuccessSnack = () => {
        setOpenDeleteSuccessSnack(true)
        setOpenDeleteModal(false)
    }
    const OpenDeleteApiErrorSnack = () => {
        setOpenDeleteApiErrorSnack(true)
    }

    const AddToFavorite = (event) => {
        if(addingFavorite)return;
        
        if(userLogged){
            setAddingFavorite(true)
            const userId = userLogged._id
            const data = window.localStorage.getItem(`${userId}`)
            if(data){
                const newData = JSON.parse(data)
                newData[`${product._id}`] = product
                window.localStorage.setItem(
                    `${userId}`, JSON.stringify(newData)
                )
                setAddingFavorite(false)
                setIsProductFavorite(true)
            }
            else{
                const newData = {}
                newData[`${product._id}`] = product
                window.localStorage.setItem(
                    `${userId}`, JSON.stringify(newData)
                )
                setAddingFavorite(false)
                setIsProductFavorite(true)
            }
        }
        else{
            setOpenLoginNeededSnack(true)
            dispatch(update_login_action(true))
        }
    }

    const RemoveFromFavorite = () => {
        if(addingFavorite)return;
        
        if(userLogged){
            setAddingFavorite(true)
            const userId = userLogged._id
            const data = window.localStorage.getItem(`${userId}`)
            if(data){
                const newData = JSON.parse(data)
                if(Object.keys(newData).length === 1){
                    window.localStorage.removeItem(
                        `${userId}`, JSON.stringify(newData)
                    )
                }
                else{
                    if(newData[`${product._id}`]){
                        delete newData[`${product._id}`]
                    }
                    window.localStorage.setItem(
                        `${userId}`, JSON.stringify(newData)
                    )
                }
                if(fromFavorites){
                    updateFromFavorites()
                }
                setAddingFavorite(false)
                setIsProductFavorite(false)
            }
            else{
                setAddingFavorite(false)
                setIsProductFavorite(false)
            }
        }
        else{
            setOpenLoginNeededSnack(true)
            dispatch(update_login_action(true))
        }
        
    }

    
    const handleCloseLoginNeededSnack = () => {
        setOpenLoginNeededSnack(false)
        dispatch(update_login_action(true))
    }

    const handleCloseDeleteSuccessSnack = () => {
        setOpenDeleteSuccessSnack(false)
        reloadMyProducts()
    }

    const handleCloseDeleteApiErrorSnack = () => {
        setOpenDeleteApiErrorSnack(false)
    }

    const productOnClick = (event) => {
        history.push(`/products/detail/${product._id}`)
        window.scrollTo(0, 0);
    }

    const editProductOnClick = (event) => {
        history.push(`/myproducts/edit/${product._id}`)
        //window.scrollTo(0, 0);
    }

    const handleCloseSuccessSnack = () => {
        setOpenSuccessSnack(false)
    }

    const handleAvailableChange = async (event) => {
        if(availableLoading) return;
        setAvailableChecked(event.target.checked)
        setAvailableLoading(true)
        try{
            const accessToken = userLogged.accessToken 
            const requestOptions = {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' ,
                            'Authorization': `${accessToken}`},
                body: JSON.stringify({
                    _id : product._id,
                    available: true, 
                    availableChecked: event.target.checked
                })
            };
            let result = await fetch(`${url}/api/products/`, requestOptions)
            if(result.status === 200){
                setOpenSuccessSnack(true)
                setAvailableLoading(false)
            }
            else{
                setAvailableChecked(!(event.target.checked))
                setOpenDeleteApiErrorSnack(true)
                setAvailableLoading(false)
            }
        }   
        catch(e){
            setAvailableChecked(!(event.target.checked))
            setOpenDeleteApiErrorSnack(true)
            setAvailableLoading(false)
        }
    }

    const productDate = () => {
        const currentDate = new Date(product.lastUpload)
        return `${currentDate.getDate()}/${currentDate.getMonth()+1}/${currentDate.getFullYear()}`
    }

    const calcSendingCost = () => {
        const volumeWeight = product.large * product.width * product.height / 6000 
        if(volumeWeight < product.weight){
            return product.weight * sendingCostValue
        }
        return volumeWeight * sendingCostValue
    }

    const reduceDecimalValue = (number) => {
        if(Math.floor(number.valueOf()) === number.valueOf()) return number;
        const hasMoreDecimal = number.toString().split(".")[1].length || 0
        if(hasMoreDecimal > 2){
            return number.toFixed(2)
        }
        return number 
    }

    const getCertifiedColor = () => {
        if(product?.user?.certification === 'Tienda Oficial'){
            return colors.officialStore
        }
        if(product?.user?.certification === 'Premium'){
            return colors.premium
        }
        return 'white'
    }

    return (
        <Card elevation={4} className={classes.productCard}>
            <CardActionArea>
                <Snackbar open={openLoginNeededSnack} autoHideDuration={2000} onClose={handleCloseLoginNeededSnack}>
                    <Alert onClose={handleCloseLoginNeededSnack} severity="warning">
                        Debe iniciar sesión para realizar esta accion.
                    </Alert>
                </Snackbar>
                <Snackbar open={openDeleteSuccessSnack} autoHideDuration={1000} onClose={handleCloseDeleteSuccessSnack}>
                    <Alert onClose={handleCloseDeleteSuccessSnack} severity="success">
                        Se eliminó el anuncio.
                    </Alert>
                </Snackbar>
                <Snackbar open={openSuccessSnack} autoHideDuration={2000} onClose={handleCloseSuccessSnack}>
                    <Alert onClose={handleCloseSuccessSnack} severity="success">
                        Disponibilidad actualizada correctamente.
                    </Alert>
                </Snackbar>
                <Snackbar open={openDeleteApiErrorSnack} autoHideDuration={2000} onClose={handleCloseDeleteApiErrorSnack}>
                    <Alert onClose={handleCloseDeleteApiErrorSnack} severity="error">
                        No se pudo completar la acción. Revise su conexión a Internet.
                    </Alert>
                </Snackbar>
                <div style={{position: 'relative'}} onClick={productOnClick}>
                    <div style={{position: 'relative'}}>
                    <img src={product.category?.iconUrl} className={classes.categoryIcon}/>
                    
                    <CardMedia>
                        {loaded?
                            <LazyLoadImage
                            className={classes.media}
                            alt={product._id}
                            placeholderSrc={placeholder}
                            visibleByDefault={true}
                            effect="blur"
                            src={product.mainImageUrl} />
                        :
                            <LazyLoadImage
                            className={classes.mediaPlaceholder}
                            placeholderSrc={placeholder}
                            alt={product._id}
                            effect="blur"
                            afterLoad={mediaDisplay}
                            src={product.mainImageUrl} />
                        }
                    </CardMedia>
                    </div>
                    <CardContent>
                        <LinesEllipsis 
                            className={classes.title}
                            text={product.name}
                            maxLine='3'
                            ellipsis='...'
                            trimRight
                            basedOn='letters'
                        />
                        {(userId === -1 && product.available === false) &&
                            <Typography className={classes.title} style={{color: colors.orangeEdit}}>
                                No disponible por el momento
                            </Typography>
                        }
                        <div style={{display: 'flex', alignItems: 'center', marginBottom: product.user.certification === 'Tienda Oficial'? '5px' : '10px'}}>
                            <img src={product.user.profilePictureUrl? product.user.profilePictureUrl : userPlaceholder} 
                                className={classes.profilePicture}/>
                            <Typography className={classes.storeName}>{product.user.username}</Typography>
                        </div>
                        {product.user.certification === 'Tienda Oficial' &&
                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
                            <IoBagCheck 
                                className={classes.profilePicture} style={{color: colors.officialStore}}/>
                            <Typography className={classes.storeName} style={{color: colors.officialStore}}>Tienda Oficial</Typography>
                        </div>
                        }
                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '-25px'}}>
                            <Typography style={{ textAlign: 'end', paddingRight: '2px', maxWidth: 'calc(100% - 64px)', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} className={classes.productSecondaryInfo}>
                                {product.region}
                            </Typography>
                            |
                            <Typography style={{marginRight: 'auto', paddingLeft: '2px'}} className={classes.productSecondaryInfo}>
                                {productDate()}
                            </Typography>
                        </div>
                    </CardContent>
                </div>
            </CardActionArea>
            <CardActions style={{display: 'block'}} disableSpacing>
            {userLogged?._id === product.user._id?
            <React.Fragment>
                <DeleteProductModal openDeleteModal={openDeleteModal} productId={product._id} productName={product.name} setOpenSuccesSnack={OpenDeleteSuccessSnack} setOpenApiErrorSnack={OpenDeleteApiErrorSnack} closeDeleteModalParent={closeDeleteModal}/>
                {!homePage &&
                    <div style={{display: 'flex', marginBottom: '5px'}}>
                        <Typography style={{paddingTop: '5px', marginLeft: '10px', marginRight: 'auto'}}>Disponible</Typography>
                        {availableLoading && 
                            <CircularProgress style={{height:'20px', width:'20px', marginTop:'10px', marginLeft:'9px'}}/>
                        }
                        <Switch
                            checked={availableChecked}
                            onChange={handleAvailableChange}
                            color="primary"
                            name="availableChecked"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </div>
                    }
                <Grid container alignItems="center" justifyContent={"flex-end"} className={classes.group}>
                    
                    <Grid style={{marginRight: 'auto', marginLeft: '10px'}} item>
                        <Typography className={classes.price}>{`${product.price} ${product.currency}`}</Typography>
                    </Grid>
                    
                    <Grid item>
                        <IconButton
                            className={classes.editIcon}
                            aria-label="edit"
                            onClick={editProductOnClick}>
                            <EditIcon  />
                        </IconButton>
                    </Grid>
                    {!homePage &&
                        <Grid item>
                            <IconButton
                                className={classes.deleteIcon}
                                aria-label="delete"
                                onClick={(event) => {setOpenDeleteModal(true)}}>
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    }
                    
                    <Grid item>
                        <RWebShare
                            data={{
                                url: `${appShareUrl}/products/detail/${product._id}`,
                                title: "SeVende",
                            }}
                            closeText="Cerrar"
                        >
                            <IconButton className={classes.share} aria-label="share">
                                <ShareOutlinedIcon />
                            </IconButton>
                        </RWebShare>
                    </Grid>
                </Grid>
            </React.Fragment>
            :
                <Grid container alignItems='center' justifyContent="flex-end" className={classes.group}>
                    
                    <Grid style={{marginRight: 'auto', marginLeft: '10px'}} item>
                        <Typography className={classes.price}>{`${product.price} ${product.currency}`}</Typography>
                    </Grid>
                    
                    <Grid item>
                        {isProductFavorite?
                        <IconButton
                            className={classes.favorite}
                            onClick={RemoveFromFavorite}
                            aria-label="remove from favorites">
                            <FavoriteIcon  />
                        </IconButton>
                        :
                        <IconButton
                            className={classes.favorite}
                            onClick={AddToFavorite}
                            aria-label="add to favorites">
                            <FavoriteBorderOutlinedIcon  />
                        </IconButton>
                        }
                    </Grid>
                    
                    <Grid item>
                        <RWebShare
                            data={{
                            url: `${appShareUrl}/products/detail/${product._id}`,
                            title: "SeVende",
                        }}
                        >
                            <IconButton className={classes.share} aria-label="share">
                                <ShareOutlinedIcon />
                            </IconButton>
                        </RWebShare>
                    </Grid>
                </Grid>
            }
            </CardActions>
        </Card>
    );
}