import { UPDATE_ORDER_PDF } from "../actions/orderPdfAction";

const default_order = {
    order: {}
}

function order_pdf_store(state = default_order, action){
    switch(action.type) {
        case UPDATE_ORDER_PDF: {
            return{
                order: action.payload
            }
        }
        default: return state;
    }
}

export default order_pdf_store