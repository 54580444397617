import { makeStyles, createTheme, withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import React, {useState, useEffect, useRef} from 'react';
import { alpha, Avatar, Badge, Typography } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import logo from '../../assets/img/splash_logo.svg';
import category from '../../assets/img/category.svg';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button'
import { IconButton, Checkbox } from '@material-ui/core';
import UserLoggedIn from '@material-ui/icons/AccountCircle'
import url, { colors, supportNumber } from '../../config'
import { BsFillFunnelFill } from "react-icons/bs"
import '../../assets/css/layout.css'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { BsGridFill } from 'react-icons/bs'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EmailIcon from '@material-ui/icons/Message';
import FavoriteIcon from '@material-ui/icons/Favorite';
import CloseIcon from '@material-ui/icons/Close'
import DehazeIcon from '@material-ui/icons/Dehaze';
import ListIcon from '@material-ui/icons/ListAlt'
import { Apps, Assignment, Group, Help, Home, Inbox, Info, Message, MonetizationOn, Person, Send } from '@material-ui/icons';
import { Divider } from '@material-ui/core';
import LoginRegister from './LoginRegister';
import MuiAlert from '@material-ui/lab/Alert';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Tooltip } from '@material-ui/core';
import WarningAmberIcon from '@material-ui/icons/PriorityHigh'
import { Snackbar } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux'
import { unread_messages_action } from '../../redux/actions/socketAction';
import { v4 as uuidv4 } from 'uuid'
import PageVisibility from 'react-page-visibility'
import vende_button from '../../assets/img/vende.svg'
import { update_products_action, update_search_params_action } from '../../redux/actions/productsAction';
import { remove_user_action } from '../../redux/actions/userAction';
import { update_login_action } from '../../redux/actions/loginAction';
import { Skeleton } from '@material-ui/lab';
import AttachMoneyIcon from '@material-ui/icons/MonetizationOn';

const StyledBadge = withStyles((theme) => ({
    badge: {
      right: 0,
      top: 0,
      border: `0px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
      backgroundColor: alpha(colors.redError,0.85)
    },
  }))(Badge);

const themeMUI = createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          color: "white",
          backgroundColor: "rgba(251,82,100,0.85)",
          borderTop: 'solid 2px rgb(251,82,100)'
        }
      }
    }
  });

  const GreenCheckbox = withStyles({
    root: {
      height: '14px',
      width: '14px',
      color: colors.secondary,
      '&$checked': {
        color: colors.secondary,
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));

const useStyles = makeStyles((theme) => ({
    page: {
        background: '#fefbfb',
        width: '100%'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: colors.primary
    },
    mainToolbar: {
        minHeight: 65,
        [theme.breakpoints.down('sm')]: {
            minHeight:'45px',
            height: '45px'    
          },
    },
    smallToolbar: {
        height: '50px',
        minHeight: '50px',
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'flex'
          },
    },
    toolbar: theme.mixins.toolbar,
    secondToolbar: {
        backgroundColor: '#f3f3f3',
        minHeight: 45,
        height: 45,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
          },
    },
    appBarTab: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: `${colors.whiteAppbar} !important`,
        height: '50px',
        top: 'auto',
        bottom: '0',
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    tabToolbar: {
        minHeight: '50px',
        height: '50px',
        padding: '0px',
        alignItems: 'center'
    },
    tabElement: {
        flex: 1, 
        display: 'flex',
        height: '50px',
        alignItems: 'center',
        cursor: 'pointer'
    },
    tabIcon: {
        height: '28px',
        width: '28px'
    },
    logo: {
        cursor: 'pointer',
        height: '32px',
        width: 'auto',
        marginLeft: '46px',
        marginRight: '76px',
        [theme.breakpoints.down('sm')]: {
            marginRight: 'auto', 
            marginLeft: '10px',
            height: '25px', 
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: 'auto'    
        },
    },
    SearchAndFilter:{
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
          },
    },
    SearchAndFilterSmall:{
        display: 'none',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            display: 'flex'
          },
    },
    search: {
        display: 'flex',
        borderRadius: '3px',
        height: 35, 
        backgroundColor: 'white',
        boxShadow: '4px 4px 2px -2px rgba(0,0,0,.2)',
        '&:hover': {
            boxShadow: '4px 4px 4px 0 rgba(0,0,0,.2)',
        },
        '&:focus': {
            boxShadow: '4px 4px 4px 0 rgba(0,0,0,.2)',
        },
        marginRight: 4,
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('md')]: {
          width: 'auto',
        },
        [theme.breakpoints.down('md')]: {
            marginRight: 4    
        },
    },
    searchIcon: {
        padding: '0px 8px',
        height: '100%',
        //position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        cursor: 'pointer',
        backgroundColor: colors.secondary,
        borderTopRightRadius: '3px',
        borderBottomRightRadius: '3px',
        '&:hover': {
            backgroundColor: alpha(colors.secondary, 0.85),
          },
        [theme.breakpoints.down('sm')]:{
            padding: '0px 8px',
        }
    },
    inputRoot: {
        color: 'inherit',
        width: '100%'
    },
    inputInput: {
        height: 22,
        fontSize: '12px',
        fontWeight: 400,
        paddingRight: '12px',
        // vertical padding + font size from searchIcon
        paddingLeft: `18px`,
        transition: theme.transitions.create('width'),
        width: '100%',
        '&::placeholder': {
            textAlign: 'center',
            color: colors.secondaryDark,
            opacity: 1, /* Firefox */
            letterSpacing: '6px'  
        },
        /* or, for legacy browsers */
        
        '&::-webkit-input-placeholder': {
            textAlign: 'center'
        },
        '&:-moz-placeholder': { /* Firefox 18- */
            textAlign: 'center'  
        }, 
        '&::-moz-placeholder': {  /* Firefox 19+ */
            textAlign: 'center'  
        },
        '&:-ms-input-placeholder': {  
            textAlign: 'center' 
        },
        [theme.breakpoints.down('sm')]:{
            paddingLeft: '18px'
        },
        [theme.breakpoints.up('md')]: {
            width: '50ch',
        },
          
          '&:-ms-input-placeholder': { /* Internet Explorer 10-11 */
            color: colors.secondaryDark
          },
          
          '&::-ms-input-placeholder': { /* Microsoft Edge */
            color: colors.secondaryDark
          },
    },
    navbarButton: {
        backgroundColor: colors.secondary,
        '&:hover': {
          backgroundColor: alpha(colors.secondary, 0.85),
        },
        color: colors.secondaryDark,
        height: 35,
        marginRight: 4,
    },
    sellButton: {
        backgroundColor: '#ffffff',
        '&:hover': {
          backgroundColor: '#fefefe',
        },
        height: 35,
        marginRight: '5px',
        marginLeft: 'auto',
        fontWeight: 600,
        borderRadius: '3px',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
          },
    },
    sellButtonHidden: {
        marginLeft: 'auto',
        [theme.breakpoints.down('xs')]: {
            marginLeft: '0px'
          },
    },
    filterIcon:{
        transform: 'scaleX(-1)',
        width: '1.1em',
        height: '1.1em'
    },
    navbarUserIcon: {
        color: '#ffffff',
        height: '18px',
        width: 'auto'
    },
    sellIconOptions: {
        color: colors.secondary,
    },
    dropDownIcon: {
        color: 'rgba(0, 0, 0, 0.87)',
        cursor: "text",
        zIndex: "10"
    },
    label: {
        textTransform: 'capitalize',
        fontWeight: 600,
    },
    labelProvince: {
        textTransform: 'capitalize',
        fontWeight: 400,
        fontSize: '16px',
        whiteSpace: 'nowrap'
    },
    endButton: {
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        borderRadius: '10',
        fontWeight: 600 
    },
    darkGrayButton: {
        backgroundColor: colors.secondary,
        '&:hover': {
          backgroundColor: alpha(colors.secondary, 0.85),
        },
        color: '#ffffff',
        height: 35,
        marginRight: 4
    },
    primaryButton: {
        backgroundColor: colors.secondary,
        '&:hover': {
          backgroundColor: alpha(colors.secondary, 0.85),
        },
        borderRadius: '3px',
        color: '#ffffff',
        height: 35,
        marginRight: '10px',
        marginLeft: '5px'

    },
    secondaryButton: {
        borderRadius: '3px',
        '&:hover': {
          backgroundColor: alpha(colors.secondary, 0.15),
        },
        marginLeft: 'auto',
        border: `solid 1px ${colors.secondary}`,
        color: colors.secondary,
        height: 35,
        marginRight: 4
    },
    filterButton: {
        padding:0,
        minWidth:38,
        borderRadius: '3px'
    },
    categoriesIcon: {
        color: "#ffffff",
        width: "1.3em",
        height: "1.3em",
    },
    categoriesButton: {
        minWidth: 38,
        backgroundColor: colors.secondary,
        borderRadius: '3px',
        padding: '7px',
        [theme.breakpoints.up('md')]: {
            display: 'none'
          },
        "&:hover": { 
            backgroundColor: alpha(colors.secondary, 0.85),
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
        },
    },
    optionsIcon: {
        color: "#ffffff",
        width: "0.9em",
        height: "0.9em",
    },
    optionsButton: {
        minWidth: 38,
        backgroundColor: colors.secondary,
        borderRadius: '3px',
        marginRight: '6px',
        [theme.breakpoints.down('md')]: {
            marginRight: 0    
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
        "&:hover": { 
            backgroundColor: alpha(colors.secondary, 0.85),
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
        },  
    },
    loginButton: {
        color: 'white',
        backgroundColor: colors.secondary,
        fontWeight: 600,
        marginRight: '5px',
        borderRadius: '3px',
        textTransform: 'capitalize',
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
        "&:hover": { 
            backgroundColor: alpha(colors.secondary, 0.85),
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
        },
        [theme.breakpoints.down('md')]: {
            marginRight: 0    
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
          },
    },
    messageButton: {
        borderRadius: '3px',
        backgroundColor: colors.secondary,
        marginRight: '5px',
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
        "&:hover": { 
            backgroundColor: alpha(colors.secondary, 0.85),
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
          },
    },

    //Categories Style
    
    categoryDrawerStyle:{
        width: 250
    },
    drawerTextStyle:{
        display: "block",
        width:"100%", 
        textAlign:"center", 
        lineHeight: "56px", 
        fontWeight:"600", 
        fontSize:"16px",
        color: "#ffffff", 
        backgroundColor:colors.primary, 
        height: "56px"
    },
    arrow: {
        fontSize: 20,
        color: "#4A4A4A",
        "&::before": {
          backgroundColor: colors.redError,
        }
    },
    inputHolder: {
        display: 'flex',
        width: '70px',
        marginRight: '15px',
        backgroundColor:colors.secondary,
        height: '25px', 
        boxShadow: '1px 1px 1px #D4D4D4', 
        borderRadius: '3px'
    },
    currencyHolder: {
        display: 'flex',
        height: '25px',
        backgroundColor:colors.secondary, 
        boxShadow: '1px 1px 1px #D4D4D4', 
        borderRadius: '3px'
    },
    inputRootLogin: {
        width: '100%',
    },
    inputInputLogin: {
        height: 22,
        fontSize: '0.875rem',
        fontWeight: 400,
        // vertical padding + font size from searchIcon
        transition: theme.transitions.create('width'),
        width: '100%',
        paddingLeft: '10px',
        paddingRight: '10px',
        color: 'white',
        '&::placeholder': { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: 'white',
            textAlign: 'center',
            opacity: 1 /* Firefox */
          },
          
          '&:-ms-input-placeholder': { /* Internet Explorer 10-11 */
            color: 'white',
            textAlign: 'center',
          },
          
          '&::-ms-input-placeholder': { /* Microsoft Edge */
            color: 'white',
            textAlign: 'center',
          },
    },
    errorButton: {
        color: colors.redError,
        padding: '3px',
        marginTop:'5px',
        "&:hover, &.Mui-focusVisible": { 
            backgroundColor: colors.redErrorOpacity,
        }
    },
    menuIconUserOptions: {
        width: '20px', 
        height: '20px',
        marginRight: '5px'
    },
    filterPopup: {
        display:'flex', 
        marginBottom:'10px',
        [theme.breakpoints.down('xs')]: {
            display: 'block'
          },
    },
    filterPopupSecond: {
        display:'flex', 
        marginBottom:'10px',
        [theme.breakpoints.down('xs')]: {
            display: 'block'
          },
    },
    smallSizeFilter: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
          },
    },
    longSizeFilter: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block'
          }, 
    },
    filtersHolder: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
          },
    },
    filterDrawerIcon: {
        color:colors.secondary, 
        height: '18px', 
        width: '18px', 
        margin: '10px auto 10px auto',
        [theme.breakpoints.down('sm')]: {
            margin: '10px 5px 10px 10px'
          },
    },
    filterDrawerText: {
        color:colors.secondary,
        [theme.breakpoints.up('md')]: {
            display: 'none'
          }, 
    },
    stateFilterHolder: {
        display: 'block',
        [theme.breakpoints.down('xs')]: {
            display: 'block'
          },
    },
    stateFilterFirst: {
        display: 'block',
        // [theme.breakpoints.down('sm')]: {
        //     display: 'block'
        //   },
    },
    stateFilterSecond: {
        display: 'block',
        // [theme.breakpoints.down('sm')]: {
        //     display: 'block'
        //   },
    },
    asNewFilter: {
        padding:'0px 3px 0px 0px',
        [theme.breakpoints.down('xs')]: {
            padding:'0px 3px 0px 22px',
          },
    },
    smallSizeFilterMargin: {
        marginLeft: '0px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '25px'
          },
    },
    selectedProvince: {
        height: '11px',
        width: '11px',
        border: `solid 1px ${colors.secondary}`,
        backgroundColor: colors.secondary,
        marginLeft: '1px'
    },
    unselectedProvince: {
        height: '13px',
        width: '13px',
        border: `solid 1px ${colors.secondary}`
    },
    filterDivider: {
        backgroundColor: colors.secondary, 
        height: '360px', 
        width: '1px', 
        marginLeft: '25px', 
        marginRight: '25px', 
        marginTop: '40px',
        marginBottom: '0px',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
          },
    },
    categoryActive: {
        backgroundColor: `${colors.secondary} !important`,
        color: 'white'
    },
}))

function ElevationScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    });
  
    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    });
} 

const skeleton = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18]
const provinces = [
    'Todas las Provincias',
    'Artemisa',
    'Camagüey',
    'Ciego de Ávila',
    'Cienfuegos',
    'Granma',
    'Guantánamo',
    'Holguín',
    'Isla de la Juventud',
    'La Habana',
    'Las Tunas',
    'Matanzas',
    'Mayabeque',
    'Pinar del Río',
    'Sancti Spíritus',
    'Santiago de Cuba',
    'Villa Clara'
]
const ITEM_HEIGHT = 48;

const currencies = ["TODAS","MN", "USD", "MLC"]

const Navbar = ({props, setAccess, isHomePage, isFavoritesPage, isSellPage, isConversationsPage, isAccountPage}) => {
    const history = useHistory();
    const classes  = useStyles()
    const location = useLocation()
    const locationUseRef = useRef()
    locationUseRef.current = location
    const [data, setData] = useState([])
    const [searchField, setSearchField] = useState("")

    const [minPriceError, setMinPriceError] = useState("")
    const [minPriceErrorOpen, setMinPriceErrorOpen] = useState(false)
    const [maxPriceError, setMaxPriceError] = useState("")
    const [maxPriceErrorOpen, setMaxPriceErrorOpen] = useState(false)

    const [anchorElCurrency, setAnchorElCurrency] = useState(null);
    const openCurrency = Boolean(anchorElCurrency);
    const [newFilterChecked, setNewFilterChecked] = useState(true)
    const [asNewFilterChecked, setAsNewFilterChecked] = useState(true)
    const [smallUseFilterChecked, setSmallUseFilterChecked] = useState(true)
    const [highUseFilterChecked, setHighUseFilterChecked] = useState(true)
    const [categoriesLoading, setCategoriesLoading] = useState(true)

    const [paramsLoading, setParamsLoading] = useState(true)
   
    const [categoryDrawer, setCategoryDrawer] = useState({left: false, right: false})

    const [anchorElUserOptions, setAnchorElUserOptions] = useState(null);
    const openUserOptions = Boolean(anchorElUserOptions);

    const [anchorElFilter, setAnchorElFilter] = useState(null);
    const openFilter = Boolean(anchorElFilter);

    const [anchorElCategories, setAnchorElCategories] = useState(null);
    const openCategories = Boolean(anchorElCategories);

    const [anchorElDrawer, setAnchorElDrawer] = useState(null);
    const openDrawer = Boolean(anchorElDrawer);

    const [openSuccesSnack, setOpenSuccesSnack] = useState(false)
    const [openApiErrorSnack, setOpenApiErrorSnack] = useState(false)
    const [openRegisterSuccesSnack, setOpenRegisterSuccesSnack] = useState(false)
    const [openRegisterApiErrorSnack, setOpenRegisterApiErrorSnack] = useState(false)

    const socket = useRef()

    const searchParamsToExecute = useSelector((state) => state.products_store.searchParameters)
    const searchParamsToExecuteUseRef = useRef()
    searchParamsToExecuteUseRef.current = searchParamsToExecute
    const socket_store_selector = useSelector((state) => state.socket_store.socket)
    const hasNewMessages = useSelector((state) => state.socket_store.newMessages)
    const user = useSelector((state) => state.user_store.user)
    const dispatch = useDispatch()

    // #region UseEffect

    useEffect(() => {
        const getInitialQueryParams = () => {
            try{
                const newParams = getQueryParams() 
                if(newParams.queryHasParameters){
                    newParams.onBackPressed = false
                    dispatch(update_search_params_action(newParams.params))
                }
                setParamsLoading(false)
            } catch(error){
                console.log(`ENCONTRE ERROR ${error}`)
                setParamsLoading(false)
            }
        }
        getInitialQueryParams() 
    }, [])

    useEffect(() => {
        if(isHomePage){
            manageParams()
        }
    }, [searchParamsToExecute])

    useEffect(() => {
        const fetchCategories = async () => {
            try{
                let result = await fetch(`${url}/api/categories`)
                let dataResult = await result.json()
                let dataResultWithAll = [{id: 0, name: "Todos", iconUrl: category}]
                setData(dataResultWithAll.concat(dataResult))
                setCategoriesLoading(false)
            } catch(error){
                console.log({error})
            }
        }
        fetchCategories() 
    }, [])

    useEffect(() => {
        const fetchUser = async () => {
            if(user)
            { 
                try{
                    const messageEventHandler = () => {
                        dispatch(unread_messages_action())
                    }
                    if(false){
                    }
                    else{
                        if(socket_store_selector){
                            socket.current = socket_store_selector
                            socket.current.on("getMessage", messageEventHandler)
                        }
                        const requestOptions = {
                            method: 'GET',
                            headers: { 'Content-Type': 'application/json',
                                        'Authorization': `${user.accessToken}`}
                            };
                        let result = await fetch(`${url}/api/conversations/unreadMessages`, requestOptions);
                        console.log(result)
                        if(result.status === 200){
                            let newData = await result.json();
                            if(newData.unreadMessages){
                                dispatch(unread_messages_action())
                            }
                        }
                        return () => {
                            if(socket_store_selector){
                                socket.current = socket_store_selector
                                
                                socket.current.on("getMessage", messageEventHandler)
                            }
                            socket.current.off("getMessage", messageEventHandler)
                        };
                    }
                }
                catch(e){
                    console.log(e)
                }
            }
        }
        fetchUser() 
    }, [user])

    useEffect(() => {
        if(isHomePage){
            window.addEventListener('popstate', (event) => {
                onBackPressedDetect(event)
            })
            return (
                window.removeEventListener('popstate', (event) => {
                    onBackPressedDetect(event)  
                })
            )
        }
    }, []);

    const onBackPressedDetect = (event) => {
        if(detectChange()){
            dispatch(update_products_action([], 0, false))
            const newParams = getQueryParams()
            newParams.params.onBackPressed = true 
            dispatch(update_search_params_action(newParams.params))   
        }
    }

    const detectChange = () => {
        const search = locationUseRef.current.search
        const queryParams = new URLSearchParams(search)
        let oldParams = {...searchParamsToExecuteUseRef.current}
        if (queryParams.has('searchPhrase')) {
            if(oldParams.searchPhrase !== queryParams.get('searchPhrase')){
                return true
            }
        }
        else{
            if(oldParams.searchPhrase !== '') {
                return true
            }
        }
        if (queryParams.has('categoryId')) {
            if(oldParams.selected !== queryParams.get('categoryId')){
                return true
            }
        }
        else{
            if(oldParams.selected !== '0'){
                return true
            }
        } 
        return false
    }

    const categoryOnClick = (Id) => {
        if(isHomePage){
            dispatch(update_products_action([], 0, false))
            let params = {...searchParamsToExecute}
            params.page = 1
            params.selected = Id
            params.onBackPressed = false

            dispatch(update_search_params_action(params))
            return
        }
        dispatch(update_products_action([], 0, false))
        let store_params = {...searchParamsToExecute}
        store_params.page = 1
        store_params.selected = Id
        store_params.minPrice = ''
        store_params.maxPrice = ''
        store_params.selectedCurrency = 'TODAS'
        store_params.selectedProvince = 'Todas las Provincias'
        store_params.searchPhrase = ''
        store_params.statusToSearch = ["Nuevo", "Como Nuevo", "Poco uso", "Usado"]
        dispatch(update_search_params_action(store_params))
        history.push(`/?categoryId=${Id}`)
    }

    const getQueryParams = () => {
        const search = locationUseRef.current.search
        const queryParams = new URLSearchParams(search)
        let queryHasParameters = false
        let newParams = {...searchParamsToExecute}
        if (queryParams.has('searchPhrase')) {
            newParams.searchPhrase = queryParams.get('searchPhrase')
            setSearchField(queryParams.get('searchPhrase'))
            queryHasParameters = true
        }
        else{
            setSearchField("")
            newParams.searchPhrase = ''
        }
        if (queryParams.has('minPrice')) {
            newParams.minPrice = queryParams.get('minPrice')
            queryHasParameters = true
        }
        if (queryParams.has('maxPrice')) {
            newParams.maxPrice = queryParams.get('maxPrice')
            queryHasParameters = true
        }
        if(queryParams.has('status')) {
            const statusParam = queryParams.get('status').trim().split(",")
            newParams.statusToSearch = queryParams.get('status').trim().split(",")
            if(!statusParam.find(c => c === "Nuevo")) setNewFilterChecked(false)
            if(!statusParam.find(c => c === "Como nuevo")) setAsNewFilterChecked(false)
            if(!statusParam.find(c => c === "Poco uso")) setSmallUseFilterChecked(false)
            if(!statusParam.find(c => c === "Usado")) setHighUseFilterChecked(false)
            queryHasParameters = true
        }
        if(queryParams.has('currency')){
            newParams.selectedCurrency = queryParams.get('currency')
            queryHasParameters = true
        }
        if (queryParams.has('categoryId')) {
            newParams.selected = queryParams.get('categoryId')
            queryHasParameters = true
        }
        else{
            newParams.selected = "0"
        }
        if (queryParams.has('region')) {
            newParams.selectedProvince = queryParams.get('region')
            queryHasParameters = true
        } 
        return {
            queryHasParameters: queryHasParameters,
            params: newParams
        }
    }

    const categoryMainDrawerList = () => (
        <div className={classes.categoryMainDrawerStyle}
          role="presentation"
          onKeyDown={toggleDrawer(false, 'left')}
          onClick={toggleDrawer(false, 'left')}
        >
          <List style={{paddingTop: "0px"}}>
            {categoriesLoading === true?
                [skeleton.map((skl) => {
                    return(
                    <ListItem style={{paddingTop: '2px', paddingBottom: '2px'}} button alignItems="center" key={skl}>
                        <Skeleton style={{height: '20px', width: '150px'}} />
                    </ListItem>
                    )      
                })]
            :
                [data.map((category) => (
                <ListItem style={{paddingTop: '2px', paddingBottom: '2px'}} button alignItems="center" selected={searchParamsToExecute.selected === category._id} key={category._id} onClick={() => {categoryOnClick(category._id)}} className={(category._id === searchParamsToExecute.selected)? classes.categoryActive : ""}>
                    <ListItemIcon style={{minWidth: '30px'}}><img alt="" src={category.iconUrl} style={{width:"16px", height:"16px"}}/></ListItemIcon>
                    <Typography style={{color: searchParamsToExecute.selected === category._id? 'white' : colors.secondary, fontSize: '11px', fontWeight: '600'}}>
                        {category.name.toUpperCase()}
                    </Typography>
                </ListItem>
                ))]
            }
          </List>
        </div>
      );

    
    // #endregion

    const handleChangeMinPrice = (event) => {
        if(minPriceError){
            setMinPriceError("")
            handleMinPriceErrorTooltipClose()
        }
        dispatch(update_products_action([], 0, false))
        let params = {...searchParamsToExecute}
        params.page = 1
        params.minPrice = event.target.value
        params.onBackPressed = false
        dispatch(update_search_params_action(params))
        if(event.target.value){
            if(!parseInt(event.target.value)){
                setMinPriceError('El Precio debe ser un numero')
                handleMinPriceErrorTooltipOpen()
            }
        }
    };
    const handleChangeMaxPrice = (event) => {
        if(maxPriceError){
            setMaxPriceError("")
            handleMaxPriceErrorTooltipClose()
        }
        dispatch(update_products_action([], 0, false))
        let params = {...searchParamsToExecute}
        params.page = 1
        params.maxPrice = event.target.value
        params.onBackPressed = false
        dispatch(update_search_params_action(params))
        if(event.target.value){
            if(!parseInt(event.target.value)){
                setMaxPriceError('El Precio debe ser un numero')
                handleMaxPriceErrorTooltipOpen()
            }
        }
    };
    
    // #region Snack Events
    const handleCloseSuccesSnack = () => {
        setOpenSuccesSnack(false)
        window.location.reload(false);
    }
    const handleCloseApiErrorSnack = () => {
        setOpenApiErrorSnack(false)
    }
    const handleCloseRegisterSuccesSnack = () => {
        setOpenRegisterSuccesSnack(false)
    }
    const handleCloseRegisterApiErrorSnack = () => {
        setOpenRegisterApiErrorSnack(false)
    }
    const OpenSuccessSnack = () => {
        setOpenRegisterSuccesSnack(false)
        setOpenSuccesSnack(true)
        if(setAccess)setAccess()
    }
    const OpenApiErrorSnack = () => {
        setOpenApiErrorSnack(true)
    }
    const OpenRegisterSuccessSnack = () => {
        setOpenRegisterSuccesSnack(true)
    }
    const OpenRegisterApiErrorSnack = () => {
        setOpenRegisterApiErrorSnack(true)
    }

    // #endregion

    const handleCloseFilter = () => {
        handleMinPriceErrorTooltipClose()
        handleMinPriceErrorTooltipClose()
        setAnchorElFilter(null);
    };

    // #region OTHERS
    const handleCloseCategories = (Id) => {
        setAnchorElCategories(null);
        if (Id !== null){
            dispatch(update_products_action([], 0, false))
            let params = {...searchParamsToExecute}
            params.page = 1
            params.selected = Id
            params.onBackPressed = false
            dispatch(update_search_params_action(params))
        }
    };
    const handleCloseDrawer = (name) => {
        setAnchorElDrawer(null);
        if (name !== null){
            dispatch(update_products_action([], 0, false))
            let params = {...searchParamsToExecute}
            params.page = 1
            params.selectedProvince = name
            params.onBackPressed = false
            dispatch(update_search_params_action(params))  
        }
    };

    const handleMinPriceErrorTooltipClose = () => {
        setMinPriceErrorOpen(false);
    };
    const handleMaxPriceErrorTooltipClose = () => {
        setMaxPriceErrorOpen(false);
    };
    const handleMinPriceErrorTooltipOpen = () => {
        setMinPriceErrorOpen(true);
    };
    const handleMaxPriceErrorTooltipOpen = () => {
        setMaxPriceErrorOpen(true);
    };

    const handleCurrencyClick = (event) => {
        setAnchorElCurrency(event.currentTarget);
      };
    const handleCurrencyClose = (currency) => {
        setAnchorElCurrency(null);
        if (currency !== null){
            dispatch(update_products_action([], 0, false))
            let params = {...searchParamsToExecute}
            params.page = 1
            params.selectedCurrency = currency
            params.onBackPressed = false
            dispatch(update_search_params_action(params))   
        }
    };

    const handleNewFilterChange = (event) => {
        let newStatusToSearch = []
        if((event.target.checked && asNewFilterChecked && smallUseFilterChecked && highUseFilterChecked)){

        }
        else{
            if(event.target.checked)newStatusToSearch.push("Nuevo")
            if(asNewFilterChecked)newStatusToSearch.push("Como nuevo")
            if(smallUseFilterChecked)newStatusToSearch.push("Poco uso")
            if(highUseFilterChecked)newStatusToSearch.push("Usado")
        }
        dispatch(update_products_action([], 0, false))
        let params = {...searchParamsToExecute}
        params.page = 1
        params.statusToSearch = newStatusToSearch
        params.onBackPressed = false
        dispatch(update_search_params_action(params))
        setNewFilterChecked(event.target.checked)
      }

      const handleAsNewFilterChange = (event) => {
        let newStatusToSearch = []
        if((newFilterChecked && event.target.checked && smallUseFilterChecked && highUseFilterChecked)){

        }
        else{
            if(newFilterChecked)newStatusToSearch.push("Nuevo")
            if(event.target.checked)newStatusToSearch.push("Como nuevo")
            if(smallUseFilterChecked)newStatusToSearch.push("Poco uso")
            if(highUseFilterChecked)newStatusToSearch.push("Usado")
        }
        dispatch(update_products_action([], 0, false))
        let params = {...searchParamsToExecute}
        params.page = 1
        params.statusToSearch = newStatusToSearch
        params.onBackPressed = false
        dispatch(update_search_params_action(params))
        setAsNewFilterChecked(event.target.checked)
      }

      const handleSmallUseFilterChange = (event) => {
        let newStatusToSearch = []
        if((newFilterChecked && asNewFilterChecked && event.target.checked && highUseFilterChecked)){

        }
        else{
            if(newFilterChecked)newStatusToSearch.push("Nuevo")
            if(asNewFilterChecked)newStatusToSearch.push("Como nuevo")
            if(event.target.checked)newStatusToSearch.push("Poco uso")
            if(highUseFilterChecked)newStatusToSearch.push("Usado")
        }
        dispatch(update_products_action([], 0, false))
        let params = {...searchParamsToExecute}
        params.page = 1
        params.statusToSearch = newStatusToSearch
        params.onBackPressed = false
        dispatch(update_search_params_action(params))
        setSmallUseFilterChecked(event.target.checked)
      }

      const handleHighUseFilterChange = (event) => {
        let newStatusToSearch = []
        if((newFilterChecked && asNewFilterChecked && smallUseFilterChecked && event.target.checked)){

        }
        else{
            if(newFilterChecked)newStatusToSearch.push("Nuevo")
            if(asNewFilterChecked)newStatusToSearch.push("Como nuevo")
            if(smallUseFilterChecked)newStatusToSearch.push("Poco uso")
            if(event.target.checked)newStatusToSearch.push("Usado")
        }
        dispatch(update_products_action([], 0, false))
        let params = {...searchParamsToExecute}
        params.page = 1
        params.statusToSearch = newStatusToSearch
        params.onBackPressed = false
        dispatch(update_search_params_action(params))
        setHighUseFilterChecked(event.target.checked)
      }
    // #endregion

    const manageParams = () => {
        const queryParams = {}
        let hasParameters = false
        if (searchField) {
            queryParams["searchPhrase"] = searchField
            hasParameters = true
        }
        if(searchParamsToExecute.minPrice){
            if(parseInt(searchParamsToExecute.minPrice)){
                queryParams["minPrice"] = searchParamsToExecute.minPrice
                hasParameters = true
            }
        }
        if(searchParamsToExecute.maxPrice){
            if(parseInt(searchParamsToExecute.maxPrice)){
                queryParams["maxPrice"] = searchParamsToExecute.maxPrice
                hasParameters = true
            }
        }
        let newStatusToSearch = []
        if((newFilterChecked && asNewFilterChecked && smallUseFilterChecked && highUseFilterChecked)){

        }
        else{
            if(newFilterChecked)newStatusToSearch.push("Nuevo")
            if(asNewFilterChecked)newStatusToSearch.push("Como nuevo")
            if(smallUseFilterChecked)newStatusToSearch.push("Poco uso")
            if(highUseFilterChecked)newStatusToSearch.push("Usado")
        }
        if(newStatusToSearch.length > 0){
            if(newStatusToSearch.length === 1) queryParams["status"] = newStatusToSearch[0]
            if(newStatusToSearch.length === 2) queryParams["status"] = newStatusToSearch[0]+","+newStatusToSearch[1]
            if(newStatusToSearch.length === 3) queryParams["status"] = newStatusToSearch[0]+","+newStatusToSearch[1]+","+newStatusToSearch[2]
            hasParameters = true
        }
        if(searchParamsToExecute.selectedCurrency !== "TODAS"){
            queryParams["currency"] = searchParamsToExecute.selectedCurrency
            hasParameters = true
        }
        if(!(searchParamsToExecute.selected === "0")){
            queryParams["categoryId"] = searchParamsToExecute.selected
            hasParameters = true
        }
        if(searchParamsToExecute.selectedProvince !== "Todas las Provincias"){
            queryParams["region"] = searchParamsToExecute.selectedProvince
            hasParameters = true
        }
        if(hasParameters){
            let params = new URLSearchParams(queryParams)
            console.log(params)
            // history.replace({
            //     search: params.toString(),
            // })
            if(!searchParamsToExecute.onBackPressed){
                history.push({
                    path: '/',
                    search: params.toString(),
                })
            }
        }
        else{
            let params = new URLSearchParams()
            // history.replace({
            //     search: params.toString(),
            // // })
            if(!searchParamsToExecute.onBackPressed){
                history.push({
                    path: '/',
                    search: params.toString(),
                })
            }
        }
    }



    // #region LOGIN METHODS
    const userLoggedOut = () => {
        const userId = user._id
        const subscription = user.subscription
        socket.current = socket_store_selector
        socket.current.emit("removeSocketFromUser", {
            userId: userId,
            subscription: subscription
        })
        window.localStorage.removeItem('user')
        dispatch(remove_user_action())
        window.history.replaceState(null, null, "/");
        history.push('/')
    }
    const handleClickOpen = () => {
        dispatch(update_login_action(true))
    };
    // #endregion

    // #region HANDLE CHANGE
    const handleChange = (event) => {
        setSearchField(event.target.value);
        if(isHomePage && !event.target.value){
            dispatch(update_products_action([], 0, false))
            let params = {...searchParamsToExecute}
            params.page = 1
            params.searchPhrase = searchField
            params.onBackPressed = false
            dispatch(update_search_params_action(params))
        }
    };
    // #endregion
    
    // #region ONCLICK
    const handleSearchClick = (event) => {
        if(isHomePage){
            dispatch(update_products_action([], 0, false))
            let params = {...searchParamsToExecute}
            params.page = 1
            params.searchPhrase = searchField
            params.onBackPressed = false
            dispatch(update_search_params_action(params))
            return
        }
        let params = '?'
        if(searchField !== ""){
            params += "searchPhrase="
            params += searchField
        }
        if(params.length > 1){
            dispatch(update_products_action([], 0, false))
            let store_params = {...searchParamsToExecute}
            store_params.page = 1
            store_params.selected = "0"
            store_params.minPrice = ''
            store_params.maxPrice = ''
            store_params.selectedCurrency = 'TODAS'
            store_params.selectedProvince = 'Todas las Provincias'
            store_params.searchPhrase = searchField
            store_params.statusToSearch = ["Nuevo", "Como Nuevo", "Poco uso", "Usado"]
            dispatch(update_search_params_action(store_params))
            history.push(`/${params}`)
        }
    }
    const toAccountPage = (event) => {
        if(!user) {
            handleClickOpen()
            return
        }
        window.scrollTo(0, 0)
        history.push("/account")
    }
    const toMyProductsPage = (event) => {
        if(!user) {
            handleClickOpen()
            return
        }
        window.scrollTo(0, 0)
        history.push("/myproducts")
    }
    const toCreateProductPage = (event) => {
        window.scrollTo(0, 0)
        history.push("/myproducts/create")
    }
    const toFavoritesPage = (event) => {
        if(!user) {
            handleClickOpen()
            return
        }
        window.scrollTo(0, 0)
        history.push("/favorites")
    }
    const toHomePage = () => {
        window.scrollTo(0, 0)
        if(isHomePage) {
            return
        }
        history.push("/")
    }
    const toAdminUsersPage = (event) => {
        window.scrollTo(0, 0)
        history.push("/adminUsers")
    }
    const toAdminProductsPage = (event) => {
        window.scrollTo(0, 0)
        history.push("/adminProducts")
    }
    const toAdminCategoriesPage = (event) => {
        window.scrollTo(0, 0)
        history.push("/adminCategories")
    }
    const toLegalInformationPage = (event) => {
        window.scrollTo(0, 0)
        history.push("/legalinformation")
    }
    const toHelpPage = (event) => {
        window.scrollTo(0, 0)
        history.push("/help")
    }
    const toConversationsPage = (event) => {
        if(!user) {
            handleClickOpen()
            return
        }
        window.scrollTo(0, 0)
        history.push("/conversations")
    }

    const findCategorySelected = (selected) => {
        for (let index = 0; index < data.length; index++) {
            if(data[index]._id === selected){
                return data[index].name.toUpperCase()
            }
        }
    } 
    const handleClickFilter = (event) => {
        setAnchorElFilter(event.currentTarget);
    };
    const handleClickUserOptions = (event) => {
        setAnchorElUserOptions(event.currentTarget);
    };
    const handleClickCategories = (event) => {
        setAnchorElCategories(event.currentTarget);
    };
    const handleClickDrawer = (event) => {
        setAnchorElDrawer(event.currentTarget);
    };
    // #endregion

    // #region OTHERS
    const handleCloseUserOptions = () => {
        setAnchorElUserOptions(null);
    };

    const handleKeyDown = (e) => {
        if(e.key === 'Enter'){
            e.preventDefault()
            handleSearchClick()
        }
    }
    // #endregion

    // #region DRAWERS
    const toggleDrawer = (open, side) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        setCategoryDrawer({...categoryDrawer, [side]: open});
      };
    
      const categoryDrawerList = () => (
        <div className={classes.categoryDrawerStyle}
          role="presentation"
          onKeyDown={toggleDrawer(false, 'left')}
          onClick={toggleDrawer(false, 'left')}
        >
        <span className={classes.drawerTextStyle}>Categorías</span>
          <List style={{paddingTop: "0px"}}>
            {data.map((category) => (
              <ListItem style={{paddingTop: '4px', paddingBottom: '4px'}} button alignItems="center" key={category._id} onClick={() => {categoryOnClick(category._id)}}>
                <ListItemIcon><img src={category.iconUrl} style={{width:"22px", height:"22px"}}/></ListItemIcon>
                <Typography style={{color: colors.secondary, fontSize: '16px'}}>
                    {category.name.toUpperCase()}
                </Typography>
              </ListItem>
            ))}
          </List>
        </div>
      );
    
      const optionsList = () => (
        <div
          className={classes.categoryDrawerStyle}
          role="presentation"
          onClick={toggleDrawer(false, 'right')}
          onKeyDown={toggleDrawer(false, 'right')}
        >
          <span className={classes.drawerTextStyle}>Opciones</span>
          <List style={{paddingTop: "0px"}}>
            <Divider ></Divider>
            
            {user?
            <React.Fragment>
                <ListItem  style={{paddingBottom: '2px', paddingTop: '2px'}} button key={4} onClick={toAccountPage}>
                    <ListItemIcon style={{minWidth: '40px'}}><AccountCircleIcon style={{color:colors.secondary}}/></ListItemIcon>
                    <ListItemText 
                        style={{color: colors.secondary}} primary={"Mi Cuenta"} />
                </ListItem>
                <Divider></Divider>
                
                <React.Fragment>
                <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}} button key={0} onClick={toCreateProductPage}>
                    <ListItemIcon style={{minWidth: '40px'}}><MonetizationOn className={classes.sellIconOptions}/></ListItemIcon>
                    <ListItemText style={{color: colors.secondary}} primary={"Vende"} />
                </ListItem>
                <Divider></Divider>
                <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}} button key={1} onClick={toMyProductsPage}>
                    <ListItemIcon style={{minWidth: '40px'}}><ListIcon style={{color:colors.secondary}}/></ListItemIcon>
                    <ListItemText 
                        style={{color: colors.secondary}} primary={"Mis Anuncios"} />
                </ListItem>
                <Divider></Divider>
                </React.Fragment>
                
                <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}} button key={2} onClick={toConversationsPage}>
                    <ListItemIcon style={{minWidth: '40px'}}>
                        
                        <Badge color="error" variant="dot" invisible={!hasNewMessages}>
                            <EmailIcon style={{color:colors.secondary}}/>
                        </Badge>
                          
                    </ListItemIcon>
                    <ListItemText 
                        style={{color: colors.secondary}} primary={"Mensages"} />
                </ListItem>
                <Divider></Divider>
                {user.isSuperAdmin &&
                    <React.Fragment>
                        <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}} button key={7} onClick={toAdminUsersPage}>
                            <ListItemIcon style={{minWidth: '40px'}}><Group style={{color:colors.secondary}}/></ListItemIcon>
                            <ListItemText 
                                style={{color: colors.secondary}} primary={'Administrar Usuarios'} />
                        </ListItem>
                        <Divider></Divider>
                    </React.Fragment>
                }
                {user.isSuperAdmin &&
                    <React.Fragment>
                        <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}} button key={9} onClick={toAdminProductsPage}>
                            <ListItemIcon style={{minWidth: '40px'}}><Inbox style={{color:colors.secondary}}/></ListItemIcon>
                            <ListItemText 
                                style={{color: colors.secondary}} primary={'Administrar Productos'} />
                        </ListItem>
                        <Divider></Divider>
                    </React.Fragment>
                }
                {user.isSuperAdmin &&
                    <React.Fragment>
                        <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}} button key={10} onClick={toAdminCategoriesPage}>
                            <ListItemIcon style={{minWidth: '40px'}}><Apps style={{color:colors.secondary}}/></ListItemIcon>
                            <ListItemText 
                                style={{color: colors.secondary}} primary={'Administrar Categorias'} />
                        </ListItem>
                        <Divider></Divider>
                    </React.Fragment>
                }
                <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}} button key={3} onClick={toFavoritesPage}>
                    <ListItemIcon style={{minWidth: '40px'}}><FavoriteIcon style={{color:colors.secondary}}/></ListItemIcon>
                    <ListItemText 
                        style={{color: colors.secondary}} primary={"Favoritos"} />
                </ListItem>
                {/* <Divider></Divider>
                <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}} button key={9} onClick={toLegalInformationPage}>
                    <ListItemIcon style={{minWidth: '40px'}}><Info style={{color:colors.secondary}}/></ListItemIcon>
                    <ListItemText 
                        style={{color: colors.secondary}} primary={"Información Legal"} />
                </ListItem> */}
                <Divider></Divider>
                <a target="_blank" href={`https://wa.me/${supportNumber}?text=${encodeURI("Hola, tengo una pregunta.")}`} style={{cursor: 'pointer', textDecoration: 'none', color: 'black'}}>
                <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}} button key={10}>
                    <ListItemIcon style={{minWidth: '40px'}}><Send style={{color:colors.secondary}}/></ListItemIcon>
                    <ListItemText 
                        style={{color: colors.secondary}} primary={"Contactar y Ayuda"} />
                </ListItem>
                </a>
                {/* <Divider></Divider>
                <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}} button key={11} onClick={toHelpPage}>
                    <ListItemIcon style={{minWidth: '40px'}}><Help style={{color:colors.secondary}}/></ListItemIcon>
                    <ListItemText 
                        style={{color: colors.secondary}} primary={"Preguntas Frecuentes"} />
                </ListItem> */}
                <Divider></Divider>
                <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}} button key={5} onClick={userLoggedOut}>
                    <ListItemIcon style={{minWidth: '40px'}}><CloseIcon style={{color:colors.secondary}}/></ListItemIcon>
                    <ListItemText 
                        style={{color: colors.secondary}} primary={"Cerrar Sesión"} />
                </ListItem>
            </React.Fragment>
            :
            <React.Fragment>
                
                <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}} button key={0} onClick={handleClickOpen}>
                    <ListItemIcon style={{minWidth: '40px'}}><MonetizationOn className={classes.sellIconOptions}/></ListItemIcon>
                    <ListItemText style={{color: colors.secondary}} primary={"Vende"} />
                </ListItem>
                <Divider></Divider>
                <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}} button key={1} onClick={handleClickOpen}>
                    <ListItemIcon style={{minWidth: '40px'}}><AccountCircleIcon style={{color:colors.secondary}}/></ListItemIcon>
                    <ListItemText 
                        style={{color: colors.secondary}} primary={"Iniciar Sesión"} />
                </ListItem>
                {/* <Divider></Divider>
                <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}} button key={9} onClick={toLegalInformationPage}>
                    <ListItemIcon style={{minWidth: '40px'}}><Info style={{color:colors.secondary}}/></ListItemIcon>
                    <ListItemText 
                        style={{color: colors.secondary}} primary={"Información Legal"} />
                </ListItem> */}
                <Divider></Divider>
                <a target="_blank" href={`https://wa.me/${supportNumber}?text=${encodeURI("Hola, tengo una pregunta.")}`} style={{cursor: 'pointer', textDecoration: 'none', color: 'black'}}>
                <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}} button key={10}>
                    <ListItemIcon style={{minWidth: '40px'}}><Send style={{color:colors.secondary}}/></ListItemIcon>
                    <ListItemText 
                        style={{color: colors.secondary}} primary={"Contactar y Ayuda"} />
                </ListItem>
                </a>
                {/* <Divider></Divider>
                <ListItem style={{paddingBottom: '2px', paddingTop: '2px'}} button key={11} onClick={toHelpPage}>
                    <ListItemIcon style={{minWidth: '40px'}}><Help style={{color:colors.secondary}}/></ListItemIcon>
                    <ListItemText 
                        style={{color: colors.secondary}} primary={"Preguntas Frecuentes"} />
                </ListItem> */}
            </React.Fragment>
            }
            <Divider></Divider>
          </List>
        </div>
      );
    // #endregion

    const handleChangeVisibility = async isVisible => {
        console.log("Cambio visibilidad")
        const isSession = window.localStorage.getItem('session')
        let sessionId = null
        if(isSession){
            sessionId = JSON.parse(isSession)._id
        }
        else{
            const newSessionId = uuidv4()
            window.localStorage.setItem(
            'session', JSON.stringify({_id: newSessionId})
            )
            sessionId = newSessionId
        }
        if(user){
            if(sessionId){
                if(socket.current){
                    socket.current.emit("visibility", {isVisible: isVisible, userId: user._id, deviceId: sessionId})
                }
            }
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json',
                            'Authorization': `${user.accessToken}`}
                };
            let result = await fetch(`${url}/api/conversations/unreadMessages`, requestOptions);
            console.log(result)
            if(result.status === 200){
                let newData = await result.json();
                if(newData.unreadMessages){
                    dispatch(unread_messages_action())
                }
            }
        }
    }

    return (
        <div>
            {/*app bar*/}
            <CssBaseline />
            <PageVisibility  onChange={handleChangeVisibility}/>
            <ElevationScroll {...props}>
                <AppBar className={classes.appBar}
                >
                    <StyledMenu
                        id="filter-menu"
                        anchorEl={anchorElFilter}
                        keepMounted
                        open={openFilter}
                        onClose={handleCloseFilter}
                        PaperProps={{
                        style: {
                            backgroundColor:'white',
                            borderRadius: '2px',
                        },
                        }}
                    >
                        <div className={classes.filtersHolder}>
                            
                            <div className={classes.filterPopup}>
                                <div style={{marginLeft:'25px'}}>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <LocationOnIcon fontSize='small' className={classes.filterDrawerIcon} />
                                        <Typography className={classes.filterDrawerText}>Provincia</Typography> 
                                    </div>
                                    <div className={classes.smallSizeFilter}>
                                        <Button
                                            variant="contained"
                                            aria-label="more"
                                            aria-controls="long-menu-drawer"
                                            aria-haspopup="true"
                                            onClick={handleClickDrawer}
                                            style={{display: 'flex', boxShadow: '1px 1px 1px #D4D4D4', color: 'white', margin: '5px', backgroundColor: colors.secondary, marginRight: '25px'}}
                                            //className={`${classes.navbarButton} ${classes.noRadiusButton}`}
                                            classes={{
                                                label: classes.labelProvinceMainDrawer, // class name, e.g. `classes-nesting-label-x`
                                            }}
                                            endIcon={<ArrowDropDownIcon className={classes.dropDownIconDrawer}  style={{color: 'white'}}/>}
                                        >{searchParamsToExecute.selectedProvince}</Button>
                                        <Menu
                                            id="long-menu-drawer"
                                            anchorEl={anchorElDrawer}
                                            keepMounted
                                            open={openDrawer}
                                            onClose={() => handleCloseDrawer(null)}
                                            PaperProps={{
                                            style: {
                                                maxHeight: ITEM_HEIGHT * 4.5,
                                                width: "27ch"
                                            },
                                            }}
                                        >
                                            {provinces.map((province) => (
                                                <MenuItem key={province} selected={province === searchParamsToExecute.selectedProvince} onClick={() => handleCloseDrawer(province)}>
                                                    {province}
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </div>
                                    <div className={classes.longSizeFilter} style={{marginLeft:'10px', cursor: 'pointer'}}>
                                        
                                        {provinces.map((province) => (
                                            <div 
                                                key={province}
                                                onClick={() => handleCloseDrawer(province)} 
                                                style={{display: 'flex', alignItems: 'center'}}>
                                                <div className={province === searchParamsToExecute.selectedProvince? classes.selectedProvince : classes.unselectedProvince}/>
                                                <Typography style={{fontSize: '12px', color: colors.secondary, marginLeft: '4px'}}>
                                                    {province.toUpperCase()}
                                                </Typography>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            
                            
                            <div className={classes.filterDivider}/>
                            
                            <div>
                                <div className={classes.smallSizeFilterMargin} style={{display: 'flex', alignItems: 'center'}}>
                                    <BsGridFill className={classes.filterDrawerIcon} />
                                    <Typography className={classes.filterDrawerText}>Categoría</Typography>
                                </div>
                                <div className={classes.smallSizeFilter}>
                                    <Button
                                        variant="contained"
                                        aria-label="more"
                                        aria-controls="long-menu-drawer"
                                        aria-haspopup="true"
                                        onClick={handleClickCategories}
                                        style={{display: 'flex', boxShadow: '1px 1px 1px #D4D4D4', color: 'white', margin: '5px 0px 5px 30px', backgroundColor: colors.secondary, marginRight: '25px'}}
                                        //className={`${classes.navbarButton} ${classes.noRadiusButton}`}
                                        classes={{
                                            label: classes.labelProvinceMainDrawer, // class name, e.g. `classes-nesting-label-x`
                                        }}
                                        endIcon={<ArrowDropDownIcon className={classes.dropDownIconDrawer} style={{color: 'white'}} />}
                                    >{findCategorySelected(searchParamsToExecute.selected)}</Button>
                                    <Menu
                                        id="long-menu-drawer"
                                        anchorEl={anchorElCategories}
                                        keepMounted
                                        open={openCategories}
                                        onClose={() => handleCloseCategories(null)}
                                        PaperProps={{
                                        style: {
                                            maxHeight: ITEM_HEIGHT * 4.5,
                                            width: "27ch"
                                        },
                                        }}
                                    >
                                        {data.map((category) => (
                                            <MenuItem key={category._id} selected={category._id === searchParamsToExecute.selected} onClick={() => handleCloseCategories(category._id)}>
                                                {category.name}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </div>
                                <div className={classes.longSizeFilter}>
                                    <div>
                                    {categoryMainDrawerList()}
                                    </div>
                                </div>
                            </div>
                            <div className={classes.filterDivider}/>
                            <div className={classes.filterPopup}>
                                <div className={classes.smallSizeFilterMargin}>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <AttachMoneyIcon className={classes.filterDrawerIcon} />
                                        <Typography className={classes.filterDrawerText}>Precio y Estado</Typography>
                                    </div>
                                    <div style={{display:'flex'}}>
                                        <div>
                                            <Typography style={{fontSize: '12px', color: colors.secondary}}>MIN</Typography>
                                            <div className={classes.inputHolder}>
                                                <InputBase
                                                    elevation={4}
                                                    onClick={() => 
                                                        {
                                                            if(minPriceError) handleMinPriceErrorTooltipOpen()
                                                            if(maxPriceError) handleMaxPriceErrorTooltipClose()
                                                        }}
                                                    type="text"
                                                    value={searchParamsToExecute.minPrice}
                                                    placeholder="-"
                                                    onChange={handleChangeMinPrice}
                                                    classes={{
                                                        root: classes.inputRootLogin,
                                                        input: classes.inputInputLogin,
                                                    }}
                                                    inputProps={{ 'aria-label': 'search' }}
                                                />
                                                {minPriceError &&
                                                    <div>
                                                    <MuiThemeProvider theme={themeMUI}>
                                                        <Tooltip classes={{arrow: classes.arrow}}
                                                            arrow
                                                            PopperProps={{
                                                            disablePortal: true,
                                                            }}
                                                            onClose={handleMinPriceErrorTooltipClose}
                                                            open={minPriceErrorOpen}
                                                            disableFocusListener
                                                            disableHoverListener
                                                            disableTouchListener
                                                            title={minPriceError}
                                                        >
                                                            <IconButton onClick={() => {
                                                                            if(maxPriceErrorOpen){
                                                                                handleMaxPriceErrorTooltipClose()
                                                                            }
                                                                            handleMinPriceErrorTooltipOpen()
                                                                        }} 
                                                                        aria-label="warning" className={classes.errorButton}>
                                                                <WarningAmberIcon fontSize="small" />
                                                            </IconButton>
                                                            
                                                        </Tooltip>
                                                    </MuiThemeProvider>
                                                    </div>                       
                                                }
                                            </div>
                                        </div>
                                        <div>
                                            <Typography style={{fontSize: '12px', color: colors.secondary}}>MAX</Typography>
                                            <div className={classes.inputHolder}>
                                                <InputBase
                                                    elevation={4}
                                                    onClick={() => 
                                                        {
                                                            if(maxPriceError) handleMaxPriceErrorTooltipOpen()
                                                            if(minPriceError) handleMinPriceErrorTooltipClose()
                                                        }}
                                                    type="text"
                                                    value={searchParamsToExecute.maxPrice}
                                                    onChange={handleChangeMaxPrice}
                                                    placeholder="-"
                                                    classes={{
                                                        root: classes.inputRootLogin,
                                                        input: classes.inputInputLogin,
                                                    }}
                                                    inputProps={{ 'aria-label': 'search' }}
                                                />
                                                {maxPriceError &&
                                                    <div>
                                                    <MuiThemeProvider theme={themeMUI}>
                                                        <Tooltip classes={{arrow: classes.arrow}}
                                                            arrow
                                                            PopperProps={{
                                                            disablePortal: true,
                                                            }}
                                                            onClose={handleMaxPriceErrorTooltipClose}
                                                            open={maxPriceErrorOpen}
                                                            disableFocusListener
                                                            disableHoverListener
                                                            disableTouchListener
                                                            title={maxPriceError}
                                                        >
                                                            <IconButton onClick={() => {
                                                                            if(minPriceErrorOpen){
                                                                                handleMinPriceErrorTooltipClose()
                                                                            }
                                                                            handleMaxPriceErrorTooltipOpen()
                                                                        }}  aria-label="warning" className={classes.errorButton}>
                                                                <WarningAmberIcon fontSize="small" />
                                                            </IconButton>
                                                            
                                                        </Tooltip>
                                                    </MuiThemeProvider>
                                                    </div>                       
                                                }
                                            </div>
                                        </div>
                                        <div>
                                            <Typography style={{fontSize: '12px', color: colors.secondary}}>MONEDA</Typography>
                                            <div className={classes.currencyHolder} onClick={handleCurrencyClick} style={{display: 'flex', alignItems: 'center', cursor: 'pointer', marginRight: '20px'}}>
                                                <Typography style={{fontSize:'12px', marginLeft:'10px', color: 'white'}}>{searchParamsToExecute.selectedCurrency}</Typography>
                                                <ArrowDropDownIcon style={{color: 'white', height: '20px', width:'20px', marginLeft:'-2px'}}/>
                                            </div>
                                            <Menu
                                                id="long-menu"
                                                anchorEl={anchorElCurrency}
                                                keepMounted
                                                open={openCurrency}
                                                onClose={() => handleCurrencyClose(null)}
                                                PaperProps={{
                                                style: {
                                                },
                                                }}
                                            >
                                                {currencies.map((currency) => (
                                                    <MenuItem key={currency} selected={currency === searchParamsToExecute.selectedCurrency} onClick={() => handleCurrencyClose(currency)}>
                                                        {currency}
                                                    </MenuItem>
                                                ))}
                                            </Menu>
                                        </div>
                                    </div>
                                    
                                    <div className={classes.stateFilterHolder}>
                                        <div className={classes.stateFilterFirst}>
                                            <div style={{display:'flex', alignItems: 'center', marginBottom: '2px', marginTop: '12px'}}>
                                                <Typography style={{color: colors.secondary, paddingTop:'1px', width: '85px', fontSize: '12px'}}>NUEVO</Typography>
                                                <GreenCheckbox
                                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                    checked={newFilterChecked}
                                                    onChange={handleNewFilterChange}
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </div>
                                            <div style={{display:'flex', alignItems: 'center', marginBottom: '2px'}}>
                                                <Typography style={{color: colors.secondary, paddingTop:'1px', width: '85px', fontSize: '12px'}}>COMO NUEVO</Typography>
                                                <GreenCheckbox
                                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                    checked={asNewFilterChecked}
                                                    onChange={handleAsNewFilterChange}
                                                    color="primary"
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </div>
                                        </div>
                                        <div className={classes.stateFilterSecond}>
                                            <div style={{display:'flex', alignItems: 'center', marginBottom: '2px'}}>
                                                <Typography style={{color: colors.secondary, paddingTop:'1px', width: '85px', fontSize: '12px'}}>POCO USO</Typography>
                                                <GreenCheckbox
                                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                    checked={smallUseFilterChecked}
                                                    onChange={handleSmallUseFilterChange}
                                                    color="primary"
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </div>
                                            <div style={{display:'flex', alignItems: 'center', marginBottom: '2px'}}>
                                                <Typography style={{color: colors.secondary, paddingTop:'1px', width: '85px', fontSize: '12px'}}>USADO</Typography>
                                                <GreenCheckbox
                                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                    checked={highUseFilterChecked}
                                                    onChange={handleHighUseFilterChange}
                                                    color="primary"
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </div>
                                        </div>
                                    </div>               
                                </div>
                            </div>
                            
                        </div>
                    </StyledMenu> 
                    <Toolbar
                        className={classes.mainToolbar}>
                        <Button 
                            className={classes.categoriesButton}
                            onClick={toggleDrawer(true, 'left')}
                        >
                            <BsGridFill className={classes.categoriesIcon}/>
                        </Button>
                        <SwipeableDrawer
                            anchor={'left'}
                            open={categoryDrawer['left']}
                            onClose={toggleDrawer(false, 'left')}
                            onOpen={toggleDrawer(true, 'left')}
                        >
                            {categoryDrawerList()}
                        </SwipeableDrawer>
                        <img src={logo} className={classes.logo}
                            onClick={() => {
                                dispatch(update_products_action([], 0, false))
                                let params = {
                                    page : 1,
                                    selected : "0",
                                    minPrice : '',
                                    maxPrice : '',
                                    selectedCurrency : 'TODAS',
                                    selectedProvince : 'Todas las Provincias',
                                    statusToSearch : ["Nuevo", "Como Nuevo", "Poco uso", "Usado"],
                                    searchPhrase: ''
                                }
                                dispatch(update_search_params_action(params))
                                history.push('/')
                            }}
                        />
                        <div className={classes.SearchAndFilter}>
                            <div className={classes.search}>
                                <InputBase
                                    elevation={4}
                                    placeholder="BUSCAR & COMPRAR"
                                    autoComplete='off'
                                    onKeyDown={handleKeyDown}
                                    value={searchField}
                                    onChange={handleChange}
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                                <div
                                    onClick = {handleSearchClick} 
                                    className={classes.searchIcon}>
                                    <SearchIcon />
                                </div>
                            </div>

                            {isHomePage &&
                            <React.Fragment>
                                <Button
                                aria-controls='filter-menu'
                                onClick={handleClickFilter}
                                variant="contained"
                                className={`${classes.darkGrayButton} ${classes.filterButton}`}
                                >
                                    <BsFillFunnelFill className={classes.filterIcon}/>
                                </Button>
                                   
                            </React.Fragment>
                            }
                            
                        </div>
                        <Snackbar open={openSuccesSnack} autoHideDuration={1000} onClose={handleCloseSuccesSnack}>
                            <Alert onClose={handleCloseSuccesSnack} severity="success">
                                Se inició sesión correctamente.
                            </Alert>
                        </Snackbar>
                        <Snackbar open={openApiErrorSnack} autoHideDuration={2000} onClose={handleCloseApiErrorSnack}>
                            <Alert onClose={handleCloseApiErrorSnack} severity="error">
                                No se pudo iniciar sesión. Revise su conexión a Internet.
                            </Alert>
                        </Snackbar>
                        <Snackbar open={openRegisterSuccesSnack} autoHideDuration={1000} onClose={handleCloseRegisterSuccesSnack}>
                            <Alert onClose={handleCloseRegisterSuccesSnack} severity="success">
                                Usuario registrado correctamente. Iniciando sesión...
                            </Alert>
                        </Snackbar>
                        <Snackbar open={openRegisterApiErrorSnack} autoHideDuration={2000} onClose={handleCloseRegisterApiErrorSnack}>
                            <Alert onClose={handleCloseRegisterApiErrorSnack} severity="error">
                                No se pudo registrar el usuario. Revise su conexión a Internet.
                            </Alert>
                        </Snackbar>
                        {user?
                        <React.Fragment>
                            <Button
                                onClick={toCreateProductPage}
                                variant="contained"
                                className={classes.sellButton}
                                classes={{
                                    label: classes.label, // class name, e.g. `classes-nesting-label-x`
                                }}
                                >
                                    <img src={vende_button} style={{height: '18px', width: 'auto'}}/>
                            </Button>
                            <IconButton
                                    onClick={toFavoritesPage}
                                    className={classes.messageButton}
                                    style={{padding:'9px'}}
                                    edge="end"
                                    aria-label="messages">
                                <FavoriteIcon fontSize='small' className={classes.navbarUserIcon}/>
                            </IconButton>
                            <IconButton
                                    onClick={toConversationsPage}
                                    className={classes.messageButton}
                                    style={{padding:'9px'}}
                                    edge="end"
                                    aria-label="messages">
                                
                                <Badge color="error" variant="dot" invisible={!hasNewMessages}>
                                    <EmailIcon  fontSize='small' className={classes.navbarUserIcon}/>
                                </Badge>
                                  
                            </IconButton>
                            <IconButton edge='end' 
                                className={classes.loginButton}
                                //startIcon={user.profilePictureUrl? <Avatar style={{width:'20px', height: '20px'}} src={user.profilePictureUrl} /> : <UserLoggedIn className={classes.navbarUserIcon} />}
                                onClick={handleClickUserOptions}
                                style={user.profilePictureUrl? {padding:'8px'}: {padding:'9px'}}
                            >
                                {user.profilePictureUrl? 
                                    <Avatar style={{width:'20px', height: '20px'}} src={user.profilePictureUrl} />
                                : 
                                    <UserLoggedIn className={classes.navbarUserIcon} />
                                }
                            </IconButton>
                            <StyledMenu
                                id="long-menu"
                                anchorEl={anchorElUserOptions}
                                keepMounted
                                open={openUserOptions}
                                onClose={() => handleCloseUserOptions(null)}
                                PaperProps={{
                                style: {
                                },
                                }}
                            >    
                                <MenuItem key={0} onClick={() => {handleCloseUserOptions()
                                                                  toAccountPage()}}
                                >
                                    <AccountCircleIcon className={classes.menuIconUserOptions}/> Mi Cuenta
                                </MenuItem>
                                
                                <MenuItem key={1} onClick={() => {handleCloseUserOptions()
                                                                  toMyProductsPage()}}
                                >
                                    <ListIcon className={classes.menuIconUserOptions} /> Mis Anuncios
                                </MenuItem>
                                
                                
                                {user.isSuperAdmin &&
                                <MenuItem key={4} onClick={() => {handleCloseUserOptions()
                                                                  toAdminUsersPage()}}
                                >
                                    <Group className={classes.menuIconUserOptions} /> {'Administrar Usuarios'}
                                </MenuItem>
                                }
                                {(user.isSuperAdmin) &&
                                <MenuItem key={9} onClick={() => {handleCloseUserOptions()
                                                                  toAdminProductsPage()}}
                                >
                                    <Inbox className={classes.menuIconUserOptions} /> {'Administrar Productos'}
                                </MenuItem>
                                }
                                {(user.isSuperAdmin) &&
                                <MenuItem key={10} onClick={() => {handleCloseUserOptions()
                                                                  toAdminCategoriesPage()}}
                                >
                                    <Apps className={classes.menuIconUserOptions} /> {'Administrar Categorías'}
                                </MenuItem>
                                }
                                
                                
                                {/* <MenuItem key={6} onClick={() => {handleCloseUserOptions()
                                                                  toLegalInformationPage()}}
                                >
                                    <Info className={classes.menuIconUserOptions} /> {'Informacion Legal'}
                                </MenuItem> */}
                                <MenuItem key={7} onClick={() => {handleCloseUserOptions()}}
                                >
                                    <a target="_blank" href={`https://wa.me/${supportNumber}?text=${encodeURI("Hola, tengo una pregunta.")}`} style={{display: 'flex', alignItems: 'center', marginTop: '3px', cursor: 'pointer', textDecoration: 'none', color: 'black', opacity: '0.9', width: '100%'}}><Send className={classes.menuIconUserOptions} /> Contactar y Ayuda</a>
                                </MenuItem>
                                {/* <MenuItem key={8} onClick={() => {handleCloseUserOptions()
                                                                  toHelpPage()}}
                                >
                                    <Help className={classes.menuIconUserOptions} /> {'Preguntas Frecuentes'}
                                </MenuItem> */}
                               
                                <MenuItem key={2} onClick={() => {handleCloseUserOptions()
                                                                  userLoggedOut()}}
                                >
                                    <CloseIcon className={classes.menuIconUserOptions} /> Cerrar Sesión
                                </MenuItem>    
                            </StyledMenu>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            
                            <Button
                            variant="contained"
                            onClick={handleClickOpen}
                            className={classes.sellButton}
                            classes={{
                                label: classes.label, // class name, e.g. `classes-nesting-label-x`
                                }}
                            >
                                <img src={vende_button} style={{height: '18px', width: 'auto'}}/>
                            </Button>
                            
                            <Button edge='end' 
                                className={classes.loginButton}
                                style={{padding: '6px 16px', marginLeft: '10px'}}
                                startIcon={<UserLoggedIn className={classes.navbarUserIcon} />}
                                onClick={handleClickOpen}
                            >
                            Iniciar Sesión
                            </Button>
                            </React.Fragment>
                        }
                        
                        <Button 
                            className={classes.optionsButton}
                            onClick={toggleDrawer(true, 'right')}
                        >
                            
                            <Badge color="error" variant="dot" invisible={!hasNewMessages}>
                                <DehazeIcon className={classes.optionsIcon}/>
                            </Badge>
                            
                        </Button>
                        <SwipeableDrawer
                            anchor={'right'}
                            open={categoryDrawer['right']}
                            onClose={toggleDrawer(false, 'right')}
                            onOpen={toggleDrawer(true, 'right')}
                        >
                            {optionsList()}
                        </SwipeableDrawer>
                    </Toolbar>
                    
                    <Toolbar
                        className={classes.smallToolbar}>
                        <div className={classes.SearchAndFilterSmall}>
                            <div className={classes.search}>
                                <InputBase
                                    elevation={4}
                                    placeholder="BUSCAR & COMPRAR"
                                    value={searchField}
                                    onKeyDown={handleKeyDown}
                                    onChange={handleChange}
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                                <div
                                    onClick = {handleSearchClick} 
                                    className={classes.searchIcon}>
                                    <SearchIcon />
                                </div>
                            </div>
                        </div>
                        {isHomePage &&
                            <React.Fragment>
                                <Button
                                aria-controls='filter-menu'
                                onClick={handleClickFilter}
                                variant="contained"
                                className={`${classes.darkGrayButton} ${classes.filterButton}`}
                                style={{marginRight: '0px'}}
                                >
                                    <BsFillFunnelFill className={classes.filterIcon}/>
                                </Button>
                                   
                            </React.Fragment>
                        }
                        
                    </Toolbar>
                </AppBar>
            </ElevationScroll>
            <AppBar position="fixed" color='white' className={classes.appBarTab}>
                <Toolbar className={classes.tabToolbar}>
                    <div onClick={toHomePage} className={classes.tabElement} style={{backgroundColor: isHomePage? colors.primary : 'inheritance'}}>
                        <Home className={classes.tabIcon} style={{marginLeft: 'auto', marginRight: 'auto'}}/>
                    </div>
                    <div onClick={toFavoritesPage} className={classes.tabElement} style={{backgroundColor: isFavoritesPage? colors.primary : 'inheritance'}}>
                        <FavoriteIcon className={classes.tabIcon} style={{marginLeft: 'auto', marginRight: 'auto'}}/>
                    </div>
                    <div onClick={toMyProductsPage} className={classes.tabElement} style={{backgroundColor: isSellPage? colors.primary : 'inheritance'}}>
                        <MonetizationOn className={classes.tabIcon} style={{marginLeft: 'auto', marginRight: 'auto', width: '35px', height: '35px'}}/>
                    </div>
                    <div onClick={toConversationsPage} className={classes.tabElement} style={{backgroundColor: isConversationsPage? colors.primary : 'inheritance'}}>
                        <Badge color="error" variant="dot" invisible={!hasNewMessages} style={{marginLeft: 'auto', marginRight: 'auto'}}>
                            <Message className={classes.tabIcon} style={{marginLeft: 'auto', marginRight: 'auto'}}/>
                        </Badge>
                    </div>
                    <div onClick={toAccountPage} className={classes.tabElement} style={{backgroundColor: isAccountPage? colors.primary : 'inheritance'}}>
                        <Person className={classes.tabIcon} style={{marginLeft: 'auto', marginRight: 'auto'}}/>
                    </div>
                </Toolbar>
            </AppBar>
            <div className={classes.page}>
                <div className={classes.mainToolbar}></div>
                <div className={classes.smallToolbar}></div>
                {/* <div style={{height:"5px"}}></div> */}
            </div>
            <LoginRegister
                setOpenSuccesSnack = {OpenSuccessSnack}
                setCloseSuccesSnack = {handleCloseSuccesSnack}
                setOpenApiErrorSnack = {OpenApiErrorSnack}
                setOpenRegisterSuccesSnack = {OpenRegisterSuccessSnack}
                setOpenRegisterApiErrorSnack = {OpenRegisterApiErrorSnack}/>
        </div>
    )
}
  
export default Navbar