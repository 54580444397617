import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import logo from '../../assets/img/logo.png';
import logo_secondary from '../../assets/img/logoblue192.png';
import { importUrl } from '../../config';


const useStyles = makeStyles(() => ({
    holder: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    },
    image: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: '10px',
        width:'auto',
        height: '140px',
    },
    mainText: {
        marginTop: '20px',
        textAlign: 'center',
        fontSize: '18px',
        fontWeight: '600'
    },
    secondaryText: {
        marginTop: '10px',
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: '400'
    },
}))

  export default function LoadingComponent({page}) {
    const classes  = useStyles()
    
    return(
        <div className={classes.holder}>
            <div>
            <img src={importUrl? logo_secondary: logo} className={classes.image}/>
            <Typography className={classes.mainText}>
                {page}
            </Typography>
            {/* <Typography className={classes.secondaryText}>
                Asegúrate de estar conectado a Internet.
            </Typography> */}
            </div>
        </div>
    )
}