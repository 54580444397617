import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Card } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles({
    media: {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        marginLeft: '10px'
    },
    mediaPlaceholder: {
        width: '50px',
        height: '50px',
        borderRadius: '50%'
    },
    username: {
        width:'90px', 
        height: '18px', 
        marginTop:'10px', 
        marginLeft:'10px'
    },
    productName: {
        width:'190px', 
        height: '18px', 
        marginTop:'10px',
        marginLeft: '10px'
    },
    reviewDescription: {
        width:'100%', 
        height: '48px', 
        marginTop:'10px',
        marginLeft: '10px',
        marginRight: '10px',
        marginBottom: '10px'
    },
});

export default function ReviewSkeletonCard() {
    const classes = useStyles()

    return (
        <Card style={{margin: '20px 10px 0 10px', borderRadius:'10px'}}>
            <div style={{display:'flex', marginTop:'10px'}}>    
                <Skeleton variant="circle" className={classes.media} />  
                <Skeleton className={classes.username} variant="rect"/>
            </div>
            <div style={{display:'flex'}}>    
                <Skeleton variant="rect" className={classes.username} />  
                <Skeleton className={classes.username} variant="rect"/>
            </div>
            <div style={{display:'flex'}}> 
                <Skeleton className={classes.productName} variant="rect"/>
            </div>
            <div style={{display:'flex'}}> 
                <Skeleton className={classes.reviewDescription} variant="rect"/>
            </div>
        </Card>
    );
}