import React, {useState, useEffect, useRef, memo} from 'react';
import {makeStyles} from '@material-ui/core';
import ProductCard from '../../global/ProductCard';
import SkeletonCard from '../../global/SkeletonCard';
import Masonry from 'react-masonry-css';
import url, { importUrl } from '../../../config';
import NoConnection from '../../global/NoConnection';
import NoSearch from '../../global/NoSearch';
import { useDispatch, useSelector } from 'react-redux';
import { update_products_action, update_search_params_action } from '../../../redux/actions/productsAction';

const userStyles = makeStyles((theme) => ({
    btn: {
        fontSize: 60,
        borderRadius: 25,
        '&:hover' : {
            //backgroundColor: 'blue'
        }
    },
    field: {
        marginTop: 20,
        marginBottom: 20,
        display: 'block'
    },
    mainContainer: {
        marginLeft: '20px',
        marginRight: '20px',
        [theme.breakpoints.up('md')]:{
            paddingLeft: '240px',
        },
        [theme.breakpoints.down('md')]:{
            marginLeft: '10px',
            marginRight: '10px',
        },
    },
    errorConnection: {
        [theme.breakpoints.up('md')]: {
            marginLeft: '250px',
            marginBottom: '100px'
        }
    }
}))
const skeleton = [-1,-2,-3,-4,-5,-6,-7,-8,-9,-10]

export const hasReached = (el) => {
    return el.getBoundingClientRect().top <= window.innerHeight;
  }

export const HomePageProductList = () => {
    const classes = userStyles()
    const [userId, setUserId] = useState()
    const [favoritesList,setFavoritesList] = useState({})
    const [loading, setLoading] = useState(false)
    const [emptySearch, setEmptySearch] = useState(false)
    const [apiError, setApiError] = useState(false)
    const isFetching = useRef(false)
    const searchParamsToExecute = useSelector((state) => state.products_store.searchParameters)
    const selector_reachFinalProduct = useSelector((state) => state.products_store.reachFinalProduct)
    const selector_products = useSelector((state) => state.products_store.products)
    const selector_products_page = useSelector((state) => state.products_store.page)
    const searchParametersRef = useRef()
    searchParametersRef.current = searchParamsToExecute
    const reachFinalProductRef = useRef()
    reachFinalProductRef.current = selector_reachFinalProduct
    const dispatch = useDispatch()
    const userLogged = useSelector((state) => state.user_store.user)

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
      }, []);
    
      useEffect(() => {
        const isUserLogged = () => {
            if(userLogged){
                setUserId(userLogged._id)
            }
        }
        isUserLogged()
    }, [])
      
    useEffect(() => {
        const abort = {current: false}
        const fetchProducts = async () => {
            try{
                if(selector_products_page === 0){
                    setLoading(true)
                    window.scrollTo(0,0)
                }
                loadFavoritesList(abort)
                if(searchParamsToExecute.page === selector_products_page){
                    if(selector_products.length === 0){
                        if(!abort.current) setEmptySearch(true)
                    }
                    return;
                }
                if(!abort.current) setEmptySearch(false)
                let productsUrl = new URL(`${url}/api/${importUrl? 'productsImport' : 'products'}`)
                let productsPromotedUrl = new URL(`${url}/api/${importUrl? 'productsImport' : 'products'}`)
                let params = { page:searchParamsToExecute.page, limit:10, promoted: false } 
                let paramsPromoted = { page:searchParamsToExecute.page, limit:5, promoted: true }
                if(userLogged){
                    const user = userLogged
                    params.userLoggedId = user._id
                    paramsPromoted.userLoggedId = user._id
                }
                if(searchParamsToExecute.selected !== "0"){
                    params.categoryId = searchParamsToExecute.selected
                    paramsPromoted.categoryId = searchParamsToExecute.selected
                }
                else{
                    delete params["categoryId"]
                }
                if(searchParamsToExecute.selectedProvince === "Todas las Provincias"){
                    delete params["region"]
                }
                else{
                    params.region = searchParamsToExecute.selectedProvince
                    paramsPromoted.region = searchParamsToExecute.selectedProvince
                }
                if(searchParamsToExecute.searchPhrase === null){
                    delete params["searchPhrase"]
                }
                else{
                    params.searchPhrase = searchParamsToExecute.searchPhrase
                    paramsPromoted.searchPhrase = searchParamsToExecute.searchPhrase
                }
                if(!(searchParamsToExecute.minPrice)){
                    delete params["minPrice"]
                }
                else{
                    if(parseInt(searchParamsToExecute.minPrice)){
                        params.minPrice = searchParamsToExecute.minPrice
                        paramsPromoted.minPrice = searchParamsToExecute.minPrice
                    }
                    else{
                        delete params["minPrice"]
                    }
                }
                if(!(searchParamsToExecute.maxPrice)){
                    delete params["maxPrice"]
                }
                else{
                    if(parseInt(searchParamsToExecute.maxPrice)){
                        params.maxPrice = searchParamsToExecute.maxPrice
                        paramsPromoted.maxPrice = searchParamsToExecute.maxPrice
                    }
                    else{
                        delete params["maxPrice"]
                    }
                }
                if(searchParamsToExecute.statusToSearch.length > 0){
                    params.status = searchParamsToExecute.statusToSearch
                    paramsPromoted.status = searchParamsToExecute.statusToSearch
                }
                else{
                    delete params["status"]
                }
                if(searchParamsToExecute.selectedCurrency !== "TODAS"){
                    params.currency = searchParamsToExecute.selectedCurrency
                    paramsPromoted.currency = searchParamsToExecute.selectedCurrency
                }
                else{
                    delete params["currency"]
                }
                Object.keys(params).forEach(key => productsUrl.searchParams.append(key, params[key]))
                let result = await fetch(productsUrl)
                let newData = await result.json()
                if(searchParamsToExecute.page === 1){
                    if(newData.length === 0)
                    {
                        if(!abort.current){
                            setEmptySearch(true)
                        }
                    }
                    else{
                        if(!abort.current) dispatch(update_products_action(newData, 1, newData.length < 10))
                    }
                }
                else{
                    if(!abort.current) dispatch(update_products_action(selector_products.concat(newData), searchParamsToExecute.page, newData.length < 10))
                }
                if(!abort.current) setLoading(false)
                isFetching.current = false
            } catch(error){
                console.log(error)
                if(!abort.current) setApiError(true)
                if(!abort.current) setLoading(false)
                isFetching.current = false
            }
        }
        abort.current = false
        fetchProducts()
        return () => {
            abort.current = true
        }
    }, [searchParamsToExecute, userLogged])

    const loadFavoritesList = (abort) => {
        if(importUrl){
            if(userLogged){
                const userId = userLogged._id
                const data = window.localStorage.getItem(`importFavorites`)
                if(data){
                    const newData = JSON.parse(data)
                    if(newData[`${userId}`]){
                        setFavoritesList(newData[`${userId}`])
                    }
                }
            }
        }
        else{
            if(userLogged){
                const userId = userLogged._id
                const data = window.localStorage.getItem(`${userId}`)
                if(data){
                    const newData = JSON.parse(data)
                    if(!abort.current) setFavoritesList(newData)
                }
            }
        }
        
    }

    const handleScroll = () => {
        const wrappedElement = document.getElementById('fecthMoreProducts');
        if (wrappedElement !== null && !isFetching.current && !reachFinalProductRef.current){
            if (wrappedElement.getBoundingClientRect().top <= window.innerHeight){
                isFetching.current = true
                dispatch(update_search_params_action({...searchParametersRef.current, page: searchParametersRef.current.page+1}))  
            }
        }
        return;
      };

    //breakpoints for container
    const breakpoints = {
        default: 5,
        1080: 4,
        750: 3,
        600: 2,
    }

    return (
        <React.Fragment>
            {apiError?
                <div className={classes.errorConnection}>
                    <NoConnection />
                </div>
            :
            <div className={classes.mainContainer}>
                {loading? 
                    <Masonry
                    breakpointCols={breakpoints}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                    >
                        {skeleton.map(skeletonId => (
                            <div key={skeletonId}>
                                <SkeletonCard/>
                            </div>
                        ))}
                    </Masonry>
                :
                <React.Fragment>
                    {emptySearch?
                        <NoSearch />
                    :
                        <Masonry
                        breakpointCols={breakpoints}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column"
                        >
                            {selector_products.map(product => (
                                <div key={product._id}>
                                    <ProductCard
                                        product={product}
                                        isFavorite={favoritesList[`${product._id}`]}
                                        userId={userId}
                                        homePage={true}/>
                                </div>
                            ))}
                            {!selector_reachFinalProduct &&
                                <div id="fecthMoreProducts"></div>
                            }
                            {!selector_reachFinalProduct?
                                skeleton.map(skeletonId => (  
                                    <div key={skeletonId}>
                                        <SkeletonCard/>
                                    </div>
                            ))
                            :
                            <span></span>
                            }
                        </Masonry>
                    }
                </React.Fragment>
                }
                
            </div>
            }
        </React.Fragment>
    )
    
}