export const UPDATE_CART = 'UPDATE_CART'
export const RESTART_CART = 'RESTART_CART'

export const update_cart_action = (count, products, weight, points) => {
    return {
        type : UPDATE_CART,
        payload: {
            count: count,
            products: products,
            weight: weight,
            points: points
        }
    }
}

export const restart_cart_action = () => {
    return {
        type : RESTART_CART,
        payload: {
            count: 0,
            products: [],
            weight: 0,
            points: 0
        }
    }
}