import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { colors, importUrl } from '../../config'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import Skeleton from '@material-ui/lab/Skeleton'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '100%',
    },
    media: {
        width: '100%',
        paddingTop: "100%"
    },
    title:{
        color: '#494949',
        fontSize: 14,
        fontWeight: 600,
        marginBottom: 3
    },
    price:{
        fontSize: 20,
        fontWeight: 700
    },
    group: {
        width: '100%',
        backgroundColor: '#f9f9f9',
        borderRadius: 25
    },
    favorite: {
        color: colors.redHeart,
        //padding: '8px',
        '&:hover': {
            color: colors.redHeart
        },
        [theme.breakpoints.down('sm')]:{
            padding: '3px',
        }
     },
     addCart: {
        color: colors.greenCart,
        //padding: '8px',
        '&:hover': {
            color: colors.greenCart
        },
        [theme.breakpoints.down('sm')]:{
            padding: '3px',
        }
     },
    editIcon: {
        color: colors.orangeEdit,
        //padding: '8px',
        '&:hover': {
            color: colors.orangeEdit
        },
        [theme.breakpoints.down('sm')]:{
            padding: '3px',
        }
    },
    deleteIcon: {
        color: colors.redError,
        //padding: '8px',
        '&:hover': {
            color: colors.redError
        },
        [theme.breakpoints.down('sm')]:{
            padding: '3px',
        }
    },
    share: {
        color: colors.blueShare,
        //padding: '8px',
        '&:hover': {
            color: colors.blueShare
        },
        [theme.breakpoints.down('sm')]:{
            padding: '3px',
            marginRight: '2px',
        }
    },
    productCard: {
        borderRadius: '10px'
    },
    box: {
        position: "relative",
        width: "100%",		/* desired width */
        '&:before': {
            content: "",
            float: "left",
            paddingTop: "100%"
          }
    },
    content: {
        float: "left"
    }
}))

export default function SkeletonCard({product, i, userId}) {
    const classes = useStyles()
    
    return (
        <Card className={classes.productCard}>
            <CardActionArea>
                <CardMedia>
                    <Skeleton variant="rect" width="100%" height="250px"/> 
                </CardMedia>
                <CardContent>
                    <Skeleton variant="text" width="90%" height="20px"/>
                    <Skeleton variant="text" width="70%" height="20px"/>
                </CardContent>
            </CardActionArea>
            <CardActions disableSpacing>
                {userId?
                <Grid container alignItems="center" justify="space-around" className={classes.group}>
                    <Grid item>
                        <IconButton aria-label="add to favorites">
                            <EditIcon className={classes.editIcon} />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton aria-label="add to favorites">
                            <DeleteIcon className={classes.deleteIcon} />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton aria-label="share">
                            <ShareOutlinedIcon className={classes.share}/>
                        </IconButton>
                    </Grid>
                </Grid>
                :
                <Grid container alignItems="center" justify={importUrl? "space-around" : "flex-end"} className={classes.group}>
                    {!importUrl &&
                    <Grid item style={{marginRight: 'auto', marginLeft: '15px'}}>
                        <Skeleton variant="text" width="30px" height="35px"/>
                    </Grid>
                    }
                    <Grid item>
                        <IconButton aria-label="add to favorites">
                            <FavoriteBorderOutlinedIcon className={classes.favorite} />
                        </IconButton>
                    </Grid>
                    {importUrl === 1 &&
                    <Grid item>
                        <IconButton aria-label="add to cart">
                            <AddShoppingCartIcon className={classes.addCart} />
                        </IconButton>
                    </Grid>
                    }
                    <Grid item>
                        <IconButton aria-label="share">
                            <ShareOutlinedIcon className={classes.share}/>
                        </IconButton>
                    </Grid>
                </Grid>
                }
            </CardActions>
        </Card>
    );
}