export const UPDATE_PRODUCTS = 'UPDATE_PRODUCTS'
export const UPDATE_SEARCH_PARAMS = 'UPDATE_SEARCH_PARAMS'

export const update_products_action = (products, page, reachFinalProduct) => {
    return {
        type : UPDATE_PRODUCTS,
        payload: {
            products: products,
            page: page,
            reachFinalProduct: reachFinalProduct
        }
    }
}

export const update_search_params_action = (payload) => {
    return {
        type : UPDATE_SEARCH_PARAMS,
        payload: payload
    }
}