export const REMOVE_SOCKET = 'REMOVE_SOCKET'
export const UNREAD_MESSAGES = 'NO_MESSAGES'
export const NO_UNREAD_MESSAGES = 'NO_UNREAD_MESSAGES'

export const remove_socket_action = () => {
    return {
        type : REMOVE_SOCKET,
        payload: null
    }
}

export const unread_messages_action = () => {
    return {
        type : UNREAD_MESSAGES,
        payload: true
    }
}

export const no_unread_messages_action = () => {
    return {
        type : NO_UNREAD_MESSAGES,
        payload: false
    }
}