import React from "react";
import { useState, useEffect, useCallback } from "react";
import url, { colors, appShareUrl, importUrl, sendingCostValue } from "../../config";
import { makeStyles, Modal, Typography } from "@material-ui/core";
import { alpha } from "@material-ui/core";
import Navbar from "../global/navbar";
import $ from 'jquery';
import '../../assets/css/imageConatiner.css';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import UserCard from "../global/UserCard.jsx";
import { ProductsDetailPageProductList } from "../pagesComponents/ProductDetailPage/ProductsDetailPageProductList";
import VisibilityIcon from '@material-ui/icons/Visibility';
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import EmailIcon from "@material-ui/icons/Email";
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'
import { Skeleton } from "@material-ui/lab";
import NoConnection from "../global/NoConnection";
import { RWebShare } from "react-web-share";
import FavoriteIcon from '@material-ui/icons/Favorite';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import { Dialog, DialogContent, DialogContentText } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import IconButton from '@material-ui/core/IconButton';
import { InputBase } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { Snackbar } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import placeholder from '../../assets/img/logo.png'
import { v4 as uuidv4 } from 'uuid'
import { Cancel, FitnessCenter, LocalOffer, Score, ShoppingCartOutlined, VerticalAlignTop } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { update_cart_action } from "../../redux/actions/cartAction";
import { useHistory } from "react-router-dom";
import { update_login_action } from "../../redux/actions/loginAction";
import b2_truck from '../../assets/promotional_banner/b2_truck.png'
import SmallTabBarMargin from "../global/SmallTabBarMargin";

const labels = {
  1: 'Pésimo',
  2: 'Malo',
  3: 'Medio',
  4: 'Bueno',
  5: 'Excelente',
};

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    page: {
        background: '#fefbfb',
        width: '100%'
    },
    main: {
        textAlign:'center',
        height: '320px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around'
    },
    image: {
        height: '320px',
        marginRight: '2px',
        borderRadius: '15px',
        display: 'inline-block',
        lineHeight: '100px',
        '&:hover': {
          cursor: 'pointer'
        },
        [theme.breakpoints.down('sm')]: {
          borderRadius: '15px'
        },
    },
    imageContainer: {
        verticalAlign: 'middle',
        display: 'inline-block',
        whiteSpace: 'nowrap',
        overflowX: 'auto',
        overflowY: 'hidden',
        borderRadius: '15px',
        width: '90%',
        marginLeft: '5%',
        marginRight: '5%',
        // [theme.breakpoints.up('lg')]: {
        //   width: '80%',
        //   marginLeft: '10%',
        //   marginRight: '10%',
        // },
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          borderRadius: '0px',
          marginLeft: '0px',
          marginRight: '0px',
        },
    },
    next: {
        textAlign: 'center',
        position: 'absolute',
        color: 'black',
        right: '0',
        verticalAlign: 'center',
        top: '70px',
        paddingLeft: '10px',
        zIndex: '100',
        height: '250px',
        lineHeight: '250px',
        borderRadius: '10px 0px 0px 10px',
        width: '60px',
        fontSize: '25px',
        cursor: 'pointer',
        transition: '0.6s ease',
        
        [theme.breakpoints.down('sm')]: {
          top: '100px',
          display: 'none'
        },
    },
    prev: {
      textAlign: 'center',
      position: 'absolute',
      left: '0',
      color: 'black',
      verticalAlign: 'center',
      top: '70px',
      paddingRight: '10px',
      zIndex: '100',
      height: '250px',
      lineHeight: '250px',
      borderRadius: '0px 10px 10px 0px',
      width: '60px',
      fontSize: '25px',
      cursor: 'pointer',
      transition: '0.6s ease',
      
      [theme.breakpoints.down('sm')]: {
        top: '100px',
        display: 'none'
      },
    },
    productInfo: {
      paddingLeft: importUrl? '20%' : '430px',
      marginTop: '20px',
      width: '100%',
      paddingRight: importUrl? '20%' : '5%',
      [theme.breakpoints.down('sm')]: {
        marginTop: '120px',
        paddingLeft: '0px',
        paddingRight: '0px',
        width: '95%',
        marginRight: 'auto',
        marginLeft: 'auto'
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: '10px',
      },
    },
    productTitle: {
      fontSize: '22px',
      fontWeight: '700'
    },
    productPrice: {
      fontSize: '18px',
      fontWeight: '600',
      color: colors.secondary,
      marginBottom: '8px'
    },
    productState: {
      fontSize: '13px',
      fontWeight: '600',
      lineHeight: '25px',
      height: '25px',
      marginTop: 'auto',
      marginRight: 'auto',
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    anotherProducts: {
      fontSize: '18px',
      marginBottom: '10px',
      color: colors.secondary,
      fontWeight: '600',
    },
    productStateHolder: {
      backgroundColor: '#F7B500',
      color: '#FFFFFF',
      height: '25px',
      marginTop: '5px',
      marginLeft:'auto',
      borderRadius: '8px'
    },
    visibilityIcon: {
      color: '#C9C9C9',
      padding:'1px',
      paddingBottom: '4px',
      marginBottom: '2px'
    },
    colorIcon: {
      color: colors.primary,
      padding:'4px',
      paddingBottom: '5px',
      marginBottom: '2px'
    },
    seenAmount: {
      color: '#4A4A4A',
      fontSize: '14px'
    },
    calification: {
      color: '#4A4A4A',
      fontSize: '14px',
      textDecoration: 'underline',
      marginLeft: 'auto',
      cursor: 'pointer'
    },
    smallText: {
      color: '#999FB0',
      fontSize: '14px',
      marginRight: '10px'
    },
    favorite: {
      cursor: 'pointer',
      color: colors.redHeart,
      height: '36px',
      width: '36px',
      marginLeft: 'auto',
      marginTop: '3px',
      marginRight: '5px',
      padding: '8px',
      borderRadius: '3px',
      '&:hover':{
        backgroundColor: alpha(colors.redHeart, 0.2)
      }
    },
    addCart: {
      cursor: 'pointer',
      height: '40px',
      width: '40px',
      color: colors.greenCart,
      marginTop: '3px',
      padding: '8px',
      borderRadius: '3px',
      '&:hover':{
        backgroundColor: alpha(colors.greenCart, 0.2)
      }
  },
  addCartButtonGreen: {
    color: colors.greenCart,
    border: `solid 1.5px ${colors.greenCart}`,
    marginTop: '5px'
  },
    share: {
        cursor: 'pointer',
        height: '36px',
        width: '36px',
        color: colors.blueShare,
        marginTop: '3px',
        padding: '8px',
        borderRadius: '3px',
        '&:hover':{
          backgroundColor: alpha(colors.blueShare, 0.2)
        }
    },
    rateProduct: {
      marginLeft: 'auto',
      marginTop: '-5px',
      fontSize: '15px',
      color: '#4A4A4A',
      cursor: 'pointer',
      '&:hover':{
        textDecoration: 'underline'
      }
    },
    shareMargin: {
      marginLeft: 'auto'
    },
    cancelButton: {
      padding: '4px',
      display: 'block',
      margin: '0px 0px 0px auto'
  },
  inputHolder: {
    display: 'flex',
    marginTop: '10px',
    marginBottom: '5px',
    backgroundColor:'#ffffff', 
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);', 
    borderRadius: '10px',
    '&:hover': {
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
    },
    '&:focus': {
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
    }
},
inputRootLogin: {
    width: '100%',
},
inputInputLogin: {
    height: 22,
    fontSize: '1rem',
    fontWeight: 400,
    // vertical padding + font size from searchIcon
    transition: theme.transitions.create('width'),
    width: '100%',
    paddingLeft: '10px',
    paddingRight: '10px',
    '&::placeholder': { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: 'rgba(0, 0, 0, 0.54)',
        opacity: 1 /* Firefox */
      },
      
      '&:-ms-input-placeholder': { /* Internet Explorer 10-11 */
        color: 'rgba(0, 0, 0, 0.54)'
      },
      
      '&::-ms-input-placeholder': { /* Microsoft Edge */
        color: 'rgba(0, 0, 0, 0.54)'
      },
},
primaryButton: {
  display: 'block',
  marginLeft: 'auto',
  marginRight: '7px',
  marginTop: '15px',
  backgroundColor: colors.primary,
  borderColor: colors.primary,
  borderRadius: '10px',
  border: 'solid 1.5px',
  color: '#FFFFFF',
  '&:hover': {
      backgroundColor: alpha(colors.primary, 0.85),
  }
},
productList: {
  [theme.breakpoints.down('xs')]: {
    marginBottom: '170px'
  },
},
minusCart: { 
  border: `solid 1.9px ${colors.greenCart}`,
  marginLeft: 'auto',
  borderRadius: '10px',
  width: '35px',
  borderRight: `solid 1px ${colors.greenCart}`,
  borderTopRightRadius: '0px',
  borderBottomRightRadius: '0px',
  fontSize: '22px',
  textAlign: 'center',
  lineHeight: '23px',
  fontWeight: '800',
  color: colors.greenCart,
  cursor: 'pointer',
  userSelect: 'none',
  '&:hover': {
      backgroundColor: alpha(colors.greenCart, 0.2)
  }
},
sumCart: {
  border: `solid 1.9px ${colors.greenCart}`,
  marginRight: '10px',
  borderRadius: '10px',
  width: '35px',
  borderLeft: `solid 1px ${colors.greenCart}`,
  borderTopLeftRadius: '0px',
  borderBottomLeftRadius: '0px',
  fontSize: '22px',
  textAlign: 'center',
  lineHeight: '28px',
  fontWeight: '800',
  userSelect: 'none',
  color: colors.greenCart,
  cursor: 'pointer',
  '&:hover': {
      backgroundColor: alpha(colors.greenCart, 0.2)
  }
},
cartCount: {
  borderTop: `solid 1.9px ${colors.greenCart}`,
  borderBottom: `solid 1.9px ${colors.greenCart}`,
  fontSize: '16px',
  paddingLeft: '8px',
  paddingRight: '8px',
  textAlign: 'center',
  lineHeight: '28px',
  fontWeight: '600',
},
modalPaper: {
  position: 'absolute',
  top: `50%`,
  left: `50%`,
  transform: `translate(-50%, -50%)`,
  width: 400,
  borderRadius: '10px',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
},
mainText: { 
  fontWeight: '600', 
  fontSize: '18px',
  marginTop: '10px'
},
addCartButton: {
  color: 'white',
  backgroundColor: colors.secondary,
  border: `solid 1.5px ${colors.secondary}`,
  width: '200px',
  marginRight: 'auto',
  '&:hover': {
      backgroundColor: alpha(colors.secondary,0.8)
  }
},
editButton: {
  marginLeft: 'auto',
  marginRight: '7px',
  backgroundColor: '#FFFFFF',
  borderColor: colors.orangeEdit,
  borderRadius: '3px',
  border: 'solid 1.5px',
  color: colors.orangeEdit,
  '&:hover': {
      backgroundColor: alpha(colors.orangeEdit, 0.2),
  }
},
fastDeliveryIcon: {
  height: '15px',
  width: 'auto',
  marginRight: '2px',
  marginBottom: '4px'
},
fastDeliveryText: {
  fontSize: '11px', 
  fontWeight: '600',
  [theme.breakpoints.down('xs')]: {
      fontSize: '9px', 
      fontWeight: '700',
  }
}
}))

  

 const ProductDetailPage = ({props, children}) => {
    const classes  = useStyles();
    const history = useHistory();
    const {id} = useParams();
    const [userProps, setUserProps] = useState({loading: true})
    const [cartCount, setCartCount] = useState(1)
    const [onCart, setOnCart] = useState(0)
    const [openModal, setOpenModal] = useState(false)
    const [cartText, setCartText] = useState('')
    const [openCartSnack, setOpenCartSnack] = useState(false)
    const [openCartSuccessSnack, setOpenCartSuccessSnack] = useState(false)

    //Calification Dialog
    const descriptionCalificationElementRef = React.useRef(null);
    const [openCalification, setOpenCalification] = useState(false)
    const [addingFavorite, setAddingFavorite] = useState(false)
    const [value, setValue] = React.useState(4);
    const [hover, setHover] = React.useState(-1);
    const [calificationText, setCalificationText] = useState("")
    const [calificationTextError, setCalificationTextError] = useState(false)
    const [sendingCalification, setSendingCalification] = useState(false)
    const [openSuccesSnack, setOpenSuccesSnack] = useState(false)
    const [openApiErrorSnack, setOpenApiErrorSnack] = useState(false)
    const [openLoginNeededSnack, setOpenLoginNeededSnack] = useState(false)
    const [openCartErrorSnack, setOpenCartErrorSnack] = useState(false)
    const [updatingCart, setUpdatingCart] = useState(false)

    const [isProductFavorite, setIsProductFavorite] = useState(false)
    const [loading, setLoading] = useState(true);
    const [loadingUser, setLoadingUser] = useState(true);
    const [imageUrls, setImageUrls] = useState([])
    const [openLightbox, setOpenLightbox] = useState(false)
    const [lightboxIndex, setLightboxIndex] = useState(0)
    const [apiError, setApiError] = useState(false);
    const [product, setProduct] = useState(null);

    const [sameUser, setSameUser] = useState(false)

    const cart_store_selector = useSelector((state) => state.cart_store)
    const userLogged = useSelector((state) => state.user_store.user)
    const dispatch = useDispatch()

    useEffect(() => {
        const fetchProduct = async () => {
            try{
              loadFavoritesList()
              let requestOptions = {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json'
                  }
                };
              let urlGetProduct = `${url}/api/${importUrl? 'productsImport' : 'products'}/${id}`
              if(userLogged){
                requestOptions = {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `${userLogged.accessToken}` }
                };
              }
              else{
                const isSession = window.localStorage.getItem('session')
                if(isSession){
                  const session = JSON.parse(isSession);
                  urlGetProduct += `?sessionId=${session._id}`
                }
                else{
                  const newSessionId = uuidv4()
                  window.localStorage.setItem(
                    'session', JSON.stringify({_id: newSessionId})
                  )
                  urlGetProduct += `?sessionId=${newSessionId}`
                }
              }
              let result = await fetch(urlGetProduct, requestOptions)
              if(result.status === 200){
                let newData = await result.json();
                const urls = [];
                if (newData.mainImageUrl) urls.push({index: 0, url:newData.mainImageUrl});
                if (newData.secondaryImagesUrl) {
                  let i = 1
                  newData.secondaryImagesUrl.forEach(element => {
                    urls.push({index: i, url:element})
                    i += 1
                  });
                }
                setImageUrls(urls);
                setProduct(newData);
                let isUserOwner = false
                if(userLogged){
                  isUserOwner = userLogged._id === newData.user._id
                }
                setSameUser(isUserOwner)
                setUserProps({loading: false, username: newData.user.username, profilePictureUrl: newData.user.profilePictureUrl, _id: newData.user._id, isUserOwner : isUserOwner, reviewsCount: newData.user.reviewsCount, reviewsRate: newData.user.reviewsRate, productId: newData._id, productName: newData.name, productCurrency: newData.currency, fixablePrice: newData.fixablePrice, lastUpload: newData.lastUpload, handleOpinionsOpen: checkIfLogged, certification: newData.user.certification})
                setLoading(false);
                setLoadingUser(false)
              }
              else{
                setApiError(true)
                setLoading(false)
                setLoadingUser(false)
              }
            }catch(error){
                setApiError(true);
                setLoading(false);
                setLoadingUser(false)
            }
        }
        fetchProduct()
    }, [])

  useEffect(() => {
    if(!product || !importUrl) return
    if(userLogged){
      if(cart_store_selector.products.length > 0){
          for (let index = 0; index < cart_store_selector.products.length; index++) {
              if(cart_store_selector.products[index].product._id === product._id){
                  setOnCart(cart_store_selector.products[index].count)
                  break;
              }
          }
      }
    }
  },[product])

  const memoizedScroll = useCallback(
    (direction) => {
      let far = $( '.image-container' ).width()/2*direction;
      let pos = $('.image-container').scrollLeft() + far;
      $('.image-container').animate( { scrollLeft: pos }, 1000)
    },
    [],
  );

  const pictureOnSelect = (key) => {
      setLightboxIndex(key)
      setOpenLightbox(true)
  };

  const loadFavoritesList = () => {
    if(importUrl){
      if(userLogged){
        const userId = userLogged._id
        const data = window.localStorage.getItem(`importFavorites`)
        if(data){
          const newData = JSON.parse(data)
          if(newData[`${userId}`]){
            if(newData[`${userId}`][`${id}`]){
              setIsProductFavorite(true)
            }
          }
        }             
      }
    }
    else{
      if(userLogged){
        const userId = userLogged._id
        const data = window.localStorage.getItem(`${userId}`)
        if(data){
            const newData = JSON.parse(data)
            if(newData[`${id}`]){
              setIsProductFavorite(true)
            }
        }             
      }
    }
  }


    // #region FavoriteOnClick
    const AddToFavorite = (event) => {
      if(addingFavorite)return;
      if(importUrl){
          if(userLogged){
              setAddingFavorite(true)
              const userId = userLogged._id
              const data = window.localStorage.getItem(`importFavorites`)
              if(data){
                  const newData = JSON.parse(data)
                  if(newData[`${userId}`])
                  {
                      newData[`${userId}`][`${product._id}`] = product
                      window.localStorage.setItem(
                          `importFavorites`, JSON.stringify(newData)
                      )
                  }
                  else{
                      newData[`${userId}`] = {}
                      newData[`${userId}`][`${product._id}`] = product
                      window.localStorage.setItem(
                          `importFavorites`, JSON.stringify(newData)
                      )
                  }
                  setAddingFavorite(false)
                  setIsProductFavorite(true)
              }
              else{
                  let newData = {}
                  newData[`${userId}`] = {}
                  newData[`${userId}`][`${product._id}`] = product
                  window.localStorage.setItem(
                      `importFavorites`, JSON.stringify(newData)
                  )
                  setAddingFavorite(false)
                  setIsProductFavorite(true)
              }
          }
          else{
              setOpenLoginNeededSnack(true)
              dispatch(update_login_action(true))
          }
      }
      else{
          if(userLogged){
              setAddingFavorite(true)
              const userId = userLogged._id
              const data = window.localStorage.getItem(`${userId}`)
              const productToAdd = {
                  _id : product._id,
                  mainImageUrl : product.mainImageUrl,
                  name: product.name,
                  price : product.price,
                  currency : product.currency,
                  category: product.category,
                  user : {
                      _id : product.user._id,
                      username : product.user.username
                  }
              }
              if(data){
                  const newData = JSON.parse(data)
                  newData[`${product._id}`] = productToAdd
                  window.localStorage.setItem(
                      `${userId}`, JSON.stringify(newData)
                  )
                  setAddingFavorite(false)
                  setIsProductFavorite(true)
              }
              else{
                  const newData = {}
                  newData[`${product._id}`] = productToAdd
                  window.localStorage.setItem(
                      `${userId}`, JSON.stringify(newData)
                  )
                  setAddingFavorite(false)
                  setIsProductFavorite(true)
              }
          }
          else{
              setOpenLoginNeededSnack(true)
              dispatch(update_login_action(true))
          }
      }
  }

  const RemoveFromFavorite = (event) => {
    if(addingFavorite)return;
    if(importUrl){
        if(userLogged){
            setAddingFavorite(true)
            const userId = userLogged._id
            const data = window.localStorage.getItem(`importFavorites`)
            if(data){
                const newData = JSON.parse(data)
                if(Object.keys(newData[`${userId}`]).length === 1){
                    if(Object.keys(newData).length === 1)
                    {
                        window.localStorage.removeItem(
                            `importFavorites`, JSON.stringify(newData)
                        )
                    }
                    else{
                        delete newData[`${userId}`][`${product._id}`]    
                        window.localStorage.setItem(
                            `importFavorites`, JSON.stringify(newData)
                        )    
                    }
                }
                else{
                    if(newData[`${userId}`][`${product._id}`]){
                        delete newData[`${userId}`][`${product._id}`]
                    }
                    window.localStorage.setItem(
                        `importFavorites`, JSON.stringify(newData)
                    )
                }
                setAddingFavorite(false)
                setIsProductFavorite(false)
            }
            else{
                setAddingFavorite(false)
                setIsProductFavorite(false)
            }
        }
        else{
            setOpenLoginNeededSnack(true)
            dispatch(update_login_action(true))
        }
    }
    else{
        if(userLogged){
            setAddingFavorite(true)
            const userId = userLogged._id
            const data = window.localStorage.getItem(`${userId}`)
            if(data){
                const newData = JSON.parse(data)
                if(Object.keys(newData).length === 1){
                    window.localStorage.removeItem(
                        `${userId}`, JSON.stringify(newData)
                    )
                }
                else{
                    if(newData[`${product._id}`]){
                        delete newData[`${product._id}`]
                    }
                    window.localStorage.setItem(
                        `${userId}`, JSON.stringify(newData)
                    )
                }
                setAddingFavorite(false)
                setIsProductFavorite(false)
            }
            else{
                setAddingFavorite(false)
                setIsProductFavorite(false)
            }
        }
        else{
            setOpenLoginNeededSnack(true)
            dispatch(update_login_action(true))
        }
    }
}

const calcSendingCost = () => {
  const volumeWeight = product.large * product.width * product.height / 6000 
  if(volumeWeight < product.weight){
      return product.weight * sendingCostValue
  }
  return volumeWeight * sendingCostValue
}

const reduceDecimalValue = (number) => {
  if(Math.floor(number.valueOf()) === number.valueOf()) return number;
  const hasMoreDecimal = number.toString().split(".")[1].length || 0
  if(hasMoreDecimal > 2){
      return number.toFixed(2)
  }
  return number 
}

  const onClickAddToCart = () => {
    if(!userLogged) {
        setOpenLoginNeededSnack(true)
        dispatch(update_login_action(true))
        return;
    }
    if(cartCount + onCart > product.maxCount){
        setCartText(`Este producto tiene un máximo de ${product.maxCount} por pedido. Vaya al carrito para realizar su pedido y poder añadir más de este producto a un nuevo pedido.`)
        setOpenCartSnack(true)
        return
    }
    if(cart_store_selector.points + (product.points) > 200){
        setCartText(`No puede exceder los 200 puntos permitidos por pedido. Vaya al carrito para realizar su pedido y poder añadir más de este producto a un nuevo pedido.`)
        setOpenCartSnack(true)
        return
    }
    setOpenModal(true)
}

const addToCart = () => {
    if(!userLogged) {
        setOpenLoginNeededSnack(true)
        dispatch(update_login_action(true))
        return;
    }
    if(cartCount + onCart >= product.maxCount){
        setCartText(`Este producto tiene un máximo de ${product.maxCount} por pedido. Vaya al carrito para realizar su pedido y poder añadir más de este producto a un nuevo pedido.`)
        setOpenCartSnack(true)
        return
    }
    if(cart_store_selector.points + (product.points*(cartCount+1)) > 200){
        setCartText(`No puede exceder los 200 puntos permitidos por pedido. Vaya al carrito para realizar su pedido y poder añadir más de este producto a un nuevo pedido.`)
        setOpenCartSnack(true)
        return
    }
    setCartCount(cartCount+1)
    
}

const onClickAddConfirm = async () => {
    if(cartCount + onCart > product.maxCount){
        setCartText(`Este producto tiene un máximo de ${product.maxCount} por pedido. Vaya al carrito para realizar su pedido y poder añadir más de este producto a un nuevo pedido.`)
        setOpenCartSnack(true)
        return
    }
    setUpdatingCart(true)
    const newWeight = cart_store_selector.weight+(product.weight*cartCount)
    const newPoints = cart_store_selector.points+(product.points*cartCount)
    const newProducts = await updateCartProducts(cartCount)
    if(newProducts.length > 0){
      setOpenCartSuccessSnack(true)
      dispatch(update_cart_action(cartCount, newProducts, newWeight, newPoints))
      setOpenModal(false)
      setOnCart(cartCount + onCart)
      setCartCount(1)
    }
    else{
      setOpenCartErrorSnack(true)
    }
    setUpdatingCart(false)
    
}

const removeFromCart = () => {
    if(updatingCart){
      return;
    }
    if(!userLogged) {
        setOpenLoginNeededSnack(true)
        dispatch(update_login_action(true))
        return;
    }
    if(cartCount === 1){
        return
    }
    else{
        setCartCount(cartCount-1)
    }
}

const updateCartProducts = async (quantity) => {
  if(userLogged){
    try{
        let newCart = JSON.parse(JSON.stringify(cart_store_selector.products))
        let founded = false
        for (let index = 0; index < newCart.length; index++) {
            if(newCart[index].product._id === product._id){
                newCart[index].count += quantity
                founded = true
                break;
            }
        }
        if(!founded){
            newCart.push({product: product, count: quantity})
        }
        let requestOptions = {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `${userLogged.accessToken}`
            },
            body:JSON.stringify({ 
                cart: newCart
            })
        };
        let urlGetProduct = `${url}/api/accountImport/updateCart`
        let result = await fetch(urlGetProduct, requestOptions)
        if(result.status === 200){
            return newCart
        }
        else{
            console.log(result)
            return []
        }
        }catch(error){
            console.log(error)
            return []
        }
    }
    else{
        setOpenLoginNeededSnack(true)
        dispatch(update_login_action(true))
    }
}

const handleCloseCartSnack = () => {
    setOpenCartSnack(false)
}

const handleCloseSuccessCartSnack = () => {
  setOpenCartSuccessSnack(false)
}

const handleCloseCartErrorSnack = () => {
  setOpenCartErrorSnack(false)
}

const handleClose = () => {
  setCartCount(1)
  setOpenModal(false)
}
  // #endregion

  const handleCalificationClose = () => {
    setOpenCalification(false)
}
const handleChangeCalificationText = (event) => {
  if(calificationTextError){
      setCalificationTextError(false)
  }
  setCalificationText(event.target.value);
};

const onClickSend = async(event) => {
  if(sendingCalification)return;
  if(!calificationText){
    setCalificationTextError(true)
    return;
  }
  setSendingCalification(true)
  try{
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json',
                  'Authorization': `${userLogged.accessToken}`
      },
      body: JSON.stringify({ 
        rate : value,
        description : calificationText,
        productId : product._id,
        productName: product.name, 
        userId : product.user._id,
        username : userLogged.username
      })
    };
    let result = await fetch(`${url}/api/reviews`, requestOptions);
    if(result.status === 201){
      setOpenSuccesSnack(true)
      setSendingCalification(false)
      setCalificationText("")
      setValue(4)
      const user = {
        loading : false,
        username : userProps.username,
        profilePictureUrl : userProps.profilePictureUrl,
        _id : userProps._id,
        isUserOwner: userProps.isUserOwner,
        reviewsCount: userProps.reviewsCount + 1,
        reviewsRate: userProps.reviewsRate + value,
        productId : userProps.productId,
        productName: userProps.name,
        productCurrency: userProps.currency,
        fixablePrice: userProps.fixablePrice,
        lastUpload: userProps.lastUpload,
        handleOpinionsOpen: checkIfLogged,
        certification: userProps.certification
      }
      setUserProps(user)
    }
    else if(result.status === 200){
      const newValue = await result.json(); 
      setOpenSuccesSnack(true)
      setSendingCalification(false)
      setCalificationText("")
      setValue(4)
      const user = {
        loading : false,
        username : userProps.username,
        profilePictureUrl : userProps.profilePictureUrl,
        _id : userProps._id,
        isUserOwner: userProps.isUserOwner,
        reviewsCount: userProps.reviewsCount,
        reviewsRate: userProps.reviewsRate + newValue.newRate,
        productId : userProps.productId,
        productName: userProps.name,
        productCurrency: userProps.currency,
        fixablePrice: userProps.fixablePrice,
        lastUpload: userProps.lastUpload,
        handleOpinionsOpen: checkIfLogged,
        certification: userProps.certification
      }
      setUserProps(user)
    }
    else{
      setOpenApiErrorSnack(true)
      setSendingCalification(false)
    }
  }
  catch{
    setOpenApiErrorSnack(true)
    setSendingCalification(false)
  }
  setOpenCalification(false)
  setOpenSuccesSnack(true)
}

const checkIfLogged = () => {
      if(userLogged){
        setOpenCalification(true)
      }
      else{
        setOpenLoginNeededSnack(true)
        dispatch(update_login_action(true))
      }
}

const handleCloseSuccesSnack = () => {
  setOpenSuccesSnack(false)
}
const handleCloseApiErrorSnack = () => {
  setOpenApiErrorSnack(false)
}
const handleCloseLoginNeededSnack = () => {
  setOpenLoginNeededSnack(false)
}

const showPhone = () => {
  if(product.user.privatePhone){
    return "Privado"
  }
  if(product.user.phone){
    return product.user.phone
  }
  return "Privado"
}

const showEmail = () => {
  if(product.user.privateEmail){
    return "Privado"
  }
  if(product.user.email){
    return product.user.email
  }
  return "Privado"
}

const calculateTimeDifference = (lastPublished) => {
  const currentDate = new Date()
  const milSecDiff = currentDate.getTime() - lastPublished
  const daysDiffFloat = milSecDiff /  (1000 * 3600 * 24)
  const daysDiff = parseInt(daysDiffFloat)
  if (daysDiff > 365){
      return "Publicado hace más de un año.";
  }
  else if(daysDiff <= 365 && daysDiff > 182){
    return "Publicado hace más de 6 meses.";
  }
  else if(daysDiff <= 182 && daysDiff > 150){
    return "Publicado hace 5 meses.";
  }
  else if(daysDiff <= 150 && daysDiff > 120){
    return "Publicado hace 4 meses.";
  }
  else if(daysDiff <= 120 && daysDiff > 90){
    return "Publicado hace 3 meses.";
  }
  else if(daysDiff <= 90 && daysDiff > 60){
    return "Publicado hace 2 meses.";
  }
  else if(daysDiff <= 60 && daysDiff > 30){
    return "Publicado hace 1 mes.";
  }
  else if(daysDiff <= 30 && daysDiff >= 1){
    return `Publicado hace ${daysDiff} ${daysDiff === 1?  "día":"días"}.`;
  }
  const hoursDif = parseInt(milSecDiff /  (1000 * 3600))
  if(hoursDif >= 1){
    return `Publicado hace ${hoursDif} ${hoursDif===1? "hora":"horas"}.`;
  }
  const minutesDif = parseInt(milSecDiff / (1000 * 60))
  if(minutesDif >= 1){
    return `Publicado hace ${minutesDif} ${minutesDif === 1? "minuto": "minutos"}.`;
  }
  return `Publicado hace menos de un minuto`
}

const editProductOnClick = (event) => {
  history.push(`/myproducts/edit/${id}`)
  //window.scrollTo(0, 0);
}
    
    return (
        <React.Fragment>
            <Navbar/>
            {apiError?
              <NoConnection />
            :
            <React.Fragment>
              <Snackbar open={openCartSnack} autoHideDuration={5000} onClose={handleCloseCartSnack}>
                  <Alert onClose={handleCloseCartSnack} severity="warning">
                      {cartText}
                  </Alert>
              </Snackbar>
              <Snackbar open={openCartSuccessSnack} autoHideDuration={1500} onClose={handleCloseSuccessCartSnack}>
                  <Alert onClose={handleCloseSuccessCartSnack} severity="success">
                      Producto añadido al carrito
                  </Alert>
              </Snackbar>
              <Snackbar open={openCartErrorSnack} autoHideDuration={5000} onClose={handleCloseCartErrorSnack}>
                  <Alert onClose={handleCloseCartErrorSnack} severity="error">
                      No se pudo añadir el producto al carrito. Revise su conexión a internet. Si el problema persiste puede añadir
                      el producto a favoritos para añadirlo al carrito más tarde.
                  </Alert>
                </Snackbar>
            {loading?
              <div>
                <div className={classes.main}>
                  <div className="wrapper" style={{marginTop:'2px'}}>
                    <div className={classes.prev} onClick={() => memoizedScroll(-1)}>&#10094;</div>
                      <div className={`${classes.imageContainer} image-container`}>
                        <Skeleton className={classes.image}
                          variant='rect' width='250px' height='320px'/>
                        <Skeleton className={classes.image} 
                          variant='rect' width='250px' height='320px'/>
                        <Skeleton className={classes.image} 
                          variant='rect' width='250px' height='320px'/>
                        <Skeleton className={classes.image} 
                          variant='rect' width='250px' height='320px'/>
                        <Skeleton className={classes.image} 
                          variant='rect' width='250px' height='320px'/>
                      </div>
                      <div className={classes.next} onClick={() => memoizedScroll(1)}>&#10095;</div>
                  </div>
                </div>
                <div className={classes.productInfo}>
                  <div style={{display:'flex'}}>
                    <Skeleton 
                      variant='rect' width='100px' height='25px'/>
                    <Skeleton className={classes.productStateHolder}
                      variant='rect' width='60px' height='25px'/>
                  </div>
                  <div style={{display: 'flex'}}>
                    <Skeleton style={{backgroundColor:colors.primary, marginRight: '5px'}}
                      width='60px' height='25px'/>
                    <FavoriteBorderOutlinedIcon size='large' className={classes.favorite}/>
                    <ShareOutlinedIcon size='large' className={classes.share}/>
                  </div>
                  <div style={{display: 'flex'}}>
                    <div>
                      <VisibilityIcon className={classes.visibilityIcon} />
                    </div>
                    <div>
                      <Skeleton width='30px' height='20px'/>
                    </div>
                    <div>
                      <Typography style={{marginLeft: '12px'}} className={classes.seenAmount}>
                        <Skeleton width='90px' height='20px'/>
                      </Typography>
                    </div>
                  </div>
                  <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    <div style={{display: 'flex'}}>
                      <LocationOnIcon className={classes.colorIcon} />
                      <Skeleton width='70px' height='20px'/>
                    </div>
                    <div style={{display: 'flex'}}>
                      <LocalPhoneIcon className={classes.colorIcon} /> 
                      <Skeleton width='70px' height='20px'/> 
                    </div>
                    <div style={{display: 'flex'}}>
                      <EmailIcon className={classes.colorIcon} />
                      <Skeleton width='70px' height='20px'/>
                    </div>
                  </div>
                  <div style={{backgroundColor: 'rgba(0, 0, 0, 0.1)', width: '100%', height: '1px'}}/>
                  <div style={{marginTop: '3px', marginBottom: '20px'}}>
                    <Skeleton width='100%' height='20px'/>
                    <Skeleton width='100%' height='20px'/>
                    <Skeleton width='100%' height='20px'/>
                    <br />
                    <Skeleton width='100%' height='20px'/>
                    <Skeleton width='100%' height='20px'/>
                    <Skeleton width='100%' height='20px'/>
                  </div>
                  {product&&
                  <React.Fragment>
                  <div>
                    <Typography className={classes.anotherProducts}>
                      Otros Anuncios
                    </Typography>
                  </div>
                  </React.Fragment>
                  }
                </div>
              </div>
            :
              <div>
                {importUrl=== 1 &&
                <Modal
                  open={openModal}
                  onClose={handleClose}
                  aria-labelledby="next order state"
                  aria-describedby="data necessary to process to next state"
                >
                  <div className={classes.modalPaper}>
                      <div style={{display: 'flex', marginBottom: '5px', alignContent: 'top'}}> 
                          <Typography className={classes.mainText}>{product.name}</Typography>
                          <IconButton style={{marginLeft: 'auto', height: '48px', width: '48px'}} onClick={() => {setOpenModal(false)
                                                      setCartCount(1)}} >
                              <Cancel />
                          </IconButton>
                      </div>
                      <Typography style={{color: colors.secondary, fontWeight: '600'}}>{reduceDecimalValue(product.price)} USD + {reduceDecimalValue(calcSendingCost()) === 0? "(Envío Gratis)" : `${reduceDecimalValue(calcSendingCost())} usd (envío)`}</Typography>
                      <div style={{display: 'flex'}}>
                        <Typography style={{fontSize: '14px', fontWeight: '600', marginTop: '5px'}}>Puntos: {reduceDecimalValue(product.points)}</Typography>
                        <Typography style={{fontSize: '14px', fontWeight: '600', marginTop: '5px', marginLeft: '20px'}}>Cant. Max: {product.maxCount}</Typography>
                      </div>
                      <div style={{display:'flex', marginBottom: '5px', marginTop: '25px'}}>
                          <div style={{display: 'flex', height: '30px', marginTop: '5px', marginLeft: 'auto'}}>
                              <Typography onClick={removeFromCart} className={classes.minusCart}>-</Typography>
                              <Typography className={classes.cartCount}>{cartCount}</Typography>
                              <Typography onClick={addToCart} className={classes.sumCart}>+</Typography>
                          </div>
                          {updatingCart?
                            <Button variant='outlined' className={classes.addCartButton}>
                                <CircularProgress style={{width: '20px', height: '20px', color: 'white'}}/>   
                            </Button>
                            :
                            <Button disabled={updatingCart} onClick={onClickAddConfirm} variant='outlined' className={classes.addCartButton}>
                                Agregar al <ShoppingCartOutlined style={{color: 'white'}}/>
                            </Button>
                        }
                      </div>
                  </div>
                </Modal>
                }
                <Snackbar open={openSuccesSnack} autoHideDuration={2000} onClose={handleCloseSuccesSnack}>
                    <Alert onClose={handleCloseSuccesSnack} severity="success">
                        Calificación enviada correctamente.
                    </Alert>
                </Snackbar>
                <Snackbar open={openApiErrorSnack} autoHideDuration={2000} onClose={handleCloseApiErrorSnack}>
                    <Alert onClose={handleCloseApiErrorSnack} severity="error">
                        No se pudo enviar la Calificación. Revise su conexión a Internet.
                    </Alert>
                </Snackbar>
                <Snackbar open={openLoginNeededSnack} autoHideDuration={2000} onClose={handleCloseLoginNeededSnack}>
                    <Alert onClose={handleCloseLoginNeededSnack} severity="warning">
                        Debe iniciar sesión para realizar esta acción.
                    </Alert>
                </Snackbar>
                {openLightbox &&
                  <Lightbox
                    mainSrc={imageUrls[lightboxIndex].url}
                    nextSrc={placeholder}
                    prevSrc={placeholder}
                    onCloseRequest={() => setOpenLightbox(false)}
                    onMovePrevRequest={() =>
                      setLightboxIndex(
                        ((lightboxIndex + imageUrls.length - 1) % imageUrls.length),
                      )
                    }
                    onMoveNextRequest={() =>
                      setLightboxIndex(
                        (lightboxIndex + 1) % imageUrls.length,
                      )
                    }
                  />
                }
                <div className={classes.main}>
                  <div className="wrapper" style={{marginTop:'2px'}}>
                    <div className={classes.prev} onClick={() => memoizedScroll(-1)}>&#10094;</div>
                      <div className={`${classes.imageContainer} image-container`}>
                        {imageUrls.map(el => (
                          <img alt="" onClick={() => {pictureOnSelect(el.index)}} className={classes.image} src={el.url}/>
                        ))}
                      </div>
                    <div className={classes.next} onClick={() => memoizedScroll(1)}>&#10095;</div>
                  </div>
                </div>
                <div className={classes.productInfo}>
                  <div style={{display:'flex'}}>
                    <Typography className={classes.productTitle}>
                        {product.name}
                    </Typography>
                    {importUrl === 0 &&
                    <div className={classes.productStateHolder}>
                      <Typography className={classes.productState}>
                        {product.condition}
                      </Typography>
                    </div>
                    }
                    {importUrl === 1 && product.possibleDiscount &&
                    <LocalOffer style={{color: colors.secondary, width: '25px', height: '25px', marginTop: '5px', marginLeft: 'auto'}}/>
                    }
                  </div>
                  <div style={{display: 'flex'}}>
                    <Typography className={classes.productPrice}>
                      {reduceDecimalValue(product.price)} {product.currency} {importUrl === 1 && ` + ${reduceDecimalValue(calcSendingCost()) === 0? "(Envío Gratis)" : `${reduceDecimalValue(calcSendingCost())} usd (envío)`}`}
                    </Typography>
                    {!sameUser &&
                    <React.Fragment>
                      <Dialog
                        PaperProps={{
                          style: { borderRadius: '15px', backgroundColor: '#f5f5f5', width: '100%'}
                          }}
                          open={openCalification}
                          onClose={handleCalificationClose}
                          scroll="body"
                          aria-labelledby="scroll-dialog-title"
                          aria-describedby="scroll-dialog-description"
                          >
                          <DialogContent>
                            <DialogContentText
                              id="scroll-dialog-description"
                              ref={descriptionCalificationElementRef}
                              tabIndex={-1}
                            >
                            
                            <div style={{display: 'flex'}}>
                              <Typography style={{fontSize:'18px', fontWeight:'600', color: '#49DB89', paddingTop: '3px'}}>Calificar Producto</Typography>
                              <IconButton size="large" onClick={() => {setOpenCalification(false)}} aria-label="warning" className={classes.cancelButton}>
                                <CancelIcon />
                              </IconButton>
                            </div>
                            <div style={{paddingLeft:'15px', width: '100%', verticalAlign: 'middle', display:'table', height:'100%', marginBottom: '10px'}}>
                              <div style={{width: '220px', display: 'flex', marginTop: '10px'}}>
                              <Rating
                                name="hover-feedback"
                                value={value}
                                precision={1}
                                onChange={(event, newValue) => {
                                  setValue(newValue);
                                }}
                                onChangeActive={(event, newHover) => {
                                  setHover(newHover);
                                }}
                              />
                              {value !== null && <Box ml={2}>{labels[hover !== -1 ? hover : value]}</Box>}
                              </div>
                              <div className={classes.inputHolder}>
                                <InputBase
                                  elevation={4}
                                    placeholder="Escriba una pequeña reseña"
                                    type="text"
                                    multiline
                                    minRows={5}
                                    maxRows={5}

                                    value={calificationText}
                                    onChange={handleChangeCalificationText}
                                    classes={{
                                        root: classes.inputRootLogin,
                                        input: classes.inputInputLogin,
                                    }}
                                    inputProps={{ 'aria-label': 'search', maxLength:'250'}}
                                />
                              </div>
                              {calificationTextError &&
                                <Typography style={{color: 'rgb(251,82,100)', fontSize: '14px', marginLeft:'5px'}}>Debe escribir una reseña</Typography>                       
                              }
                              <Button disabled={sendingCalification} onClick={onClickSend} className={classes.primaryButton}>
                                Enviar
                              </Button>
                            </div>
                            {sendingCalification && <CircularProgress size={60} style={{position:'absolute',top:'43%', left:'40%'}}/>}
                          </DialogContentText>
                        </DialogContent>
                      </Dialog>
                      {isProductFavorite?
                          <FavoriteIcon size='large' onClick={RemoveFromFavorite} className={classes.favorite} />
                      :
                          <FavoriteBorderOutlinedIcon size='large' onClick={AddToFavorite} className={classes.favorite} />
                      }
                    </React.Fragment>
                    }
                    <RWebShare
                        data={{
                          url: `${appShareUrl}/products/detail/${id}`,
                          title: "SeVende",
                        }}
                        closeText="Cerrar"
                    >
                    <ShareOutlinedIcon size='large' className={`${classes.share} ${sameUser && classes.shareMargin}`}/>
                    </RWebShare>
                  </div>
                  {importUrl === 1 && product.fastDelivery &&
                    <div style={{display:'flex', alignItems: 'center', marginTop: '-10px', marginBottom: '10px'}}>
                        <img src={b2_truck} className={classes.fastDeliveryIcon} />
                        <Typography className={classes.fastDeliveryText}>ENTREGA INMEDIATA</Typography>
                    </div>
                  }
                  <div style={{display: 'flex'}}>
                    <div>
                      <VisibilityIcon className={classes.visibilityIcon} />
                    </div>
                    <div>
                      <Typography className={classes.seenAmount}>
                        {product.viewsCount}
                      </Typography>
                    </div>
                    {importUrl ?
                    <div style={{marginLeft: 'auto', display: 'flex'}}>
                      {sameUser &&
                      <Button
                          onClick={editProductOnClick}
                          variant="contained"
                          className={classes.editButton}
                          classes={{
                              label: classes.label, // class name, e.g. `classes-nesting-label-x`
                          }}
                      >EDITAR</Button>
                      }
                      <Button onClick={onClickAddToCart} variant='outlined' className={classes.addCartButtonGreen}>
                        Agregar al <AddShoppingCartIcon style={{color: colors.greenCart}}/>
                      </Button>
                    </div>
                    :
                    <div>
                      <Typography style={{marginLeft: '12px'}} className={classes.seenAmount}>
                        {calculateTimeDifference(product.lastUpload)}
                      </Typography>
                    </div>
                    }
                  </div>
                  {loadingUser?
                  <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    <div style={{display: 'flex'}}>
                      <LocationOnIcon className={classes.colorIcon} />
                      <Skeleton width='70px' height='20px'/>
                    </div>
                    <div style={{display: 'flex'}}>
                      <LocalPhoneIcon className={classes.colorIcon} /> 
                      <Skeleton width='70px' height='20px'/> 
                    </div>
                    <div style={{display: 'flex'}}>
                      <EmailIcon className={classes.colorIcon} />
                      <Skeleton width='70px' height='20px'/>
                    </div>
                  </div>
                  :
                  <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    {importUrl?
                    <React.Fragment>
                    <div style={{display: 'flex'}}>
                      <Score className={classes.colorIcon} />
                      <Typography className={classes.smallText} style={{color: colors.primary}}>
                        Puntos:
                      </Typography>
                      <Typography className={classes.smallText}>
                        {reduceDecimalValue(product.points)}
                      </Typography>
                    </div>
                    <div style={{display: 'flex'}}>
                      <FitnessCenter className={classes.colorIcon} /> 
                      <Typography className={classes.smallText} style={{color: colors.primary}}>
                        Peso:
                      </Typography> 
                      <Typography className={classes.smallText}>
                        {product.weight} kg
                      </Typography>
                    </div>
                    <div style={{display: 'flex'}}>
                      <VerticalAlignTop className={classes.colorIcon} />
                      <Typography className={classes.smallText} style={{color: colors.primary}}>
                        Cantidad Maxima por envio:
                      </Typography>
                      <Typography className={classes.smallText}>
                        {product.maxCount}
                      </Typography>
                    </div>
                    </React.Fragment>
                    :
                    <React.Fragment>
                    <div style={{display: 'flex'}}>
                      <LocationOnIcon className={classes.colorIcon} />
                      <Typography className={classes.smallText}>
                        {product.user.region? product.user.region: "Desconocida"}
                      </Typography>
                    </div>
                    <div style={{display: 'flex'}}>
                      <LocalPhoneIcon className={classes.colorIcon} /> 
                      <Typography className={classes.smallText}>
                        {showPhone()}
                      </Typography> 
                    </div>
                    <div style={{display: 'flex'}}>
                      <EmailIcon className={classes.colorIcon} />
                      <Typography className={classes.smallText}>
                        {showEmail()}
                      </Typography>
                    </div>
                    </React.Fragment>
                    }
                  </div>
                  }
                  <div style={{backgroundColor: 'rgba(0, 0, 0, 0.1)', width: '100%', height: '1px'}}/>
                  <div>
                    <Typography style={{marginTop: '3px', marginBottom: '20px', whiteSpace: 'pre-line'}} className={classes.seenAmount}>
                      {product.description}
                    </Typography>
                  </div>
                  <div>
                    <Typography className={classes.anotherProducts}>
                      {importUrl? `Anuncios de la misma Categoria` : `Otros Anuncios de ${product?.user?.username}`}
                    </Typography>
                  </div>
                  <div className={classes.productList}>
                    <ProductsDetailPageProductList username = {product.user.username} categoryId = {product.category._id} productId = {product._id} userId = {product.user._id}/>
                  </div>
                </div>
              </div>
            }
            {!importUrl && <UserCard props = {userProps}/>}
            <SmallTabBarMargin />
            </React.Fragment>
            }
        </React.Fragment>
    )
}

export default ProductDetailPage