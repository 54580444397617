let url = "https://sevende.herokuapp.com"
// url = "http://localhost:5000"
let importUrl = 0
// importUrl = 1
const appShareUrl = "https://sevendeapp.com"
const supportEmail = "sevende.app.info@gmail.com"

const sendingCostValue = 5.0
const packaging = 0
const guideCut = 25
const discountOverValue = 350
const discountOverTotalPrice = 20
const discountOverTotalPriceMessage = "Compra mayor a 350 usd"

const supportNumber = '5219987683030' 

const phoneAreas = ['+53', '+52', '+34', '+1', '+51', '+55' ]

const provinces = importUrl === 0? ["Desconocida","Artemisa","Camagüey","Ciego de Ávila","Cienfuegos","Granma",
                        "Guantánamo","Holguín","Isla de la Juventud","La Habana",
                        "Las Tunas","Matanzas","Mayabeque","Pinar del Río","Sancti Spíritus",
                        "Santiago de Cuba","Villa Clara"]
                        :
                        ["Provincia","Artemisa","Camagüey","Ciego de Ávila","Cienfuegos","Granma",
                        "Guantánamo","Holguín","Isla de la Juventud","La Habana",
                        "Las Tunas","Matanzas","Mayabeque","Pinar del Río","Sancti Spíritus",
                        "Santiago de Cuba","Villa Clara"]
const municipalities = [
[
    
],
[
    "Artemisa",
    "Alquízar",
    "Bahía Honda",
    "Bauta",
    "Caimito",
    "Candelaria",
    "Guanajay",
    "Güira de Melena",
    "Mariel",
    "San Antonio de los Baños",
    "San Cristóbal"],
[
    "Camagüey",
    "Carlos Manuel de Céspedes",
    "Esmeralda",
    "Florida",
    "Guaimaro",
    "Jimagüayú",
    "Minas",
    "Najasa",
    "Nuevitas",
    "Santa Cruz del Sur",
    "Sibanicú",
    "Sierra de Cubitas",
    "Vertientes"    
],
[
    "Chambas",
    "Ciego de Ávila",
    "Ciro Redondo",
    "Baraguá",
    "Bolivia",
    "Florencia",
    "Majagua",
    "Morón",
    "Primero de Enero",
    "Venezuela"
],
[
    "Abreus",
    "Aguada de Pasajeros",
    "Cienfuegos",
    "Cruces",
    "Cumanayagua",
    "Palmira",
    "Rodas",
    "Santa Isabel de las Lajas"
],
[
    "Bartolomé Masó",
    "Bayamo",
    "Buey Arriba",
    "Campechuela",
    "Cauto Cristo",
    "Guisa",
    "Jiguaní",
    "Manzanillo",
    "Media Luna",
    "Niquero",
    "Pilón",
    "Río Cauto",
    "Yara"
],
[
    "Baracoa",
    "Caimanera",
    "El Salvador",
    "Guantánamo",
    "Imías",
    "Maisí",
    "Manuel Tames",
    "Niceto Pérez",
    "San Antonio del Sur",
    "Yateras"
],
[
    "Antilla",
    "Báguanos",
    "Banes",
    "Cacocum",
    "Calixto García",
    "Cueto",
    "Frank País",
    "Gibara",
    "Holguín",
    "Mayarí",
    "Moa",
    "Rafael Freyre",
    "Sagua de Tánamo",
    "Urbano Noris"
],
[
    "Nueva Gerona",
    "Otros lugares"
],
[
    "Arroyo Naranjo",
    "Boyeros",
    "Centro Habana",
    "Cerro",
    "Cotorro",
    "Diez de Octubre",
    "Guanabacoa",
    "Habana del Este",
    "Habana Vieja",
    "La Lisa",
    "Marianao",
    "Plaza de la Revolución",
    "Playa",
    "Regla",
    "San Miguel del Padrón"
],
[
    "Amancio Rodríguez",
    "Colombia",
    "Jesús Menéndez",
    "Jobabo",
    "Las Tunas",
    "Majibacoa",
    "Manatí",
    "Puerto Padre"
],
[
    "Calimete",
    "Cárdenas",
    "Ciénaga de Zapata",
    "Colón",
    "Jagüey Grande",
    "Jovellanos",
    "Limonar",
    "Los Arabos",
    "Martí",
    "Matanzas",
    "Pedro Betancourt",
    "Perico",
    "Unión de Reyes"
],
[
    "Batabanó",
    "Bejucal",
    "Güines",
    "Jaruco",
    "Madruga",
    "Melena del Sur",
    "Nueva Paz",
    "Quivicán",
    "San José de las Lajas",
    "San Nicolás de Bari",
    "Santa Cruz del Norte"
],
[
    "Consolación del Sur",
    "Guane",
    "La Palma",
    "Los Palacios",
    "Mantua",
    "Minas de Matahambre",
    "San Juan y Martínez",
    "San Luis",
    "Sandino",
    "Pinar del Río",
    "Viñales"
],
[
    "Cabaiguán",
    "Fomento",
    "Jatibonico",
    "La Sierpe",
    "Sancti Spíritus",
    "Taguasco",
    "Trinidad",
    "Yaguajay"
],
[
    "Contramaestre",
    "Guamá",
    "Julio Antonio Mella",
    "Palma Soriano",
    "San Luis",
    "Santiago de Cuba",
    "Segundo Frente",
    "Songo la Maya",
    "Tercer Frente"
],
[
    "Caibarién",
    "Camajuaní",
    "Cifuentes",
    "Corralillo",
    "Encrucijada",
    "Manicaragua",
    "Placetas",
    "Quemado de Güines",
    "Ranchuelo",
    "Remedios",
    "Sagua la Grande",
    "Santa Clara",
    "Santo Domingo"
]]

const colors = {
    primary: importUrl? '#87CEFA' : '#49E38D',
    secondary: importUrl? '#0F1F75' : '#1B5333',
    secondaryDark: importUrl? '#191970' : '#1A5333', //CAMBIAR EL AZUL A MAS OSCURO
    containerGradientFirst : importUrl? '#DDF0FA' : '#EEFFEF',
    containerGradientSecond : importUrl? '#87CEFA' : '#49E38D',
    republishButton: importUrl? '#49E38D' : '#32C5FF',
    orangeEdit: '#FFC50B',
    redHeart: '#FF6B7C',
    redError: 'rgb(251,82,100)',
    redErrorOpacity: 'rgba(251,82,100,0.2)',
    blueShare: '#32C5FF',
    purpleHeart: '#7E57C2',
    greenCart: '#49E38D',
    requestedDarkKhaki: '#BDB76B',
    confirmedDarkCyan: '#008B8B',
    packagingDarkMagenta: '#8B008B',
    sendedDarkSeaGreen: '#8FBC8F',
    recieveDarkGreen: '#006400', 
    red: '#BA3B28', 
    lightGreen: '#9CEEA4', 
    blueLink: '#457DB6',
    officialStore: "#4169E1",
    premium: "#FFD700" 
}


export default url 
export {
    colors, 
    provinces, 
    municipalities, 
    appShareUrl, 
    importUrl, 
    supportNumber, 
    supportEmail,
    phoneAreas, 
    sendingCostValue,
    packaging,
    guideCut,
    discountOverValue,
    discountOverTotalPrice,
    discountOverTotalPriceMessage
}