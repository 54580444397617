import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import no_publish from '../../assets/img/no_publish.svg';
import no_publish_secondary from '../../assets/img/no_publish_secondary.svg';
import { importUrl } from '../../config';


const useStyles = makeStyles(() => ({
    holder: {
        width: '100%',
        marginTop: '80px',
        marginBottom: '80px'
    },
    image: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width:'auto',
        height: '120px',
    },
    mainText: {
        marginTop: '15px',
        textAlign: 'center',
        fontSize: '18px',
        fontWeight: '600'
    },
    secondaryText: {
        marginTop: '10px',
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: '400'
    },
}))

  export default function NoOtherProducts() {
    const classes  = useStyles()
    
    return(
        <div className={classes.holder}>
            <img src={importUrl? no_publish_secondary : no_publish} className={classes.image}/>
            <Typography className={classes.mainText}>
                No tiene otros anuncios publicados :(
            </Typography>
        </div>
    )
}