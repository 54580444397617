import React from "react"
import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import url from "../../config"
import { update_cart_action } from "../../redux/actions/cartAction"
import { update_user_action } from "../../redux/actions/userAction"

export default function SetStartItems(){
    const user = useSelector((state) => state.user_store.user)
    const dispatch = useDispatch()

    useEffect(() => {
        const fetchUser = async () => {
            try{ 
                const isUserLogged = window.localStorage.getItem('user')
                if(!isUserLogged)return
                const token = JSON.parse(isUserLogged).accessToken
                const requestOptions = {
                    method: 'GET'
                }    
                let result = await fetch(`${url}/api/account/${user._id}`, requestOptions)
                console.log(result)
                if(result.status === 200){
                    const user = await result.json()
                    const userToUpdate = {
                        _id: user._id,
                        username: user.username,
                        email: user.email,
                        profilePictureUrl: user.profilePictureUrl,
                        isAdmin: user.isAdmin,
                        isSuperAdmin: user.isSuperAdmin,
                        subscription: user.subscription,
                        accessToken: token,
                    }
                    window.localStorage.setItem(
                        'user', JSON.stringify(userToUpdate)
                    )
                    dispatch(update_user_action(userToUpdate))
                }
            } 
            catch(error){
                console.log(error);
            }
        }
        fetchUser()
    }, [])

    return null
}