import React, {useState, useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import url, { colors } from '../../config';
import ReviewSkeletonCard from './ReviewSkeletonCard';
import ReviewCard from './ReviewCard';
import { Typography } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    cancelButton: {
        padding: '4px',
        display: 'block',
        margin: '10px 0px 10px auto'
    },
    opinionsAbout: {
        marginLeft: '20px',
        marginTop: '10px',
        width: '280px',
        marginBottom: '-10px',
        fontSize: '16px',
        color: colors.secondary,
        fontWeight: '600',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
}))

  const skeleton = [-1,-2,-3,-4,-5]

  export default function ReviewsList({queryId, productQuery, props}) {
    const classes  = useStyles()
    const [loading, setLoading] = useState(true)
    const [loadingMore, setLoadingMore] = useState(false)
    const [data, setData] = useState([])
    const isFetching = useRef(false)
    const reachFinalProduct = useRef(false)
    const [page, setPage] = useState(1)
    const [reset, setReset] = useState(false)

    useEffect(() => {
        const fetchReset = () => {
            if(!isFetching){
                isFetching.current = true
            }
            else{
                reachFinalProduct.current = false
                setData([])
                setPage(1)
                setReset(!reset)
            }
        }
        fetchReset()
    }, [props])

    useEffect(() => {
        const fetchReviews = async () => {
            try{
                const requestOptions = {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json' 
                    }
                }
                let result = await fetch(`${url}/api/reviews/?page=${page}&limit=${5}&${queryId}${props._id}`, requestOptions)
                console.log(result)
                if(result.status === 200){
                    let dataResult = await result.json()
                    if  (dataResult.length < 5){
                        reachFinalProduct.current = true
                    }
                    setData(data.concat(dataResult))
                    setLoading(false)
                    setLoadingMore(false)
                }
                else{
                    //setApiError(true)
                    reachFinalProduct.current = true
                }
            } catch(error){
                console.log(error);
                //setApiError(true)
                setLoading(false)
                setLoadingMore(false)
                reachFinalProduct.current = true
            }
        }
        fetchReviews()
    }, [page, reset])
    
    return(
        <div>
            {loading?
                skeleton.map(skeletonId => (  
                    <div key={skeletonId}>
                        <ReviewSkeletonCard />
                    </div>
                ))
            :
            <React.Fragment>
                <Typography className={classes.opinionsAbout}>
                    {data.length > 0? `Calificaciones sobre ${props.username}` : `${productQuery? 'No tiene calificaciones' : 'No tiene calificaciones'}`}
                </Typography>
                {data.map(element => (  
                    <div key={element._id}>
                        <ReviewCard review={element}/>
                    </div>
                ))}
                {!reachFinalProduct.current &&
                    <div style={{cursor: 'pointer', margin: '10px 20px 0 auto', width:'143px'}} 
                    onClick={() => {
                                setLoadingMore(true)
                                setPage(prevPage => prevPage+1)}
                            }
                    >
                        Cargar más calificaciones
                    </div>
                }
                {loadingMore &&
                    skeleton.map(skeletonId => (  
                        <div key={skeletonId}>
                            <ReviewSkeletonCard />
                        </div>
                    ))
                }
            </React.Fragment>
        }
    </div>
    )
}