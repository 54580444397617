import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import sevende_logo_white from '../../../assets/img/sevendeLogoBlanco.svg'
import google_store from '../../../assets/img/google_store.png'
import apple_store from '../../../assets/img/apple_store.png'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import b2_cells from '../../../assets/promotional_banner/b2_cells.png'
import b2_truck from '../../../assets/promotional_banner/b2_truck_light.png'
import b2_small_truck from '../../../assets/promotional_banner/b2_truck.png'
import b2_mainText from '../../../assets/promotional_banner/b2_mainText.png'
import b2_secondaryText from '../../../assets/promotional_banner/b2_secondaryText.png'
import b2_10off from '../../../assets/promotional_banner/b2_10off.png'
import b3_background from '../../../assets/promotional_banner/b3_background.png'
import b3_cornerLeft from '../../../assets/promotional_banner/b3_corner_left.png'
import b3_cornerRight from '../../../assets/promotional_banner/b3_corner_right.png'
import b3_mainText from '../../../assets/promotional_banner/b3_mainText.png'
import b3_girl from '../../../assets/promotional_banner/b3_girl.png'
import b3_products from '../../../assets/promotional_banner/b3_products.png'
import b1_boxes from '../../../assets/promotional_banner/b1_boxes.png'
import b1_cart from '../../../assets/promotional_banner/b1_cart.png'
import b1_text from '../../../assets/promotional_banner/b1_text.png'
import b1_background from '../../../assets/promotional_banner/b1_backgroundImage.png'
import { colors } from '../../../config';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: 'flex',
        backgroundImage: `linear-gradient(to right, ${colors.containerGradientFirst} 0%, ${colors.containerGradientSecond} 90%, ${colors.containerGradientSecond} 100%)`,
        height: '240px',
        marginTop: '10px',
        marginLeft: '260px',
        marginRight:'20px',
        borderRadius: '3px',
        alignItems: 'center',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
        [theme.breakpoints.down('md')]: {
            height: '175px',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            marginRight: '10px',
            height: '150px',
            backgroundImage: `linear-gradient(to right, ${colors.containerGradientFirst} 0%, ${colors.containerGradientSecond} 30%, ${colors.containerGradientSecond} 100%)`,
        },
        [theme.breakpoints.down('xs')]: {
            height: '125px'
        }
    },
    mainContainerCarouselItem: {
        display: 'flex',
        height: '240px',
        alignItems: 'center',
        borderRadius: '3px',
        [theme.breakpoints.down('sm')]: {
            height: '150px'
        },
        [theme.breakpoints.down('xs')]: {
            height: '165px'
        }
    },
    sevendeMainContainer: {
        marginLeft: '40px',
        display: 'block',
        [theme.breakpoints.down('md')]: {
            marginLeft: 'auto',
            marginRight: 'auto' 
        }
    },
    mainContainerImage: {
        width: '180px',
        height: 'auto',
        margin: '10px 40px 0px 40px',
        opacity: '0.9',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            width: '120px',
            marginLeft: 'auto',
            marginRight: 'auto' 
        }
    },
    mainContainerInfo: {
        display: 'flex',
        position: 'relative',
        alignIntems: 'center',
        height: '240px',
        flex: 1,
        borderRadius: '3px',
        [theme.breakpoints.down('sm')]: {
            height: '150px'
        },
        [theme.breakpoints.down('xs')]: {
            height: '140px',
            marginRight: '0px'
        }
    },
    b2Background: {
        position: 'absolute',
        display: 'flex',
        height: '100%',
        width: '100%',
        borderRadius: '3px',
    },
    mainContainerInfoSevende: {
        marginRight: 'auto',
        [theme.breakpoints.down('md')]: {
            marginLeft: 'auto'
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: '20px',
            marginRight: '20px',
            width: '100%'
        },
    },
    mainContainerMainText: {
        fontSize: '22px',
        fontWeight: '500', 
        textAlign: 'justify',
        color: colors.secondaryDark,
        [theme.breakpoints.down('sm')]: {
            fontSize: '22px'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '20px',
            fontWeight: '600',
        }
    },
    mainContainerSlogan: {
        display: 'block',  
        marginRight: '40px',
        marginLeft: '40px', 
        width: '320px', 
        color: 'white',
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    percentOff: {
        height: '150px', 
        width: 'auto !important', 
        marginRight: 'auto', 
        marginLeft: 'auto', 
        alignSelf: 'center',
        [theme.breakpoints.down('sm')]: {
            height: '130px'
        },
        [theme.breakpoints.down('xs')]: {
            height: '120px'
        },
    },
    b2MainContainer: {
        display: 'flex',
        flexDirection: 'column', 
        marginLeft: '40px', 
        marginRight: 'auto',
        [theme.breakpoints.down('xs')]: {
            marginLeft: '10px' 
        },
    },
    b2MainTextImage: {
        height: '45px',
        width: 'auto !important',  
        marginBottom: '40px',
        marginTop: '25px',
        [theme.breakpoints.down('md')]: {
            height: '35px',
            marginBottom: '50px',
        },
        [theme.breakpoints.down('sm')]: {
            height: '35px',
            marginBottom: '40px',
        },
        [theme.breakpoints.down('xs')]: {
            height: '30px',
            marginTop: '15px',
            marginBottom: '30px',
        },
    },
    b2SecondaryTextImage: {
        height: 'auto',
        width: '100px !important',
        marginLeft: '30px',
        [theme.breakpoints.down('md')]: {
            width: '80px !important',
            marginLeft: '20px'
        },
        [theme.breakpoints.down('xs')]: {
            height: '20px',
            marginLeft: '10px'
        },
    },
    b2SecondaryImage: {
        height: '170px',
        width: 'auto !important',
        marginLeft: 'auto',
        marginRight: 'auto', 
        alignSelf: 'center',
        [theme.breakpoints.down('md')]: {
            height: '150px'
        },
        [theme.breakpoints.down('sm')]: {
            height: '130px'
        },
        [theme.breakpoints.down('xs')]: {
            height: '120px',
            position: 'absolute',
            right: '-50px'
        },
    },
    b2MainImageContainer: {
        height: 'auto',
        display: 'flex',
        alignItems: 'right',
        width: '232px !important',
        marginRight: '25px',
        marginLeft: 'auto', 
        alignSelf: 'end',
        marginBottom: '10px',
        [theme.breakpoints.down('md')]: {
            width: '140px !important',
            marginRight: '35px',
        },
        [theme.breakpoints.down('sm')]: {
            width: '110px !important',
            marginRight: '45px',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
    },
    b2MainImage: {
        height: '40px',
        width: 'auto !important',
        display: 'block',
        marginLeft: 'auto', 
        [theme.breakpoints.down('sm')]: {
            height: '40px'
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
    },
    b2SmallTruck: {
        display: 'none !important',
        height: 'auto',
        width: '50px !important',
        marginTop: '35px',
        marginLeft: '10px',
        [theme.breakpoints.down('xs')]: {
            display: 'inline-block !important'
        },
    },
    b3GirlImage: {
        height: '130px !important',
        width: 'auto !important',
        alignSelf: 'end',
        marginLeft: 'auto',
        [theme.breakpoints.down('sm')]:{
            height: '110px !important',
        },
        [theme.breakpoints.down('xs')]:{
            height: '80px !important',
        }
    },
    b3TextsContainer: {
        marginLeft: '-30px'
    },
    b3MainTextImage: {
        marginTop: '20px',
        height: '100px !important',
        width: 'auto !important',
        [theme.breakpoints.down('md')]:{
            height: '80px !important',
        },
        [theme.breakpoints.down('sm')]:{
            height: '60px !important',
        },
        [theme.breakpoints.down('xs')]:{
            height: '45px !important',
            marginLeft: '-60px',
            marginTop: '10px',
        }
    },
    b3SecondaryText: {
        fontSize: '14px',
        fontWeight: '600',
        marginTop: '-50px',
        textAlign: 'left',
        color: colors.secondary,
        width: '400px',
        [theme.breakpoints.down('md')]:{
            width: '320px',
            marginTop: '-40px',
        },
        [theme.breakpoints.down('sm')]:{
            width: '250px',
            marginTop: '-30px',
        },
        [theme.breakpoints.down('xs')]:{
            width: '190px',
            fontSize: '12px',
            marginTop: '-20px',
            marginLeft: '-10px'
        }
    },
    b3ProductsImage: {
        marginLeft: '-30px',
        height: '130px !important',
        width: 'auto !important',
        alignSelf: 'end', 
        marginRight: '21%',
        [theme.breakpoints.down('md')]:{
            marginRight: '17%',
            height: '110px !important',
        },
        [theme.breakpoints.down('xs')]:{
            marginLeft: '-80px',
            marginBottom: '10px',
            height: '80px !important',
        },
    },
    b320Off: {
        height: '40px !important',
        width: 'auto !important',
        marginTop: '45px',
        [theme.breakpoints.down('md')]:{
            height: '35px !important',
        },
        [theme.breakpoints.down('xs')]:{
            height: '30px !important',
            marginTop: '25px',
        }
    },
    b1Background: {
        height: '100%',
        width: '90% !important',
        opacity: '0.8',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('md')]:{
            width: '90% !important',
        },
        [theme.breakpoints.down('sm')]:{
            width: '90% !important',
        },
        [theme.breakpoints.down('xs')]:{
            width: '100% !important',
        }
    },
    b1Text: {
        height: '160px',
        width: 'auto !important',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('md')]:{
            height: '140px',
        },
        [theme.breakpoints.down('sm')]:{
            height: '120px',
        },
        [theme.breakpoints.down('xs')]:{
            height: '80px',
            alignSelf: 'start',
            marginTop: '20px'
        }
    },
    b1Boxes: {
        height: '160px',
        width: 'auto !important',
        alignSelf: 'center',
        marginLeft: '30px',
        marginRight: 'auto',
        [theme.breakpoints.down('md')]:{
            height: '140px',
            marginLeft: '20px',
        },
        [theme.breakpoints.down('sm')]:{
            height: '110px',
            marginLeft: '20px',
        },
        [theme.breakpoints.down('xs')]:{
            height: '90px',
            marginLeft: '10px',
            alignSelf: 'end',
            marginBottom: '10px'
        }
    },
    b1Cart: {
        height: '160px',
        width: 'auto !important',
        marginLeft: 'auto',
        marginRight: '30px',
        alignSelf: 'center',
        [theme.breakpoints.down('md')]:{
            height: '160px',
            marginRight: '20px',
        },
        [theme.breakpoints.down('sm')]:{
            height: '125px',
            marginRight: '20px',
        },
        [theme.breakpoints.down('xs')]:{
            height: '90px',
            marginRight: '10px',
            alignSelf: 'end',
            marginBottom: '10px'
        }
    },
    b3CornerLeft: {
        height: '100%',
        width: 'auto !important',
        position: 'absolute',
        marginLeft: '5%',
        [theme.breakpoints.down('md')]:{
            marginLeft: '0',
            borderTopLeftRadius: '3px',
            borderBottomLeftRadius: '3px'
        },
    },
    b3CornerRight: {
        height: '100%',
        width: 'auto !important',
        position: 'absolute',
        right: '0',
        marginRight: '5%',
        [theme.breakpoints.down('md')]:{
            marginRight: '0',
        },
    },
    b3BackgroundImage: {
        height: '100%',
        width: '90% !important',
        position: 'absolute',
        marginLeft: '5%',
        marginRight: '5%',
        [theme.breakpoints.down('md')]:{
            width: '100% !important',
            marginLeft: '0',
            marginRight: '0',
            borderRadius: '3px'
        },
    },
    b3Girl: {
        height: '180px',
        width: 'auto !important',
        position: 'absolute',
        alignSelf: 'end',
        marginLeft: 'calc(5% + 75px)',
        [theme.breakpoints.down('md')]:{
            height: '150px',
            marginLeft: 'calc(5% + 35px)',
        },
        [theme.breakpoints.down('sm')]:{
            height: '135px',
        },
        [theme.breakpoints.down('xs')]:{
            height: '100px',
            marginLeft: '10px',
        }
    },
    b3Products: {
        height: '185px',
        width: 'auto !important',
        position: 'absolute',
        alignSelf: 'end',
        right: '0',
        marginRight: 'calc(5% + 25px)',
        marginBottom: '1px',
        [theme.breakpoints.down('md')]:{
            height: '150px',
            marginRight: 'calc(5% + 5px)',
        },
        [theme.breakpoints.down('sm')]:{
            height: '135px',
            marginRight: 'calc(5%)',
        },
        [theme.breakpoints.down('xs')]:{
            height: '90px',
            marginRight: '5px'
        }
    },
    b3MainText: {
        height: '195px',
        width: 'auto !important',
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%, 0)',
        marginTop: '15px',
        [theme.breakpoints.down('md')]:{
            height: '180px',
        },
        [theme.breakpoints.down('sm')]:{
            height: '165px',
            marginTop: '15px',
        },
        [theme.breakpoints.down('xs')]:{
            height: '110px',
            marginTop: '2px',
        }
    },
}))


const Slogan = ({classes}) => {
    return(
        <div className={classes.mainContainerSlogan}>
            <Typography style={{fontSize: '20px', fontWeight: '500'}}>
                COMPRA Y VENDE A LO CUBANO
            </Typography>
            <Typography style={{fontSize: '33px', fontWeight: '500', marginTop: '-10px', marginBottom: '-10px'}}>
                PERO ORGANIZAO :)
            </Typography>
            <img alt="" src={sevende_logo_white} style={{height:'65px', width: 'auto', marginLeft: 'auto', display: 'block'}}/>
        </div>
    )
}

export default function PromotionalBanner(){
    const classes = useStyles()

    const toGooglePlay = () => {
        console.log("ENTRA")
        window.open('https://play.google.com/store/apps/details?id=com.sevende.green&pli=1', '_blank')
    }

    return (
        <div className={classes.mainContainer}>
            {/* <Carousel autoPlay interval={5000} showStatus={false} showArrows={false} showThumbs={false} useKeyboardArrows infiniteLoop>
                <div className={classes.mainContainerCarouselItem} style={{borderRadius: '3px'}}>
                    <div className={classes.mainContainerInfo} style={{backgroundImage: `linear-gradient(to right, #000000 0%, #000000 50%, #000000 100%)`, borderRadius: '3px'}}>
                        <div style={{width: '100%', height: '100%', position: 'absolute', borderRadius: '3px'}}>
                            <img src={b1_background} className={classes.b1Background} style={{borderRadius: '3px'}}/>
                        </div>
                        <div style={{width: '100%', height: '100%', position: 'absolute', display: 'flex', borderRadius: '3px'}}>
                            <img src={b1_boxes} className={classes.b1Boxes}/>
                        </div>
                        <div style={{width: '100%', height: '100%', position: 'absolute', display: 'flex', borderRadius: '3px'}}>
                            <img src={b1_cart} className={classes.b1Cart}/>
                        </div>
                        <div style={{width: '100%', height: '100%', position: 'absolute', display: 'flex', alignItems: 'center', borderRadius: '3px'}}>
                            <img src={b1_text} className={classes.b1Text}/>
                        </div>
                    </div>
                </div>
                <div className={classes.mainContainerCarouselItem}>
                    <div className={classes.b2Background}>
                        <div style={{flex: 1, height: '100%'}}>
                            <div style={{backgroundColor: '#000000', height: '70%', width: '100%', borderTopLeftRadius: '3px'}}/>
                            <div style={{backgroundColor: '#8F8177', height: '30%', width: '100%', borderBottomLeftRadius: '3px'}}/>
                        </div>
                        <div style={{flex: 1, height: '100%'}}>
                            <div style={{backgroundColor: '#DCDCDE', height: '40%', width: '100%', borderTopRightRadius: '3px'}}/>
                            <div style={{backgroundColor: '#0D0B0A', height: '60%', width: '100%', borderBottomRightRadius: '3px'}}/>
                        </div>
                    </div>
                    <div className={classes.mainContainerInfo}>
                        <div className={classes.b2MainContainer}>
                            <img src={b2_mainText} className={classes.b2MainTextImage}/>
                            <img src={b2_secondaryText} className={classes.b2SecondaryTextImage}/>
                            <img src={b2_small_truck} className={classes.b2SmallTruck}/>
                        </div>
                        <img src={b2_cells} className={classes.b2SecondaryImage}/>
                        <div className={classes.b2MainImageContainer}>
                            <img src={b2_truck} className={classes.b2MainImage}/>
                        </div>
                    </div>
                </div>
                <div className={classes.mainContainerCarouselItem}>
                    <div className={classes.b2Background}>
                        <div style={{flex: 1, height: '100%', backgroundColor: '#A2A2A1', borderRadius: '3px'}} />
                        <img src={b3_background} className={classes.b3BackgroundImage} />
                        <img src={b3_cornerLeft} className={classes.b3CornerLeft} />
                        <img src={b3_girl} className={classes.b3Girl} />
                        <img src={b3_cornerRight} className={classes.b3CornerRight} />
                        <img src={b3_products} className={classes.b3Products} />
                        <img src={b3_mainText} className={classes.b3MainText} />
                    </div>
                </div>
                
            </Carousel> */}
            <React.Fragment>
                <div className={classes.mainContainerInfoSevende}>    
                    
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{flex: 1}} onClick={toGooglePlay}>
                            <Typography className={classes.mainContainerMainText} style={{textAlign: 'center'}}>
                                DESCARGANOS
                            </Typography>
                            <img src={google_store} className={classes.mainContainerImage}/>
                        </div>
                        <div style={{flex: 1}}>
                            <Typography className={classes.mainContainerMainText} style={{textAlign: 'center'}}>
                                PRONTO EN
                            </Typography>
                            <div>
                                <img src={apple_store}  className={classes.mainContainerImage}/>
                            </div>
                        </div>
                    </div>
                </div>
                <Slogan classes={classes}/>
            </React.Fragment>
        </div>
    )
}