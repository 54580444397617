import { alpha, makeStyles, withStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import React, {useState, useEffect, useRef} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import category from '../../assets/img/category.svg';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import url, { colors, supportNumber } from '../../config'
import '../../assets/css/layout.css'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {HomePageProductList} from '../pagesComponents/HomePage/HomePageProductList';
import { Badge, Collapse, Fab } from '@material-ui/core';
import LoginRegister from '../global/LoginRegister';
import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';
import { IconButton, Checkbox } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import WarningAmberIcon from '@material-ui/icons/PriorityHigh'
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux'
import { unread_messages_action } from '../../redux/actions/socketAction';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import HelpIcon from '@material-ui/icons/Help'
import InfoIcon from '@material-ui/icons/Info'
import SendIcon from '@material-ui/icons/Send'
import { Skeleton } from '@material-ui/lab';
import { update_search_params_action, update_products_action } from '../../redux/actions/productsAction';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import PromotionalBanner from '../pagesComponents/HomePage/PromotionalBanner';
import { update_login_action } from '../../redux/actions/loginAction';
import Navbar from '../global/navbar'
import SmallTabBarMargin from '../global/SmallTabBarMargin';

const StyledBadge = withStyles((theme) => ({
    badge: {
      right: 0,
      top: 0,
      border: `0px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
      backgroundColor: alpha(colors.redError,0.85)
    },
  }))(Badge);

const themeMUI = createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          color: "white",
          backgroundColor: "rgba(251,82,100,0.85)",
          borderTop: 'solid 2px rgb(251,82,100)'
        }
      }
    }
  });

  const GreenCheckbox = withStyles({
    root: {
      height: '14px',
      width: '14px',
      color: colors.secondary,
      '&$checked': {
        color: colors.secondary,
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));

const useStyles = makeStyles((theme) => ({
    page: {
        background: '#fefbfb',
        width: '100%'
    },
    mainDivResponsive: {
        position: 'relative',
        [theme.breakpoints.up('lg')]: {
            maxWidth: '1536px',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
    },
    logo: {
        cursor: 'pointer',
        height: '32px',
        width: 'auto',
        marginLeft: '46px',
        marginRight: '76px', 
        [theme.breakpoints.down('sm')]: {
            marginRight: 'auto', 
            marginLeft: '10px',
            height: '25px',   
          },
        [theme.breakpoints.down('xs')]: {
            marginLeft: 'auto',
          },
    },
    SearchAndFilter:{
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
          },
    },
    SearchAndFilterSmall:{
        display: 'none',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            display: 'flex'
          },
    },
    search: {
        display: 'flex',
        borderRadius:'3px',
        height: 35, 
        backgroundColor: 'white',
        boxShadow: '4px 4px 2px -2px rgba(0,0,0,.2)',
        '&:hover': {
            boxShadow: '4px 4px 4px 0 rgba(0,0,0,.2)',
        },
        '&:focus': {
            boxShadow: '4px 4px 4px 0 rgba(0,0,0,.2)',
        },
        marginRight: 4,
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('md')]: {
          width: 'auto',
        },
    },
    searchIcon: {
        padding: '0px 8px',
        height: '100%',
        //position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        cursor: 'pointer',
        backgroundColor: colors.secondary,
        borderTopRightRadius: '3px',
        borderBottomRightRadius: '3px',
        '&:hover': {
            backgroundColor: alpha(colors.secondary, 0.85),
          },
        [theme.breakpoints.down('sm')]:{
            padding: '0px 8px',
        }
    },
    inputRoot: {
        color: 'inherit',
        width: '100%'
    },
    inputInput: {
        height: 22,
        //transformOrigin: 'top left',
        //transform: 'scale(0.75)',
        fontSize: '12px',
        fontWeight: 400,
        paddingRight: '12px',
        // vertical padding + font size from searchIcon
        paddingLeft: `18px`,
        transition: theme.transitions.create('width'),
        width: 'calc(100%)',
        '&::placeholder': {
            textAlign: 'center',
            color: colors.secondaryDark,
            opacity: 1, /* Firefox */
            letterSpacing: '6px' 
        },
        /* or, for legacy browsers */
        
        '&::-webkit-input-placeholder': {
            textAlign: 'center'
        },
        '&:-moz-placeholder': { /* Firefox 18- */
            textAlign: 'center'  
        }, 
        '&::-moz-placeholder': {  /* Firefox 19+ */
            textAlign: 'center'  
        },
        '&:-ms-input-placeholder': {  
            textAlign: 'center',
            color: colors.secondaryDark 
        },
        [theme.breakpoints.down('sm')]:{
            paddingLeft: '18px'
        },
        [theme.breakpoints.up('md')]: {
            width: 'calc(50ch)',
        },
    },
    navbarButton: {
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: alpha(theme.palette.common.white, 0.35),
        },
        height: '30px',
        marginRight: 4
    },
    
    sellIconFab: {
        //fontSize: 'inherit',
        color: 'white',
        marginTop: '5px',
        marginBottom: '-5px',
        marginLeft: 'auto',
        marginRight: 'auto',
        //transform: 'rotate(45deg)'
    },
    dropDownIconDrawer: {
        color: colors.secondary,
        zIndex: "10",
        cursor: 'pointer'
    },
    LocationOnIconDrawer: {
        color: colors.secondary
    },
    label: {
        textTransform: 'capitalize',
        fontWeight: 600,
    },
    labelProvinceMainDrawer: {
        fontWeight: 500,
        fontSize: '12px',
        whiteSpace: 'nowrap',
        backgroundColor: 'transparent'
    },
    //Categories Style
    
    
    arrow: {
        fontSize: 20,
        color: "#4A4A4A",
        "&::before": {
          backgroundColor: colors.redError,
        }
    },
    inputHolderDrawer: {
        display: 'flex',
        width: '60px',
        marginRight: '5px',
        backgroundColor:colors.secondary, 
        height: '25px',
        boxShadow: '1px 1px 1px #D4D4D4', 
        borderRadius: '3px'
    },
    currencyHolder: {
        display: 'flex',
        height: '25px',
        backgroundColor:colors.secondary, 
        boxShadow: '1px 1px 1px #D4D4D4', 
        borderRadius: '3px'
    },
    inputRootLogin: {
        width: '100%',
    },
    inputInputLogin: {
        height: 22,
        fontSize: '0.875rem',
        fontWeight: 400,
        // vertical padding + font size from searchIcon
        transition: theme.transitions.create('width'),
        width: '100%',
        paddingLeft: '10px',
        paddingRight: '10px',
        color: 'white',
        '&::placeholder': { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: 'white',
            textAlign: 'center',
            opacity: 1 /* Firefox */
          },
          
          '&:-ms-input-placeholder': { /* Internet Explorer 10-11 */
            color: 'white',
            textAlign: 'center',
          },
          
          '&::-ms-input-placeholder': { /* Microsoft Edge */
            color: 'white',
            textAlign: 'center',
          },
    },
    errorButton: {
        color: colors.redError,
        padding: '3px',
        "&:hover, &.Mui-focusVisible": { 
            backgroundColor: colors.redErrorOpacity,
        }
    },
    
    drawer: {
        width: '240px',
        flexShrink: 0,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
      },
      drawerPaper: {
        position: 'fixed',
        width: '240px',
        overflowY: 'scroll',
        zIndex: '1001',
        height: 'calc(100% - 75px)',
        borderRadius: '5px',
        borderRight: '0px solid',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
      },
      drawerFilter: {
        backgroundColor: colors.containerGradientFirst,
        borderRadius: '5px',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
      },
      drawerInfo: {
        backgroundColor: colors.containerGradientFirst,
        borderRadius: '5px',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
      },
      drawerContainer: {
        overflow: 'auto',
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    selectedProvince: {
        height: '11px',
        width: '11px',
        border: `solid 1px ${colors.secondary}`,
        backgroundColor: colors.secondary,
        marginLeft: '1px'
    },
    unselectedProvince: {
        height: '13px',
        width: '13px',
        border: `solid 1px ${colors.secondary}`
    },
    filterDivider: {
        backgroundColor: colors.secondary, 
        height: '360px', 
        width: '1px', 
        marginLeft: '25px', 
        marginRight: '25px', 
        marginTop: '40px',
        marginBottom: '0px',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
          },
    },
    categoryActive: {
        backgroundColor: `${colors.secondary} !important`,
        color: 'white'
    },
    menuIconUserOptions: {
        width: '20px', 
        height: '20px',
        marginRight: '5px'
    },
    listText: {
        fontWeight: '800',
        fontSize: '14px',
        marginLeft: '20px',
        marginRight: '20px',
        [theme.breakpoints.up('md')]:{
            paddingLeft: '240px',
        },
        [theme.breakpoints.down('md')]:{
            marginLeft: '10px',
            marginRight: '10px',
        },
    }
}))

function HideOnScroll(props) {
    const { children } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger();
    let screenWidth = window.innerWidth
  
    return (
      <Collapse in={screenWidth > 600? true : !trigger}>
        {children}
      </Collapse>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
  };

  
  // One item component
  // selected prop will be passed
  
  // All items component
  // Important! add unique key
  
  
const skeleton = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18]
const provinces = [
    'Todas las Provincias',
    'Artemisa',
    'Camagüey',
    'Ciego de Ávila',
    'Cienfuegos',
    'Granma',
    'Guantánamo',
    'Holguín',
    'Isla de la Juventud',
    'La Habana',
    'Las Tunas',
    'Matanzas',
    'Mayabeque',
    'Pinar del Río',
    'Sancti Spíritus',
    'Santiago de Cuba',
    'Villa Clara'
]
const ITEM_HEIGHT = 48;

const currencies = ["TODAS","MN", "USD", "MLC"]

const HomePage = ({props, children, socket_store, registerPromotion}) => {
    const classes  = useStyles()
    const location = useLocation()
    const locationUseRef = useRef()
    locationUseRef.current = location
    const history = useHistory()
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const [data, setData] = useState([])
    const [searchField, setSearchField] = useState("")
    const [minPriceError, setMinPriceError] = useState("")
    const [minPriceErrorOpen, setMinPriceErrorOpen] = useState(false)
    const [maxPriceError, setMaxPriceError] = useState("")
    const [maxPriceErrorOpen, setMaxPriceErrorOpen] = useState(false)
    const [categoryDrawer, setCategoryDrawer] = useState({left: false, right: false})

    const [paramsLoading, setParamsLoading] = useState(true)

    const [anchorElCategories, setAnchorElCategories] = useState(null);
    const openCategories = Boolean(anchorElCategories);
    const [anchorElDrawer, setAnchorElDrawer] = useState(null);
    const openDrawer = Boolean(anchorElDrawer);

    const [anchorElUserOptions, setAnchorElUserOptions] = useState(null);
    const openUserOptions = Boolean(anchorElUserOptions);

    const [anchorElFilter, setAnchorElFilter] = useState(null);
    const openFilter = Boolean(anchorElFilter);

    const [openSuccesSnack, setOpenSuccesSnack] = useState(false)
    const [openApiErrorSnack, setOpenApiErrorSnack] = useState(false)
    const [openRegisterSuccesSnack, setOpenRegisterSuccesSnack] = useState(false)
    const [openRegisterApiErrorSnack, setOpenRegisterApiErrorSnack] = useState(false)

    const [anchorElCurrency, setAnchorElCurrency] = useState(null);
    const openCurrency = Boolean(anchorElCurrency);
    const [newFilterChecked, setNewFilterChecked] = useState(true)
    const [asNewFilterChecked, setAsNewFilterChecked] = useState(true)
    const [smallUseFilterChecked, setSmallUseFilterChecked] = useState(true)
    const [highUseFilterChecked, setHighUseFilterChecked] = useState(true)
    const [showMainContainer, setShowMainContainer] = useState(true)
    const [categoriesLoading, setCategoriesLoading] = useState(true)

    const socket = useRef()
    const searchParamsToExecute = useSelector((state) => state.products_store.searchParameters)
    const searchParamsToExecuteUseRef = useRef()
    searchParamsToExecuteUseRef.current = searchParamsToExecute
    const socket_store_selector = useSelector((state) => state.socket_store.newMessages)
    const cart_store_selector = useSelector((state) => state.cart_store.count)
    const userLogged = useSelector((state) => state.user_store.user)
    const dispatch = useDispatch()

    useEffect(() => {
        const fetchCategories = async () => {
            try{
                let result = await fetch(`${url}/api/categories`)
                let dataResult = await result.json()
                let dataResultWithAll = [{_id: "0", name: "Todos", iconUrl: category}]
                setData(dataResultWithAll.concat(dataResult))
            } catch(error){
                console.log({error})
                setShowMainContainer(false)
            }
            setCategoriesLoading(false)
        }
        fetchCategories() 
    }, [])

    useEffect(() => {
        const fetchUser = async () => {
            try{
                if(userLogged)
                { 
                    if(false){

                    }
                    else{
                        const messageEventHandler = () => {
                            dispatch(unread_messages_action())
                        }
                        if(socket_store.socket){
                            socket.current = socket_store.socket
                            
                            socket.current.on("getMessage", messageEventHandler)
                            
                        }
                        const requestOptions = {
                            method: 'GET',
                            headers: { 'Content-Type': 'application/json',
                                        'Authorization': `${userLogged.accessToken}`}
                            };
                        let result = await fetch(`${url}/api/conversations/unreadMessages`, requestOptions);
                        if(result.status === 200){
                            let newData = await result.json();
                            if(newData.unreadMessages){
                            dispatch(unread_messages_action())
                            }
                        }
                        else{
                            //setApiError(true)
                        }
                        return () => {
                            
                            socket.current.off("getMessage", messageEventHandler)
                            
                        };
                    }
                }
                else{
                }
            } catch(error){
            }
        }
        fetchUser() 
    }, [userLogged])

    useEffect(() => {
        manageParams()
    }, [searchParamsToExecute])
    
    // #endregion

    const manageParams = () => {
        const queryParams = {}
        let hasParameters = false
        if (searchField) {
            queryParams["searchPhrase"] = searchField
            hasParameters = true
        }
        if(searchParamsToExecute.minPrice){
            if(parseInt(searchParamsToExecute.minPrice)){
                queryParams["minPrice"] = searchParamsToExecute.minPrice
                hasParameters = true
            }
        }
        if(searchParamsToExecute.maxPrice){
            if(parseInt(searchParamsToExecute.maxPrice)){
                queryParams["maxPrice"] = searchParamsToExecute.maxPrice
                hasParameters = true
            }
        }
        let newStatusToSearch = []
        if((newFilterChecked && asNewFilterChecked && smallUseFilterChecked && highUseFilterChecked)){

        }
        else{
            if(newFilterChecked)newStatusToSearch.push("Nuevo")
            if(asNewFilterChecked)newStatusToSearch.push("Como nuevo")
            if(smallUseFilterChecked)newStatusToSearch.push("Poco uso")
            if(highUseFilterChecked)newStatusToSearch.push("Usado")
        }
        if(newStatusToSearch.length > 0){
            if(newStatusToSearch.length === 1) queryParams["status"] = newStatusToSearch[0]
            if(newStatusToSearch.length === 2) queryParams["status"] = newStatusToSearch[0]+","+newStatusToSearch[1]
            if(newStatusToSearch.length === 3) queryParams["status"] = newStatusToSearch[0]+","+newStatusToSearch[1]+","+newStatusToSearch[2]
            hasParameters = true
        }
        if(searchParamsToExecute.selectedCurrency !== "TODAS"){
            queryParams["currency"] = searchParamsToExecute.selectedCurrency
            hasParameters = true
        }
        if(!(searchParamsToExecute.selected === "0")){
            queryParams["categoryId"] = searchParamsToExecute.selected
            hasParameters = true
        }
        if(searchParamsToExecute.selectedProvince !== "Todas las Provincias"){
            queryParams["region"] = searchParamsToExecute.selectedProvince
            hasParameters = true
        }
        if(hasParameters){
            setShowMainContainer(false)
        }
        else{
            setShowMainContainer(true)
        }
    }

    // #region Snack Events
    const handleCloseSuccesSnack = () => {
        setOpenSuccesSnack(false)
        if(registerPromotion){
            history.push("/")
        }
        else{
            window.location.reload(false);
        }
    }
    const handleCloseApiErrorSnack = () => {
        setOpenApiErrorSnack(false)
    }
    const handleCloseRegisterSuccesSnack = () => {
        setOpenRegisterSuccesSnack(false)
    }
    const handleCloseRegisterApiErrorSnack = () => {
        setOpenRegisterApiErrorSnack(false)
    }
    const OpenSuccessSnack = () => {
        setOpenRegisterSuccesSnack(false)
        setOpenSuccesSnack(true)
    }
    const OpenApiErrorSnack = () => {
        setOpenApiErrorSnack(true)
    }
    const OpenRegisterSuccessSnack = () => {
        setOpenRegisterSuccesSnack(true)
    }
    const OpenRegisterApiErrorSnack = () => {
        setOpenRegisterApiErrorSnack(true)
    }
    // #endregion

    // #region LOGIN METHODS
    
    const handleClickOpen = () => {
        dispatch(update_login_action(true));
    };
    // #endregion

    // #region ONCLICK
    const toLegalInformationPage = (event) => {
        history.push("/legalinformation")
    }
    const toHelpPage = (event) => {
        history.push("/help")
    }

    const toCreateProductPage = (event) => {
        if(!userLogged){
            dispatch(update_login_action(true))
            return
        }
        history.push("/myproducts/create")
    }
    const toHelpWhatsap = () => {
        window.open(`https://wa.me/${supportNumber}?text=${encodeURI("Hola, tengo una pregunta.")}`, '_blank')
    }

    const handleClickDrawer = (event) => {
        setAnchorElDrawer(event.currentTarget);
    };


    const categoryOnClick = (Id) => {
        dispatch(update_products_action([], 0, false))
        let params = {...searchParamsToExecute}
        params.page = 1
        params.selected = Id
        params.onBackPressed = false
        dispatch(update_search_params_action(params))
    }
    // #endregion
    
    // #region HandleChange
    
    const handleChangeMinPrice = (event) => {
        if(minPriceError){
            setMinPriceError("")
            handleMinPriceErrorTooltipClose()
        }
        dispatch(update_products_action([], 0, false))
        let params = {...searchParamsToExecute}
        params.page = 1
        params.minPrice = event.target.value
        params.onBackPressed = false
        dispatch(update_search_params_action(params))
        if(event.target.value){
            if(!parseInt(event.target.value)){
                setMinPriceError('El Precio debe ser un numero')
                handleMinPriceErrorTooltipOpen()
            }
        }
    };
    const handleChangeMaxPrice = (event) => {
        if(maxPriceError){
            setMaxPriceError("")
            handleMaxPriceErrorTooltipClose()
        }
        dispatch(update_products_action([], 0, false))
        let params = {...searchParamsToExecute}
        params.page = 1
        params.maxPrice = event.target.value
        params.onBackPressed = false
        dispatch(update_search_params_action(params))
        if(event.target.value){
            if(!parseInt(event.target.value)){
                setMaxPriceError('El Precio debe ser un numero')
                handleMaxPriceErrorTooltipOpen()
            }
        }
    };
    // #endregion

    // #region OTHERS
    const handleCloseCategories = (Id) => {
        setAnchorElCategories(null);
        if (Id !== null){
            dispatch(update_products_action([], 0, false))
            let params = {...searchParamsToExecute}
            params.page = 1
            params.selected = Id
            params.onBackPressed = false
            dispatch(update_search_params_action(params))
        }
    };
    const handleCloseDrawer = (name) => {
        setAnchorElDrawer(null);
        if (name !== null){
            dispatch(update_products_action([], 0, false))
            let params = {...searchParamsToExecute}
            params.page = 1
            params.selectedProvince = name
            params.onBackPressed = false
            dispatch(update_search_params_action(params))  
        }
    };
    const handleCloseUserOptions = () => {
        setAnchorElUserOptions(null);
    };
    const handleCloseFilter = () => {
        handleMinPriceErrorTooltipClose()
        handleMinPriceErrorTooltipClose()
        setAnchorElFilter(null);
    };

    const handleMinPriceErrorTooltipClose = () => {
        setMinPriceErrorOpen(false);
    };
    const handleMaxPriceErrorTooltipClose = () => {
        setMaxPriceErrorOpen(false);
    };
    const handleMinPriceErrorTooltipOpen = () => {
        setMinPriceErrorOpen(true);
    };
    const handleMaxPriceErrorTooltipOpen = () => {
        setMaxPriceErrorOpen(true);
    };

    const handleCurrencyClick = (event) => {
        setAnchorElCurrency(event.currentTarget);
      };
    const handleCurrencyClose = (currency) => {
        setAnchorElCurrency(null);
        if (currency !== null){
            dispatch(update_products_action([], 0, false))
            let params = {...searchParamsToExecute}
            params.page = 1
            params.selectedCurrency = currency
            params.onBackPressed = false
            dispatch(update_search_params_action(params))   
        }
    };

    const handleNewFilterChange = (event) => {
        let newStatusToSearch = []
        if((event.target.checked && asNewFilterChecked && smallUseFilterChecked && highUseFilterChecked)){

        }
        else{
            if(event.target.checked)newStatusToSearch.push("Nuevo")
            if(asNewFilterChecked)newStatusToSearch.push("Como nuevo")
            if(smallUseFilterChecked)newStatusToSearch.push("Poco uso")
            if(highUseFilterChecked)newStatusToSearch.push("Usado")
        }
        dispatch(update_products_action([], 0, false))
        let params = {...searchParamsToExecute}
        params.page = 1
        params.statusToSearch = newStatusToSearch
        params.onBackPressed = false
        dispatch(update_search_params_action(params))
        setNewFilterChecked(event.target.checked)
      }

      const handleAsNewFilterChange = (event) => {
        let newStatusToSearch = []
        if((newFilterChecked && event.target.checked && smallUseFilterChecked && highUseFilterChecked)){

        }
        else{
            if(newFilterChecked)newStatusToSearch.push("Nuevo")
            if(event.target.checked)newStatusToSearch.push("Como nuevo")
            if(smallUseFilterChecked)newStatusToSearch.push("Poco uso")
            if(highUseFilterChecked)newStatusToSearch.push("Usado")
        }
        dispatch(update_products_action([], 0, false))
        let params = {...searchParamsToExecute}
        params.page = 1
        params.statusToSearch = newStatusToSearch
        params.onBackPressed = false
        dispatch(update_search_params_action(params))
        setAsNewFilterChecked(event.target.checked)
      }

      const handleSmallUseFilterChange = (event) => {
        let newStatusToSearch = []
        if((newFilterChecked && asNewFilterChecked && event.target.checked && highUseFilterChecked)){

        }
        else{
            if(newFilterChecked)newStatusToSearch.push("Nuevo")
            if(asNewFilterChecked)newStatusToSearch.push("Como nuevo")
            if(event.target.checked)newStatusToSearch.push("Poco uso")
            if(highUseFilterChecked)newStatusToSearch.push("Usado")
        }
        dispatch(update_products_action([], 0, false))
        let params = {...searchParamsToExecute}
        params.page = 1
        params.statusToSearch = newStatusToSearch
        params.onBackPressed = false
        dispatch(update_search_params_action(params))
        setSmallUseFilterChecked(event.target.checked)
      }

      const handleHighUseFilterChange = (event) => {
        let newStatusToSearch = []
        if((newFilterChecked && asNewFilterChecked && smallUseFilterChecked && event.target.checked)){

        }
        else{
            if(newFilterChecked)newStatusToSearch.push("Nuevo")
            if(asNewFilterChecked)newStatusToSearch.push("Como nuevo")
            if(smallUseFilterChecked)newStatusToSearch.push("Poco uso")
            if(event.target.checked)newStatusToSearch.push("Usado")
        }
        dispatch(update_products_action([], 0, false))
        let params = {...searchParamsToExecute}
        params.page = 1
        params.statusToSearch = newStatusToSearch
        params.onBackPressed = false
        dispatch(update_search_params_action(params))
        setHighUseFilterChecked(event.target.checked)
      }
    // #endregion

    // #region Drawer
    const toggleDrawer = (open, side) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setCategoryDrawer({...categoryDrawer, [side]: open});
      };

      const categoryMainDrawerList = () => (
        <div className={classes.categoryMainDrawerStyle}
          role="presentation"
          onKeyDown={toggleDrawer(false, 'left')}
          onClick={toggleDrawer(false, 'left')}
        >
          <List style={{paddingTop: "0px"}}>
            {categoriesLoading === true?
                [skeleton.map((skl) => {
                    return(
                    <ListItem style={{paddingTop: '2px', paddingBottom: '2px'}} button alignItems="center" key={skl}>
                        <Skeleton style={{height: '20px', width: '150px'}} />
                    </ListItem>
                    )      
                })]
            :
                [data.map((category) => (
                <ListItem style={{paddingTop: '2px', paddingBottom: '2px'}} button alignItems="center" selected={searchParamsToExecute.selected === category._id} key={category._id} onClick={() => {categoryOnClick(category._id)}} className={(category._id === searchParamsToExecute.selected)? classes.categoryActive : ""}>
                    <ListItemIcon style={{minWidth: '30px'}}><img alt="" src={category.iconUrl} style={{width:"16px", height:"16px"}}/></ListItemIcon>
                    <Typography style={{color: searchParamsToExecute.selected === category._id? 'white' : colors.secondary, fontSize: '11px', fontWeight: '600'}}>
                        {category.name.toUpperCase()}
                    </Typography>
                </ListItem>
                ))]
            }
          </List>
        </div>
      );
    // #endregion

    return (
        <div>
            {/*app bar*/}
            <CssBaseline />
            
            <Navbar isHomePage={true} />
            
            <div className={classes.mainDivResponsive}>
                <div
                    className={classes.drawerPaper}
                >
                <div className={classes.drawerFilter}>
                    
                    <React.Fragment>
                    <Button
                        variant="contained"
                        aria-label="more"
                        aria-controls="long-menu-drawer"
                        aria-haspopup="true"
                        onClick={handleClickDrawer}
                        style={{display: 'flex', boxShadow: '0px 0px 0px #D4D4D4', color: colors.secondary, margin: '0px auto', padding: '10px 0', backgroundColor: 'transparent'}}
                        //className={`${classes.navbarButton} ${classes.noRadiusButton}`}
                        classes={{
                            label: classes.labelProvinceMainDrawer, // class name, e.g. `classes-nesting-label-x`
                        }}
                        startIcon={<LocationOnIcon className={classes.LocationOnIconDrawer}/>}
                        endIcon={<ArrowDropDownIcon className={classes.dropDownIconDrawer} />}
                    >{searchParamsToExecute.selectedProvince}</Button>
                    <Menu
                        id="long-menu-drawer"
                        anchorEl={anchorElDrawer}
                        keepMounted
                        open={openDrawer}
                        onClose={() => handleCloseDrawer(null)}
                        PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: "27ch"
                        },
                        }}
                    >
                        {provinces.map((province) => (
                            <MenuItem key={province} selected={province === searchParamsToExecute.selectedProvince} onClick={() => handleCloseDrawer(province)}>
                                {province}
                            </MenuItem>
                        ))}
                    </Menu>
                    </React.Fragment>
                    
                    
                    {categoryMainDrawerList()}
                    <div style={{display:'flex', marginLeft: '15px', marginBottom: '10px', paddingBottom: '10px'}}>
                        <div>
                            <Typography style={{fontSize: '12px', color: colors.secondary}}>MIN</Typography>
                            <div className={classes.inputHolderDrawer}>
                                <InputBase
                                    elevation={4}
                                    onClick={() => 
                                        {
                                            if(minPriceError) handleMinPriceErrorTooltipOpen()
                                            if(maxPriceError) handleMaxPriceErrorTooltipClose()
                                        }}
                                    type="text"
                                    value={searchParamsToExecute.minPrice}
                                    placeholder="-"
                                    onChange={handleChangeMinPrice}
                                    classes={{
                                        root: classes.inputRootLogin,
                                        input: classes.inputInputLogin,
                                    }}
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                                {minPriceError &&
                                    <div>
                                    <MuiThemeProvider theme={themeMUI}>
                                        <Tooltip classes={{arrow: classes.arrow}}
                                            arrow
                                            PopperProps={{
                                            disablePortal: true,
                                            }}
                                            onClose={handleMinPriceErrorTooltipClose}
                                            open={minPriceErrorOpen}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            title={minPriceError}
                                        >
                                            <IconButton onClick={() => {
                                                            if(maxPriceErrorOpen){
                                                                handleMaxPriceErrorTooltipClose()
                                                            }
                                                            handleMinPriceErrorTooltipOpen()
                                                        }} 
                                                        aria-label="warning" className={classes.errorButton}>
                                                <WarningAmberIcon fontSize="small" />
                                            </IconButton>
                                            
                                        </Tooltip>
                                    </MuiThemeProvider>
                                    </div>                       
                                }
                            </div>
                        </div>
                        <div>
                            <Typography style={{fontSize: '12px', color: colors.secondary}}>MAX</Typography>
                            <div className={classes.inputHolderDrawer}>
                                <InputBase
                                    elevation={4}
                                    onClick={() => 
                                        {
                                            if(maxPriceError) handleMaxPriceErrorTooltipOpen()
                                            if(minPriceError) handleMinPriceErrorTooltipClose()
                                        }}
                                    type="text"
                                    value={searchParamsToExecute.maxPrice}
                                    onChange={handleChangeMaxPrice}
                                    placeholder="-"
                                    classes={{
                                        root: classes.inputRootLogin,
                                        input: classes.inputInputLogin,
                                    }}
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                                {maxPriceError &&
                                    <div>
                                    <MuiThemeProvider theme={themeMUI}>
                                        <Tooltip classes={{arrow: classes.arrow}}
                                            arrow
                                            PopperProps={{
                                            disablePortal: true,
                                            }}
                                            onClose={handleMaxPriceErrorTooltipClose}
                                            open={maxPriceErrorOpen}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            title={minPriceError}
                                        >
                                            <IconButton onClick={() => {
                                                            if(minPriceErrorOpen){
                                                                handleMinPriceErrorTooltipClose()
                                                            }
                                                            handleMaxPriceErrorTooltipOpen()
                                                        }}  aria-label="warning" className={classes.errorButton}>
                                                <WarningAmberIcon fontSize="small" />
                                            </IconButton>
                                            
                                        </Tooltip>
                                    </MuiThemeProvider>
                                    </div>                       
                                }
                            </div>
                        </div>
                        <div>
                            <Typography style={{fontSize: '12px', color: colors.secondary}}>MONEDA</Typography>
                            <div className={classes.currencyHolder} onClick={handleCurrencyClick} style={{display: 'flex', alignItems: 'center', cursor: 'pointer', marginRight: '20px'}}>
                                <Typography style={{fontSize:'12px', marginLeft:'10px', color: 'white'}}>{searchParamsToExecute.selectedCurrency}</Typography>
                                <ArrowDropDownIcon style={{color: 'white', height: '20px', width:'20px', marginLeft:'-2px'}}/>
                            </div>
                            <Menu
                                id="long-menu"
                                anchorEl={anchorElCurrency}
                                keepMounted
                                open={openCurrency}
                                onClose={() => handleCurrencyClose(null)}
                                PaperProps={{
                                style: {
                                },
                                }}
                            >
                                {currencies.map((currency) => (
                                    <MenuItem key={currency} selected={currency === searchParamsToExecute.selectedCurrency} onClick={() => handleCurrencyClose(currency)}>
                                        {currency}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </div>
                    </div>
                    
                    <div style={{marginLeft: '15px', marginBottom: '10px', paddingBottom: '10px'}} className={classes.stateFilterHolder}>
                        <div className={classes.stateFilterFirst}>
                            <div style={{display:'flex', alignItems: 'center', marginBottom: '2px', marginTop: '12px'}}>
                                <Typography style={{color: colors.secondary, paddingTop:'1px', width: '85px', fontSize: '12px'}}>NUEVO</Typography>
                                <GreenCheckbox
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    checked={newFilterChecked}
                                    onChange={handleNewFilterChange}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            </div>
                            <div style={{display:'flex', alignItems: 'center', marginBottom: '2px'}}>
                                <Typography style={{color: colors.secondary, paddingTop:'1px', width: '85px', fontSize: '12px'}}>COMO NUEVO</Typography>
                                <GreenCheckbox
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    checked={asNewFilterChecked}
                                    onChange={handleAsNewFilterChange}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            </div>
                        </div>
                        <div className={classes.stateFilterSecond}>
                            <div style={{display:'flex', alignItems: 'center', marginBottom: '2px'}}>
                                <Typography style={{color: colors.secondary, paddingTop:'1px', width: '85px', fontSize: '12px'}}>POCO USO</Typography>
                                <GreenCheckbox
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    checked={smallUseFilterChecked}
                                    onChange={handleSmallUseFilterChange}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            </div>
                            <div style={{display:'flex', alignItems: 'center', marginBottom: '2px'}}>
                                <Typography style={{color: colors.secondary, paddingTop:'1px', width: '85px', fontSize: '12px'}}>USADO</Typography>
                                <GreenCheckbox
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    checked={highUseFilterChecked}
                                    onChange={handleHighUseFilterChange}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{marginTop: '10px', paddingTop: '15px', paddingLeft: '15px', marginBottom: '10px'}} className={classes.drawerInfo}>
                    <div style={{display: 'flex'}}>
                        <div>
                            <div onClick={toLegalInformationPage} style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                                <InfoIcon style={{height: '15px', width: '15px', color: colors.secondary}}/>
                                <Typography style={{fontSize: '12px', marginLeft: '5px', color: colors.secondary}}>
                                    INFORMACIÓN LEGAL
                                </Typography>
                            </div>
                            <a target="_blank" href={`https://wa.me/${supportNumber}?text=${encodeURI("Hola, tengo una pregunta.")}`} style={{display: 'flex', alignItems: 'center', marginTop: '3px', cursor: 'pointer', textDecoration: 'none'}}>
                                <SendIcon style={{height: '15px', width: '15px', color: colors.secondary}}/>
                                <Typography style={{fontSize: '12px', marginLeft: '5px', color: colors.secondary}}>
                                    CONTACTAR Y AYUDA
                                </Typography>
                            </a>
                            <div onClick={toHelpPage} style={{display: 'flex', alignItems: 'center', marginTop: '3px', cursor: 'pointer'}}>
                                <HelpIcon style={{height: '15px', width: '15px', color: colors.secondary}}/>
                                <Typography style={{fontSize: '12px', marginLeft: '5px', color: colors.secondary}}>
                                    PREGUNTAS FRECUENTES
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <Typography style={{fontSize: '9px', fontWeight: '400', paddingLeft: '20px', marginTop: '20px', marginBottom: '5px'}}>
                        COPYRIGHT {currentYear} | SEVENDEAPP.COM
                    </Typography>
                </div>
                </div>
                <LoginRegister 
                    setOpenSuccesSnack = {OpenSuccessSnack}
                    setCloseSuccesSnack = {handleCloseSuccesSnack}
                    setOpenApiErrorSnack = {OpenApiErrorSnack}
                    setOpenRegisterSuccesSnack = {OpenRegisterSuccessSnack}
                    setOpenRegisterApiErrorSnack = {OpenRegisterApiErrorSnack}
                    registerPromotion={registerPromotion} />
                <div className={classes.page}>
                    {showMainContainer &&
                        <PromotionalBanner />
                    }
                    {/* {showMainContainer && importUrl === 1 &&
                        <React.Fragment>
                            <Typography style={{marginTop: '3px'}} className={classes.listText}>Colaboraciones</Typography>
                            <ColaborationBanner />
                        </React.Fragment>
                    }
                    {showMainContainer && importUrl === 1 &&
                        <React.Fragment>
                            <Typography className={classes.listText}>Productos más vendidos</Typography>
                            <TopSellBanner />
                        </React.Fragment>
                    } */}
                    
                    <div style={{height:"15px"}}></div>
                    <HomePageProductList />
                </div>
            </div>
            <SmallTabBarMargin />
        </div>
    )
}

export default HomePage