import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Card } from '@material-ui/core'
import { CircularProgress } from '@material-ui/core';
import userPlaceholder from '../../assets/img/userPlaceholder.png'
import { Rating } from '@material-ui/lab';
import { useSelector } from 'react-redux';


const useStyles = makeStyles((theme) => ({
    reviewCard: {
        margin: '20px 20px 0 20px', 
        borderRadius:'10px', 
        width: '280px',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            margin: '10px 5% 0 5%'
          },
    },
    media: {
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        marginLeft: '10px'
    },
    mediaPlaceholder: {
        width: '50px',
        height: '50px',
        borderRadius: '50%'
    },
    username: { 
        fontSize: '12px',
        fontWeight: '600',
        marginTop:'4px', 
        marginLeft:'10px'
    },
    rating: {
        marginLeft: '10px',
        marginTop: '5px'
    },
    reviewDate: {
        fontSize: '11px',
        marginTop: '6px',
        marginLeft: '4px'
    },
    productName: {
        marginLeft: '10px',
        marginTop: '3px',
        fontSize: '12px',
        fontWeight:'600'
    },
    reviewDescription: {
        fontSize: '11px', 
        marginLeft: '10px',
        marginRight: '10px',
        marginBottom: '10px'
    },
}));

export default function ReviewCard({review}) {
    const classes = useStyles()
    const history = useHistory()
    const [imageOfUser, setImageOfUser] = useState(userPlaceholder)
    const [reviewDate, setReviewDate] = useState("")
    const [imageLoaded, setImageLoaded] = useState(false)

    const userLogged = useSelector((state) => state.user_store.user)

    useEffect(() => {
        const fetchReviews = () => {
            if(review.owner){
                if(review.owner.profilePictureUrl){
                    setImageOfUser(review.owner.profilePictureUrl)
                }
            }
            const newDate = new Date(review.uploadDate)
            setReviewDate(`${newDate.getDate()}/${newDate.getMonth()+1}/${newDate.getFullYear()}`)
        }
        fetchReviews()
    }, [])

    const mediaDisplay = () => {
        setImageLoaded(true)
    }

    return (
        <Card className={classes.reviewCard}>
            <div onClick={() => {
                if(userLogged){
                    const userId = userLogged._id
                    if(userId === review.owner._id){
                        history.push(`/account`)
                        return
                    }
                }
                history.push(`/users/${review.owner._id}`)    
            }} 
            style={{display:'flex', marginTop:'10px', cursor: 'pointer'}}>    
                <LazyLoadImage
                    className={classes.media}
                    visibleByDefault={true}
                    afterLoad = {mediaDisplay}
                    src={imageOfUser} />
                {!imageLoaded && <CircularProgress size={36} style={{position:'absolute', left:'28px', top:'28px'}}/>} 
                <Typography className={classes.username}>
                    {review.owner? review.owner.username : review.ownerUsername}
                </Typography>
            </div>
            <div style={{display:'flex'}}>    
            <Rating
                className={classes.rating}
                name="hover-feedback"
                value={review.rate}
                precision={1}
                readOnly
                size="small"
            />
                <Typography className={classes.reviewDate}>
                    {reviewDate}
                </Typography>
            </div>
            <div style={{display:'flex'}}> 
                <Typography className={classes.productName}>
                    {review.product? review.product.name: review.productName}
                </Typography>
            </div>
            <div style={{display:'flex'}}> 
                <Typography className={classes.reviewDescription}>
                    {review.description}
                </Typography>
            </div>
        </Card>
    );
}