import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CancelIcon from '@material-ui/icons/Cancel';
import { colors } from '../../config';
import IconButton from '@material-ui/core/IconButton';
import ReviewsList from './ReviewsList';


const useStyles = makeStyles((theme) => ({
    cancelButton: {
        padding: '4px',
        display: 'block',
        margin: '10px 20px 0px auto'
    },
    rateButton: {
      color: colors.primary,
      marginTop: '10px',
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: '16px',
      fontWeight: '400',
      animation: '$enter  350ms ease-in',
      borderColor: colors.primary,
      cursor: 'pointer',
      padding: '0px 15px 0px 15px',
      borderRadius: '7px',
      boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);', 
      '&:focus': {
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
      },
      "&:hover": { 
          backgroundColor: 'rgba(50,164,137,0.1)',
          boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
      },
  },
}))

// #endregion

  // #endregion
  const skeleton = [-1,-2,-3,-4,-5,-6,-7,-8,-9,-10]

  export default function ReviewsModal({openReview, user, handleReviewCloseParent}) {
    const classes  = useStyles()
    const descriptionElementRef = React.useRef(null);

    React.useEffect(() => {
        if (openReview) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
          }
        }
    }, [openReview]);


    const handleFollowClose = () => {
        handleReviewCloseParent(false);
    };
    
    return(
        <div>
            <Dialog
                PaperProps={{
                    style: { borderRadius: '15px', backgroundColor: '#f5f5f5', width: '100%' }
                }}
                open={openReview}
                onClose={handleFollowClose}
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <div>
                    <div style={{display:'flex'}}>
                        <IconButton size="large" onClick={() => {handleReviewCloseParent(false)}} aria-label="warning" className={classes.cancelButton}>
                            <CancelIcon />
                        </IconButton> 
                    </div>
                    <div style={{display:'flex', marginLeft: '25px', marginRight: '25px'}}>
                        <Button variant='outlined' size="large" onClick={() => {handleReviewCloseParent(true)}} aria-label="warning" className={classes.rateButton}>
                            Calificar
                        </Button> 
                    </div>
                    <ReviewsList queryId={"userId="} productQuery={"productId="} props={user}/>
                </div>
                
                <DialogContent>
                    <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                    >

                    </DialogContentText>   
                </DialogContent>             
            </Dialog>
        </div>
    )
}