import { combineReducers } from "redux";
import socket_store from "./socketReducer.js";
import cart_store from "./cartReducer.js";
import products_store from "./productsReducer.js";
import order_pdf_store from "./orderPdfReducer.js";
import user_store from "./userReducer.js";
import login_store from "./loginReducer.js";

const rootReducers = combineReducers({
    socket_store,
    cart_store,
    products_store,
    order_pdf_store,
    user_store,
    login_store,
});

export default rootReducers;