import React, {useState, useEffect, useRef, memo} from 'react';
import ProductCard from '../../global/ProductCard';
import SkeletonCard from '../../global/SkeletonCard';
import Masonry from 'react-masonry-css';
import url, { importUrl } from '../../../config';
import NoOtherProducts from '../../global/NoOtherProducts';

// const userStyles = makeStyles({
//     btn: {
//         fontSize: 60,
//         borderRadius: 25,
//         '&:hover' : {
//             //backgroundColor: 'blue'
//         }
//     },
//     field: {
//         marginTop: 20,
//         marginBottom: 20,
//         display: 'block'
//     }
// })
const skeleton = [-1,-2,-3,-4,-5,-6,-7,-8,-9,-10]

export const hasReached = (el) => {
    return el.getBoundingClientRect().top <= window.innerHeight;
  }

export const ProductsDetailPageProductList = memo((props) => {
    //const classes = userStyles()
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [favoritesList,setFavoritesList] = useState({})
    const isFetching = useRef(false)
    const reachFinalProduct = useRef(false)
    const [page, setPage] = useState(1)
    const [reset,setReset] = useState(false)
    const [noProducts, setNoProducts] = useState(false)
    const firstTime = useRef(true)

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
      }, []);
    
    
    useEffect(() => {
        const fetchProducts = async () => {
            try{
                loadFavoritesList()
                const requestOptions = {
                    method: 'GET'
                };
                console.log(props)
                var productsUrl = new URL(`${url}/api/${importUrl? 'productsImport' : 'products'}/ofuser/${importUrl? props.categoryId+'/-1' : props.userId}`),
                params = {page:page, limit:"10"}
                Object.keys(params).forEach(key => productsUrl.searchParams.append(key, params[key]))
                let result = await fetch(productsUrl,requestOptions)
                if(result.status === 200){
                    let newData = await result.json()
                    let foundSameProduct = -1
                    for(var i = 0; i < newData.length; i++) {
                        if(newData[i]._id === props.productId){
                            foundSameProduct = i
                            break;
                        }
                    }
                    if  (newData.length < 10){
                        if(page === 1){
                            if(newData.length === 0){
                                setNoProducts(true)
                            }
                        }
                        reachFinalProduct.current = true
                    }
                    if(foundSameProduct !== -1){
                        newData.splice(foundSameProduct, 1)
                    }
                    setLoading(false)
                    setData(data.concat(newData))
                    isFetching.current = false
                }
                else{
                    setLoading(false)
                    isFetching.current = false
                }
            } catch(error){
                console.log(error)
                setLoading(false)
                isFetching.current = false
            }
        }
        fetchProducts()
    }, [page, reset])

    useEffect(() => {
        const fetchCategories = () => {
            if (firstTime.current){
                firstTime.current = false
            }
            else{
                setLoading(true)
                reachFinalProduct.current = false
                window.scrollTo(0,0)
                setPage(prevPage => 1)
                setReset(prevReset => !prevReset)
                setData(prevData => [])
            }
        }
        fetchCategories() 
    }, [props])

    const handleScroll = () => {
        const wrappedElement = document.getElementById('fecthMoreProducts');
        if (wrappedElement != null && !isFetching.current && !reachFinalProduct.current){

            if (wrappedElement.getBoundingClientRect().top <= window.innerHeight){
                isFetching.current = true
                setPage(prevPage => prevPage+1)
                
            }
        }
        return;
    };

    const loadFavoritesList = () => {
        const isUserLogged = window.localStorage.getItem('user')
        if(isUserLogged){
            const userId = JSON.parse(isUserLogged).id
            const data = window.localStorage.getItem(`${userId}`)
            if(data){
                const newData = JSON.parse(data)
                setFavoritesList(newData)
            }
        }
    }
    
    const breakpoints = {
        default: 4,
        1280: 4,
        960: 3,
        600: 2
    }

    return (
        <div>
            {loading? 
                <Masonry
                breakpointCols={breakpoints}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
                >
                    {skeleton.map(skeletonId => (
                        <div key={skeletonId}>
                            <SkeletonCard/>
                        </div>
                    ))}
                </Masonry>
            :
                [noProducts?
                    <NoOtherProducts />
                :
                <Masonry
                breakpointCols={breakpoints}
                className="my-masonry-grid_detail"
                columnClassName="my-masonry-grid_column_detail"
                >
                    {data.map(product => (
                        <div key={product.id}>
                            <ProductCard
                                product={product}
                                isFavorite={favoritesList[`${product.id}`]}
                                homePage={true}/>
                        </div>
                    ))}
                    <div id="fecthMoreProducts"></div>
                    {!reachFinalProduct.current?
                        skeleton.map(skeletonId => (  
                            <div key={skeletonId}>
                                <SkeletonCard/>
                            </div>
                    ))
                    :
                    <span></span>
                    }
                </Masonry>
                ]
            }
            
        </div>
    )
    
})