import { UPDATE_LOGIN } from "../actions/loginAction";

const default_login = false

function login_store(state = default_login, action){
    switch(action.type) {
        case UPDATE_LOGIN: {
            return  action.payload
        }
        default: return state;
    }
}

export default login_store