import React from 'react';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    holder: {
        width: '100%',
        height: '50px',
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'block'
        }
    },
}))

  export default function SmallTabBarMargin() {
    const classes  = useStyles()
    
    return(
        <div className={classes.holder} id='smallTabBar'/>
    )
}