import React, { useState, useEffect, useRef } from 'react'
import url, { colors, importUrl } from '../../config'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import StarIcon from '@material-ui/icons/Star';
import {IoBagCheck} from 'react-icons/io5';
import Button from '@material-ui/core/Button'
import { alpha, CircularProgress, Menu, MenuItem, Snackbar } from '@material-ui/core'
import { Alert, Skeleton } from '@material-ui/lab'
import { Rating } from '@material-ui/lab'
import userPlaceholder from '../../assets/img/userPlaceholder.png'
import ReviewsList from './ReviewsList'
import ReviewsModal from './ReviewsModal'
import DeleteProductModal from './DeleteProductModal'
import { Dialog, DialogContent, DialogContentText } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { InputBase } from "@material-ui/core";
import { connect, useDispatch, useSelector } from 'react-redux'
import { ArrowDropDown } from '@material-ui/icons'
import { update_login_action } from '../../redux/actions/loginAction'

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '100%',
    },
    userCard: {
        position: 'absolute',
        zIndex: '101',
        top: '400px',
        left: '5%',
        [theme.breakpoints.down('sm')]: {
            top: '375px',
          },
        [theme.breakpoints.down('xs')]: {
            position: 'fixed',
            bottom: '10px',
            top: 'auto',
            left: 0,
            right: 0
          },
    },
    opinionsAboutHolder: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
          },
    },
    userReviews: {
        position: 'absolute',
        zIndex: '101',
        top: '400px',
        left: '5%',
        [theme.breakpoints.down('sm')]: {
            top: '375px',
          },
        [theme.breakpoints.down('xs')]: {
            left: '0',
            right: '0',
          },
    },
    userInfo: {
        borderRadius: '15px',
        width: '320px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 'auto',
            marginRight: 'auto'
          },
    },
    userHolder:{
        display: 'flex',
        marginTop: '20px',
        marginBottom: '15px',
        verticalAlign: 'middle'
    },
    media: {
        marginLeft: '30px',
        width: '50px',
        height: '50px',
        borderRadius: '50%'
    },
    userName:{
        color: '#494949',
        fontSize: '15px',
        fontWeight: '600',
        maxWidth: '100px',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    price:{
        fontSize: 20,
        fontWeight: 700
    },
    starOff: {
        width: '20px',
        height: '20px',
        color: '#E8E8E8'
    },
    opinions: {
        marginLeft:'5px',
        fontSize: '13px',
        color: '#4A4A4A'
    },
    checkIcon: {
        marginLeft: '12px',
        width: '20px',
        height: '20px',
    },
    certifiedText:{
        fontSize: 14,
        fontStyle: 'italic',
        marginLeft: '5px'
    },
    offerButton: {
        marginLeft: 'auto',
        marginRight: '7px',
        backgroundColor: colors.primary,
        borderColor: colors.primary,
        borderRadius: '3px',
        border: 'solid 1.5px',
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: alpha(colors.primary, 0.85),
        }
    },
    disabledButton: {
        marginLeft: 'auto',
        marginRight: '7px',
        borderColor: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: '#FFFFFF',
        borderRadius: '3px',
        border: 'solid 1.5px',
        color: 'rgba(0, 0, 0, 0.26)',
        '&:hover': {
            backgroundColor: alpha('rgba(0, 0, 0, 0.26)', 0.15),
        }
    },
    contactButton: {
        marginLeft: '7px',
        marginRight: 'auto',
        backgroundColor: '#FFFFFF',
        borderColor: colors.primary,
        borderRadius: '3px',
        border: 'solid 1.5px',
        color: colors.primary,
        '&:hover': {
            backgroundColor: alpha('#CAD1D7', 0.4),
        }
    },
    editButton: {
        marginLeft: 'auto',
        marginRight: '7px',
        backgroundColor: '#FFFFFF',
        borderColor: colors.orangeEdit,
        borderRadius: '3px',
        border: 'solid 1.5px',
        color: colors.orangeEdit,
        '&:hover': {
            backgroundColor: alpha(colors.orangeEdit, 0.2),
        }
    },
    deleteButton: {
        marginLeft: '7px',
        marginRight: 'auto',
        backgroundColor: '#FFFFFF',
        borderColor: colors.redError,
        borderRadius: '3px',
        border: 'solid 1.5px',
        color: colors.redError,
        '&:hover': {
            backgroundColor: alpha(colors.redError, 0.2),
        }
    },
    republishButton: {
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: '#FFFFFF',
        borderColor: colors.republishButton,
        borderRadius: '3px',
        border: 'solid 1.5px',
        color: colors.republishButton,
        width: '200px',
        '&:hover': {
            backgroundColor: alpha(colors.republishButton, 0.2),
        }
    },
      inputHolder: {
        display: 'flex',
        marginTop: '10px',
        marginBottom: '5px',
        backgroundColor:'#ffffff', 
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);', 
        borderRadius: '3px',
        '&:hover': {
          boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
        },
        '&:focus': {
          boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
        }
    },
        inputOfferHolder: {
            width: '250px',
            display: 'flex',
            marginTop: '10px',
            marginBottom: '5px',
            backgroundColor:'#ffffff', 
            boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);', 
            borderRadius: '3px',
            '&:hover': {
              boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
            },
            '&:focus': {
              boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
            }
        },
    cancelButton: {
        padding: '4px',
        display: 'block',
        margin: '0px 0px 0px auto'
    },
    inputRootLogin: {
        width: '100%',
    },
    inputInputLogin: {
        height: 22,
        fontSize: '1rem',
        fontWeight: 400,
        // vertical padding + font size from searchIcon
        transition: theme.transitions.create('width'),
        width: '100%',
        paddingLeft: '10px',
        paddingRight: '10px',
        '&::placeholder': { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: 'rgba(0, 0, 0, 0.54)',
            opacity: 1 /* Firefox */
          },
          
          '&:-ms-input-placeholder': { /* Internet Explorer 10-11 */
            color: 'rgba(0, 0, 0, 0.54)'
          },
          
          '&::-ms-input-placeholder': { /* Microsoft Edge */
            color: 'rgba(0, 0, 0, 0.54)'
          },
    },
    inputRootOffer: {
        width: '100%',
        height: '100px'
    },
    inputInputOffer: {
        fontSize: '2.5rem',
        fontWeight: 400,
        lineHeight: '2.5rem',
        textAlign: 'right',
        // vertical padding + font size from searchIcon
        transition: theme.transitions.create('width'),
        width: '100%',
        paddingLeft: '10px',
        paddingRight: '10px',
        '&::placeholder': { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: 'rgba(0, 0, 0, 0.54)',
            opacity: 1 /* Firefox */
          },
          
          '&:-ms-input-placeholder': { /* Internet Explorer 10-11 */
            color: 'rgba(0, 0, 0, 0.54)'
          },
          
          '&::-ms-input-placeholder': { /* Microsoft Edge */
            color: 'rgba(0, 0, 0, 0.54)'
          },
    },
    primaryButton: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: '7px',
        marginTop: '15px',
        backgroundColor: colors.primary,
        borderColor: colors.primary,
        borderRadius: '3px',
        border: 'solid 1.5px',
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: alpha(colors.primary, 0.85),
        }
      },
}));

const currencies = ["MN", "USD", "MLC"] 

function UserCard({props, socket_store}) {
    const classes = useStyles();
    const history = useHistory();
    const [openReview, setOpenReview] = useState(false)

    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [openDeleteSuccessSnack, setOpenDeleteSuccessSnack] = useState(false)
    const [openDeleteApiErrorSnack, setOpenDeleteApiErrorSnack] = useState(false)

    const descriptionSendMessageElementRef = React.useRef(null);
    const descriptionSendOfferElementRef = React.useRef(null);
    const [openSendMessage, setOpenSendMessage] = useState(false)
    const [messageText, setMessageText] = useState("Hola, sigue disponible?")
    const [messageTextError, setMessageTextError] = useState(false)
    const [sendingMessage, setSendingMessage] = useState(false)

    const [openSendOffer, setOpenSendOffer] = useState(false)
    const [offerText, setOfferText] = useState("")
    const [offerTextError, setOfferTextError] = useState(false)
    const [sendingOffer, setSendingOffer] = useState(false)

    const [openSuccesSnack, setOpenSuccesSnack] = useState(false)
    const [openApiErrorSnack, setOpenApiErrorSnack] = useState(false)
    const [openRepublishSuccessSnack, setOpenRepublishSuccessSnack] = useState(false)
    const [openRepublishApiErrorSnack, setOpenRepublishApiErrorSnack] = useState(false)
    const [openCantRepublishSnack, setOpenCantRepublishSnack] = useState(false)
    const [openSuccesOfferSnack, setOpenSuccesOfferSnack] = useState(false)
    const [openApiErrorOfferSnack, setOpenApiErrorOfferSnack] = useState(false)
    const [openLoginNeededSnack, setOpenLoginNeededSnack] = useState(false)
    const [openNoOfferSnack, setOpenNoOfferSnack] = useState(false)

    const [anchorElCurrency, setAnchorElCurrency] = useState(null);
    const openCurrency = Boolean(anchorElCurrency);
    const [selectedCurrency, setSelectedCurrency] = useState("MN")

    const [republishingProduct, setRepublishingProduct] = useState(false)

    const userLogged = useSelector((state) => state.user_store.user)
    const dispatch = useDispatch()

    const socket = useRef(null)

    useEffect(() => {
        if(!props.loading){
            setSelectedCurrency(props.productCurrency)
        }
    }, [props])

    const handleCloseReviewParent = (openRate) => {
        setOpenReview(false)
        if(openRate){
            props.handleOpinionsOpen()
        }
    }

    const calculateRepublishTime = () => {
        const hoursLeft =  (Date.now() - props.lastUpload) / 1000 / 60 / 60
        if(24 - hoursLeft > 1){
            return `Quedan ${parseInt(24 - hoursLeft)} horas restantes`
        }
        if(24 - hoursLeft === 1){
            return `Queda ${parseInt(24 - hoursLeft)} hora restante`
        }
        else{
            const minutesLeft = (Date.now() - props.lastUpload) / 1000 / 60
            if(60 - minutesLeft > 1){
                return `Quedan ${parseInt(60 - minutesLeft)} minutos restantes`
            }
            else{
                return "Queda 1 minuto restante"
            }
        }
    }

    const handleRepublish = async () => {
        try{
            if(republishingProduct) return;
            if((Date.now() - props.lastUpload) / 1000 / 60 < 1440){
                setOpenCantRepublishSnack(true)
                return
            }
            setRepublishingProduct(true)
            const requestOptions = {
              method: 'PATCH',
              headers: { 'Content-Type': 'application/json',
                         'Authorization': `${userLogged.accessToken}`
              },
              body: JSON.stringify({ 
                _id : props.productId,
                republish : true,
              })
            };
            let result = await fetch(`${url}/api/products`, requestOptions);
            if(result.status === 200){
              setOpenRepublishSuccessSnack(true)
              setRepublishingProduct(false)
            }
            else{
              OpenRepublishApiErrorSnack()
              setRepublishingProduct(false)
            }
        }
        catch(e){
            console.log(e)
            setRepublishingProduct(false)
            OpenRepublishApiErrorSnack()
        }
    }

    const contactOnClick = (event) => {
        if(userLogged){
            setOpenSendMessage(true)
        }
        else{
            setOpenLoginNeededSnack(true)
            dispatch(update_login_action(true))
        }
    }
    const offerOnClick = (event) => {
        if(userLogged){
            if(props.fixablePrice){
                setOpenNoOfferSnack(true)
                return;
            }
            setOpenSendOffer(true)
        }
        else{
            setOpenLoginNeededSnack(true)
            dispatch(update_login_action(true))
        }
    }

    const handleChangeMessageText = (event) => {
        if(messageTextError){
            setMessageTextError(false)
        }
        setMessageText(event.target.value);
      };

      const handleChangeOfferText = (event) => {
        if(offerTextError){
            setOfferTextError(false)
        }
        setOfferText(event.target.value);
      };

      const handleSendMessageClose = () => {
        setOpenSendMessage(false)
    }

    const handleSendOfferClose = () => {
        setOpenSendOffer(false)
    }

    const onClickSend = async(event) => {
        if(sendingMessage)return;
        if(!messageText){
            setMessageTextError(true)
            return;
        }
        setSendingMessage(true)
        try{
            const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                        'Authorization': `${userLogged.accessToken}`
            },
            body: JSON.stringify({ 
                messageText : messageText,
                messageDate: Date.now(),
                messageType: 'text',
                userReceive: props._id
            })
            };
            let result = await fetch(`${url}/api/conversations/?userOwner=${props._id}&product=${props.productId}`, requestOptions);
            console.log(result)
            if(result.status === 200){
                let newData = await result.json();
                console.log(newData)
                if(!socket.current){
                    if(socket_store.socket){
                        socket.current = socket_store.socket
                        socket.current.emit("sendMessage", {
                            message: newData.newMessage,
                            userId: props._id,
                            conversation: newData.newConversation
                        })    
                    }
                } 
                else{
                    socket.current.emit("sendMessage", {
                        message: newData.newMessage,
                        userId: props._id,
                        conversation: newData.newConversation
                    })
                }
                setMessageText("Hola, sigue disponible?")
                setOpenSuccesSnack(true)
                setSendingMessage(false)
            }
            else{
                setOpenApiErrorSnack(true)
                setSendingMessage(false)
            }
        }
        catch{
            setOpenApiErrorSnack(true)
            setSendingMessage(false)
        }
        setOpenSendMessage(false)
        setOpenSuccesSnack(true)
    }

    const onClickSendOffer = async(event) => {
        if(sendingOffer)return;
        if(!offerText){
            setOfferTextError(true)
            return;
        }
        setSendingOffer(true)
        try{
            const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                        'Authorization': `${userLogged.accessToken}`
            },
            body: JSON.stringify({ 
                messageText : `${offerText} ${selectedCurrency}`,
                messageDate: Date.now(),
                messageType: 'offer',
                userReceive: props._id
            })
            };
            let result = await fetch(`${url}/api/conversations/?userOwner=${props._id}&product=${props.productId}`, requestOptions);
            console.log(result)
            if(result.status === 200){
                let newData = await result.json();
                console.log(newData)
                if(!socket.current){
                    if(socket_store.socket){
                        socket.current = socket_store.socket
                        socket.current.emit("sendMessage", {
                            message: newData.newMessage,
                            userId: props._id,
                            conversation: newData.newConversation
                        })    
                    }
                } 
                else{
                    socket.current.emit("sendMessage", {
                        message: newData.newMessage,
                        userId: props._id,
                        conversation: newData.newConversation
                    })
                }
                setOfferText("")
                setOpenSuccesOfferSnack(true)
                setSendingOffer(false)
            }
            else{
                setOpenApiErrorSnack(true)
                setSendingOffer(false)
            }
        }
        catch{
            setOpenApiErrorSnack(true)
            setSendingOffer(false)
        }
        setOpenSendOffer(false)
        setOpenSuccesOfferSnack(true)
    }

    const handleCurrencyClick = (event) => {
        setAnchorElCurrency(event.currentTarget);
      };
      const handleCurrencyClose = (currency) => {
        setAnchorElCurrency(null);
        if (currency !== null){
            setSelectedCurrency(currency)      
        }
      };

    const handleCloseSuccesSnack = () => {
        setOpenSuccesSnack(false)
    }
    const handleCloseApiErrorSnack = () => {
        setOpenApiErrorSnack(false)
    }

    const handleCloseRepublishApiErrorSnack = () => {
        setOpenRepublishApiErrorSnack(false)
    }

    const handleCloseSuccesOfferSnack = () => {
        setOpenSuccesOfferSnack(false)
    }
    const handleCloseApiErrorOfferSnack = () => {
        setOpenApiErrorOfferSnack(false)
    }
    const handleCloseLoginNeededSnack = () => {
        setOpenLoginNeededSnack(false)
    }
    const handleCloseNoOfferSnack = () => {
        setOpenNoOfferSnack(false)
    }

    const handleOpinionsClick = (event) => {
        const {innerWidth} = window
        if(innerWidth < 960){
            if(props.reviewsCount === 0){
                props.handleOpinionsOpen()
                return
            }
            setOpenReview(true)
        }
        else{
            console.log("DANDO CLICK")
            props.handleOpinionsOpen()
        }
    }

    const editProductOnClick = (event) => {
        history.push(`/myproducts/edit/${props.productId}`)
        //window.scrollTo(0, 0);
    }

    const deleteProductOnClick = (event) => {
        console.log("DELETE CLICK")
        setOpenDeleteModal(true)
    }

    const closeDeleteModal = () => {
        setOpenDeleteModal(false)
    }

    const OpenDeleteSuccessSnack = () => {
        setOpenDeleteModal(false)
        setOpenDeleteSuccessSnack(true)
    }
    const OpenDeleteApiErrorSnack = () => {
        setOpenDeleteApiErrorSnack(true)
    }

    const OpenRepublishApiErrorSnack = () => {
        setOpenRepublishApiErrorSnack(true)
    }

    const handleCloseDeleteSuccessSnack = () => {
        setOpenDeleteSuccessSnack(false)
        history.goBack()
    }

    const handleCloseCantRepublishSnack = () => {
        setOpenCantRepublishSnack(false)
    }

    const handleCloseRepublishSuccessSnack = () => {
        setOpenRepublishSuccessSnack(false)
        window.location.reload(false);
    }

    const handleCloseDeleteApiErrorSnack = () => {
        setOpenDeleteApiErrorSnack(false)
    }

    const toUsersPage = () => {
        if(props.isUserOwner){
            history.push(`/account`)
        }
        else{
            history.push(`/users/${props._id}`)
        }
    }

    const getCertifiedColor = () => {
        if(props?.certification === 'Tienda Oficial'){
            return colors.officialStore
        }
        if(props?.certification === 'Premium'){
            return colors.premium
        }
        return 'white'
    }

    return (
        <React.Fragment>
        {props.loading?
            <div className={classes.userCard} style={{display:'flex'}}>
                <Card className={classes.userInfo}>
                    
                        <div className={classes.userHolder}>  
                            <Skeleton
                                className={classes.media}
                                variant = 'circle' />
                            <div style={{marginLeft:'15px'}}>
                                <div style={{display:'flex', verticalAlign: 'middle'}}>
                                    <Skeleton 
                                        variant='rect' width='170px' height='20px'/>
                                    
                                </div>
                                <div style={{display:'flex', verticalAlign: 'middle'}}>
                                    <StarIcon className={classes.starOff} />
                                    <StarIcon className={classes.starOff} />
                                    <StarIcon className={classes.starOff} />
                                    <StarIcon className={classes.starOff} />
                                    <StarIcon className={classes.starOff} />
                                    <Skeleton 
                                        variant='rect' width='70px' height='15px' style={{marginTop:'4px'}}/>
                                </div>
                            </div>
                        
                        </div>
                    {!importUrl &&
                    <div style={{display: 'flex', width: '100%', marginBottom: '20px'}}>
                        <Button
                            variant="contained"
                            className={classes.offerButton}
                            classes={{
                                label: classes.label, // class name, e.g. `classes-nesting-label-x`
                            }}
                        >HACER OFERTA</Button>
                        <Button
                            variant="contained"
                            className={classes.contactButton}
                            classes={{
                                label: classes.label, // class name, e.g. `classes-nesting-label-x`
                            }}
                        >CONTACTAR</Button>
                    </div>
                    }
                
                </Card>
            </div>
        :
            <div className={classes.userCard}>
                <Snackbar open={openRepublishSuccessSnack} autoHideDuration={1000} onClose={handleCloseRepublishSuccessSnack}>
                    <Alert onClose={handleCloseRepublishSuccessSnack} severity="success">
                        Se republico el anuncio.
                    </Alert>
                </Snackbar>
                <Snackbar open={openRepublishApiErrorSnack} autoHideDuration={2000} onClose={handleCloseRepublishApiErrorSnack}>
                    <Alert onClose={handleCloseRepublishApiErrorSnack} severity="error">
                        No se pudo republicar anuncio. Revise su conexión a Internet.
                    </Alert>
                </Snackbar>
                <Snackbar open={openCantRepublishSnack} autoHideDuration={2000} onClose={handleCloseCantRepublishSnack}>
                    <Alert onClose={handleCloseCantRepublishSnack} severity="warning">
                        Solo puede republicar cada 24 horas. {calculateRepublishTime()}.
                    </Alert>
                </Snackbar>
                <Snackbar open={openDeleteSuccessSnack} autoHideDuration={1000} onClose={handleCloseDeleteSuccessSnack}>
                    <Alert onClose={handleCloseDeleteSuccessSnack} severity="success">
                        Se eliminó el anuncio.
                    </Alert>
                </Snackbar>
                <Snackbar open={openDeleteApiErrorSnack} autoHideDuration={2000} onClose={handleCloseDeleteApiErrorSnack}>
                    <Alert onClose={handleCloseDeleteApiErrorSnack} severity="error">
                        No se pudo eliminar anuncio. Revise su conexión a Internet.
                    </Alert>
                </Snackbar>
                <Snackbar open={openSuccesSnack} autoHideDuration={2000} onClose={handleCloseSuccesSnack}>
                    <Alert onClose={handleCloseSuccesSnack} severity="success">
                        Mensaje enviado correctamente.
                    </Alert>
                </Snackbar>
                <Snackbar open={openApiErrorSnack} autoHideDuration={2000} onClose={handleCloseApiErrorSnack}>
                    <Alert onClose={handleCloseApiErrorSnack} severity="error">
                        No se pudo enviar el Mensaje. Revise su conexión a Internet.
                    </Alert>
                </Snackbar>
                <Snackbar open={openSuccesOfferSnack} autoHideDuration={2000} onClose={handleCloseSuccesOfferSnack}>
                    <Alert onClose={handleCloseSuccesOfferSnack} severity="success">
                        Oferta enviada correctamente.
                    </Alert>
                </Snackbar>
                <Snackbar open={openApiErrorOfferSnack} autoHideDuration={2000} onClose={handleCloseApiErrorOfferSnack}>
                    <Alert onClose={handleCloseApiErrorOfferSnack} severity="error">
                        No se pudo enviar la Oferta. Revise su conexión a Internet.
                    </Alert>
                </Snackbar>
                <Snackbar open={openLoginNeededSnack} autoHideDuration={2000} onClose={handleCloseLoginNeededSnack}>
                    <Alert onClose={handleCloseLoginNeededSnack} severity="warning">
                        Debe iniciar sesión para realizar esta acción.
                    </Alert>
                </Snackbar>
                <Snackbar open={openNoOfferSnack} autoHideDuration={2000} onClose={handleCloseNoOfferSnack}>
                    <Alert onClose={handleCloseNoOfferSnack} severity="warning">
                        El usuario no acepta ofertas por este producto.
                    </Alert>
                </Snackbar>
                <Card className={classes.userInfo}>
                    <div style={{cursor: 'pointer'}}>
                        <div className={classes.userHolder}>  
                            <LazyLoadImage
                                onClick={toUsersPage}
                                className={classes.media}
                                effect="blur"
                                src={props.profilePictureUrl? props.profilePictureUrl : userPlaceholder} />
                            <div style={{marginLeft:'15px'}}>
                                <div onClick={toUsersPage} style={{display:'flex', verticalAlign: 'middle'}}>
                                    <Typography className={classes.userName} color="textSecondary" component="p">
                                        {props.username}
                                    </Typography>
                                    {props?.certification &&
                                    <IoBagCheck className={classes.checkIcon} style={{color: getCertifiedColor()}}/>
                                    }
                                    {props?.certification &&
                                    <Typography className={classes.certifiedText} style={{color: getCertifiedColor()}} component="p">
                                        Certificado
                                    </Typography>
                                    }
                                </div>
                                <ReviewsModal openReview={openReview} user={props} handleReviewCloseParent={handleCloseReviewParent}/>
                                <div onClick={handleOpinionsClick} style={{display:'flex', verticalAlign: 'middle'}}>
                                    <Rating
                                        name="readonly-feedback"
                                        value={props.reviewsCount > 0? props.reviewsRate/props.reviewsCount : 0}
                                        readOnly
                                        size="small"
                                        precision={0.5}
                                    />
                                    <Typography className={classes.opinions}>{props.reviewsCount? props.reviewsCount : 0} {props.reviewsCount === 1? "calificación" : "calificaciones"}</Typography>
                                </div>
                            </div>
                        
                        </div>
                    </div>
                    {props.isUserOwner?
                    <React.Fragment>
                        <DeleteProductModal openDeleteModal={openDeleteModal} productId={props.productId} productName={props.productName} setOpenSuccesSnack={OpenDeleteSuccessSnack} setOpenApiErrorSnack={OpenDeleteApiErrorSnack} closeDeleteModalParent={closeDeleteModal}/>
                        <div style={{display: 'flex', width: '100%', marginBottom: '15px'}}>
                            <Button
                                onClick={editProductOnClick}
                                variant="contained"
                                className={classes.editButton}
                                classes={{
                                    label: classes.label, // class name, e.g. `classes-nesting-label-x`
                                }}
                            >EDITAR</Button>
                            <Button
                                onClick={deleteProductOnClick}
                                variant="contained"
                                className={classes.deleteButton}
                                classes={{
                                    label: classes.label, // class name, e.g. `classes-nesting-label-x`
                                }}
                            >ELIMINAR</Button>
                        </div>
                        <div style={{display: 'flex', width: '100%', marginBottom: '20px'}}>
                            {republishingProduct?
                                <Button
                                variant="contained"
                                className={classes.republishButton}
                                classes={{
                                    label: classes.label, // class name, e.g. `classes-nesting-label-x`
                                }}>
                                    <CircularProgress size={25} style={{color:colors.republishButton}} />
                                </Button>
                            :
                                <Button
                                onClick={handleRepublish}
                                variant="contained"
                                className={classes.republishButton}
                                classes={{
                                    label: classes.label, // class name, e.g. `classes-nesting-label-x`
                                }}
                                    >REPUBLICAR
                                </Button>
                            }
                        </div>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <Dialog
                            PaperProps={{
                            style: { borderRadius: '15px', backgroundColor: '#f5f5f5', width: '100%' }
                            }}
                            open={openSendMessage}
                            onClose={handleSendMessageClose}
                            scroll="body"
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                            >
                            <DialogContent>
                                <DialogContentText
                                id="scroll-dialog-description"
                                ref={descriptionSendMessageElementRef}
                                tabIndex={-1}
                                >
                                
                                <div style={{display: 'flex'}}>
                                <Typography style={{fontSize:'18px', fontWeight:'600', color: colors.primary, paddingTop: '3px'}}>Contactar</Typography>
                                <IconButton size="large" onClick={() => {setOpenSendMessage(false)}} aria-label="warning" className={classes.cancelButton}>
                                    <CancelIcon />
                                </IconButton>
                                </div>
                                <div style={{paddingLeft:'15px', width: '100%', verticalAlign: 'middle', display:'table', height:'100%', marginBottom: '10px'}}>
                                <div className={classes.inputHolder}>
                                    <InputBase
                                    elevation={4}
                                        placeholder="Escriba un mensaje"
                                        type="text"
                                        multiline
                                        minRows={5}
                                        maxRows={5}

                                        value={messageText}
                                        onChange={handleChangeMessageText}
                                        classes={{
                                            root: classes.inputRootLogin,
                                            input: classes.inputInputLogin,
                                        }}
                                        inputProps={{ 'aria-label': 'search', maxLength:'250'}}
                                    />
                                </div>
                                {messageTextError &&
                                    <Typography style={{color: colors.redError, fontSize: '14px', marginLeft:'5px'}}>Debe escribir una reseña</Typography>                       
                                }
                                <Button disabled={sendingMessage} onClick={onClickSend} className={classes.primaryButton}>
                                    Enviar
                                </Button>
                                </div>
                                {sendingMessage && <CircularProgress size={60} style={{position:'absolute',top:'43%', left:'40%'}}/>}
                            </DialogContentText>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            PaperProps={{
                            style: { borderRadius: '15px', backgroundColor: '#f5f5f5' }
                            }}
                            open={openSendOffer}
                            onClose={handleSendOfferClose}
                            scroll="body"
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                            >
                            <DialogContent>
                                <DialogContentText
                                id="scroll-dialog-description"
                                ref={descriptionSendOfferElementRef}
                                tabIndex={-1}
                                >
                                
                                <div style={{display: 'flex'}}>
                                <Typography style={{fontSize:'18px', fontWeight:'600', color: colors.primary, paddingTop: '3px'}}>Hacer Oferta</Typography>
                                <IconButton size="large" onClick={() => {setOpenSendOffer(false)}} aria-label="warning" className={classes.cancelButton}>
                                    <CancelIcon />
                                </IconButton>
                                </div>
                                <div style={{paddingLeft:'15px', width: '100%', verticalAlign: 'middle', display:'table', height:'100%', marginBottom: '10px'}}>
                                <div className={classes.inputOfferHolder}>
                                    <div style={{width: 'calc(100%-160px)'}}>
                                    <InputBase
                                    elevation={4}
                                        placeholder="999.99"
                                        type="number"
                                        value={offerText}
                                        onChange={handleChangeOfferText}
                                        classes={{
                                            root: classes.inputRootOffer,
                                            input: classes.inputInputOffer,
                                        }}
                                        inputProps={{ 'aria-label': 'search', maxLength:'250'}}
                                    />
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        <div onClick={handleCurrencyClick} style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                                            <Typography style={{fontSize:'22px', marginLeft:'10px', color: 'black', fontWeight: '600'}}>{selectedCurrency}</Typography>
                                            <ArrowDropDown style={{height: '20px', width:'20px', marginLeft:'-2px', marginRight:'-4px'}}/>
                                        </div>
                                        <Menu
                                            id="long-menu"
                                            anchorEl={anchorElCurrency}
                                            keepMounted
                                            open={openCurrency}
                                            onClose={() => handleCurrencyClose(null)}
                                            PaperProps={{
                                            style: {
                                            },
                                            }}
                                        >
                                            {currencies.map((currency) => (
                                                <MenuItem key={currency} selected={currency === selectedCurrency} onClick={() => handleCurrencyClose(currency)}>
                                                    {currency}
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </div>
                                </div>
                                {offerTextError &&
                                    <Typography style={{color: colors.redError, fontSize: '14px', marginLeft:'5px'}}>Debe poner una oferta</Typography>                       
                                }
                                <Button disabled={sendingOffer} onClick={onClickSendOffer} className={classes.primaryButton}>
                                    Enviar
                                </Button>
                                </div>
                                {sendingOffer && <CircularProgress size={60} style={{position:'absolute',top:'34%', left:'40%'}}/>}
                            </DialogContentText>
                            </DialogContent>
                        </Dialog>
                        {!importUrl &&
                        <div style={{display: 'flex', width: '100%', marginBottom: '20px'}}>
                            <Button
                                onClick={offerOnClick}
                                variant="contained"
                                className={props.fixablePrice? classes.disabledButton : classes.offerButton}
                                classes={{
                                    label: classes.label, // class name, e.g. `classes-nesting-label-x`
                                }}
                            >HACER OFERTA</Button>
                            <Button
                                variant="contained"
                                onClick={contactOnClick}
                                className={classes.contactButton}
                                classes={{
                                    label: classes.label, // class name, e.g. `classes-nesting-label-x`
                                }}
                            >CONTACTAR</Button>
                        </div>
                        }
                    </React.Fragment>
                    }
                    
                </Card>
                <div className={classes.opinionsAboutHolder}>
                    <ReviewsList queryId={"userId="} productQuery={"productId="} props={props}/>
                    <div style={{marginBottom: '20px'}}></div>
                </div>
            </div>
        }
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return{
      socket_store: state.socket_store
    }
  }
  
  export default connect(mapStateToProps)(UserCard)