import { alpha, Box, Button, CircularProgress, Collapse, IconButton, InputBase, Menu, MenuItem, Modal, MuiThemeProvider, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@material-ui/core";
import { makeStyles, createTheme } from "@material-ui/core/styles";
import { Alert, Skeleton } from "@material-ui/lab";
import React, {Suspense, useEffect, useState} from "react";
import url, { colors, sendingCostValue, supportNumber, guideCut, packaging, discountOverTotalPrice, discountOverTotalPriceMessage, discountOverValue } from "../../config";
import Navbar from "../global/navbar";
import { ArrowDropDown, ArrowForwardIos, KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import WarningAmberIcon from '@material-ui/icons/PriorityHigh'
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { BsFillFunnelFill } from "react-icons/bs";
import { useHistory, useLocation } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import { useSelector } from "react-redux";
import DeleteOrderModal from "../global/DeleteOrderModal";
import NoConnection from "../global/NoConnection";
import b2_truck from "../../assets/promotional_banner/b2_truck.png"

const PdfDocumentLoader = React.lazy(() => import('../global/PdfDocumentLoader'))

const themeMUI = createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          color: "white",
          backgroundColor: "rgba(251,82,100,0.85)",
          borderTop: 'solid 2px rgb(251,82,100)'
        }
      }
    }
});


const useStyles = makeStyles((theme) => ({
    orderCard: {
        marginTop: '10px',
        marginBottom: '10px',
        //backgroundColor: colors.containerGradientFirst,
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '90%'
        },
        [theme.breakpoints.down('xs')]: {
            width: '95%'
        }
    },
    orderInfoHolder: {
        //textAlign: 'center',
        marginTop: '10px',
        marginBottom: '10px',
        //backgroundColor: colors.containerGradientFirst,
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '90%'
        },
        [theme.breakpoints.down('xs')]: {
            width: '95%'
        }
    },
    orderInfo: {
        color: colors.secondary,
        fontSize: '18px',
        fontWeight: '600',
        //textAlign: 'center',
    },
    orderContainer: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
        }
    },
    infoSection: {
        display: 'flex'
    },
    infoHolder: {
        margin: '5px 15px'
    },
    header: {
        color: colors.secondary,
        fontSize: '15px',
        fontWeight: '600'
    },
    info: {
        fontSize: '15px'
    },
    makeOrderButton: {
        color: 'white',
        //border: `solid 1.5px ${colors.secondary}`,
        marginRight: '10px',
        backgroundColor: colors.secondary,
        '&:hover':{
            backgroundColor: alpha(colors.secondary, 0.9)
        }
    },
    confirmButton: {
        color: colors.secondary,
        border: `solid 1.5px ${colors.secondary}`,
        backgroundColor: 'white',
        marginRight: '10px',
        '&:hover':{
            backgroundColor: alpha(colors.secondary, 0.2)
        }
    },
    modalPaper: {
        position: 'absolute',
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        width: '60%',
        borderRadius: '10px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        [theme.breakpoints.down('xs')]: {
            width: '90%'
        }
    },
    inputHolder: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        marginTop:'10px',
        marginBottom: '10px',
        backgroundColor:'#ffffff', 
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);', 
        borderRadius: '3px',
        '&:hover': {
          boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
          backgroundColor: '#ffffff'
        },
        '&:focus': {
          boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
        }
    },
    inputRoot: {
        width: '100%',
    },
    inputInput: {
        height: 24,
        fontSize: '1rem',
        fontWeight: 400,
        // vertical padding + font size from searchIcon
        transition: theme.transitions.create('width'),
        width: '100%',
        paddingLeft: '5px',
        paddingRight: '5px',
        '&::placeholder': { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: 'rgba(0, 0, 0, 0.54)',
            opacity: 1 /* Firefox */
        },
        
        '&:-ms-input-placeholder': { /* Internet Explorer 10-11 */
            color: 'rgba(0, 0, 0, 0.54)'
        },
        
        '&::-ms-input-placeholder': { /* Microsoft Edge */
            color: 'rgba(0, 0, 0, 0.54)'
        },
    },
    errorButton: {
        color: colors.redError,
        padding: '3px',
        marginTop:'5px',
        "&:hover, &.Mui-focusVisible": { 
            backgroundColor: colors.redErrorOpacity,
        }
    },
    arrow: {
        fontSize: 20,
        color: "#4A4A4A",
        "&::before": {
            backgroundColor: colors.redError,
        }
    },
    mainText: {
        color: colors.secondary, 
        fontWeight: '600', 
        fontSize: '18px', 
        marginBottom: '10px'
    },
    hideSmall: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    showSmall: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'block'
        }
    },
    suggestionOption: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'lightgray',
        }
    },
    menuButton: {
        justifyContent: 'flex-start'
    },
    deleteButton: {
        borderRadius: '3px',
        border: `solid 1.5px ${colors.redError}`,
        backgroundColor: 'white',
        color: colors.redError,
        '&:hover': {
            backgroundColor: colors.redErrorOpacity
        }
    },
    makeDiscountButton: {
        marginRight: '10px',
        borderRadius: '3px',
        border: `solid 1.5px ${colors.orangeEdit}`,
        backgroundColor: 'white',
        color: colors.orangeEdit,
        '&:hover': {
            backgroundColor: alpha(colors.orangeEdit, 0.2)
        }
    },
    editButton: {
        marginRight: '10px',
        borderRadius: '3px',
        border: `solid 1.5px ${colors.blueLink}`,
        backgroundColor: 'white',
        color: colors.blueLink,
        '&:hover': {
            backgroundColor: alpha(colors.blueLink, 0.2)
        }
    }
}));


  function Row({order, isOpen, isImportAdmin, user}) {
    const classes = useStyles()
    const history = useHistory()
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [openDeleteSuccessSnack, setOpenDeleteSuccessSnack] = useState(false)
    const [openDeleteApiErrorSnack, setOpenDeleteApiErrorSnack] = useState(false)
    const [open, setOpen] = React.useState(isOpen);
    const [guideHouse, setGuideHouse] = useState(order.houseGuide !== undefined? order.houseGuide : '')
    const [AWB, setAWB] = useState(order.AWB !== undefined? order.AWB : '')
    const [boxesCount, setBoxesCount] = useState('1')
    const [orderProducts, setOrderProducts] = useState(order.products)
    const [orderGuideCut, setOrderGuideCut] = useState(order.guideCut !== undefined? order.guideCut : guideCut)
    const [orderPackaging, setOrderPackaging] = useState(order.packaging !== undefined? order.packaging : packaging)
    const [sendingCostDiscount, setSendingCostDiscount] = useState(order.sendingCostDiscount !== undefined? order.sendingCostDiscount: '0')
    const [sendingCostDiscountErrorOpen, setSendingCostDiscountErrorOpen] = useState(false)
    const [productsDiscount, setProductsDiscount] = useState([])
    const [productsDiscountErrorOpen, setProductsDiscountErrorOpen] = useState([false])
    const [guideHouseError, setGuideHouseError] = useState('')
    const [guideHouseErrorOpen, setGuideHouseErrorOpen] = useState(false)
    const [AWBError, setAWBError] = useState('')
    const [AWBErrorOpen, setAWBErrorOpen] = useState(false)
    const [boxesCountError, setBoxesCountError] = useState('')
    const [boxesCountErrorOpen, setBoxesCountErrorOpen] = useState(false)
    const [productsCount, setProductsCount] = useState(1)
    const [openModal, setOpenModal] = useState({open:false, makeDiscount: false})
    const [openEditModal, setOpenEditModal] = useState(false)
    const [currentStatus, setCurrentStatus] = useState(order.currentStatus)
    const [currentStatusDate, setCurrentStatusDate] = useState(order.currentStatusDate)
    const [orderGuideHouse, setOrderGuideHouse] = useState(order.houseGuide)
    const [orderSendingCostDiscount, setOrderSendingCostDiscount] = useState(order.sendingCostDiscount? order.sendingCostDiscount : '0')
    const [orderTotalAmount, setOrderTotalAmount] = useState(0)
    const [orderTotalCost, setOrderTotalCost] = useState(0)
    const [orderTotalProductsPrice, setOrderTotalProductsPrice] = useState(0)
    const [statuses, setStatuses] = useState(order.statuses)
    const [updatingStateOrder, setUpdatingStateOrder] = useState(false)
    const [updatingEditOrder, setUpdatingEditOrder] = useState(false)

    const [openApiErrorSnack, setOpenApiErrorSnack] = useState(false)
    const [openUnavailableErrorSnack, setOpenUnavailableErrorSnack] = useState(false)
    const [openUndeliveredErrorSnack, setOpenUndeliveredErrorSnack] = useState(false)
    const [openSuccessSnack, setOpenSuccessSnack] = useState(false)
    const [openEditSuccessSnack, setOpenEditSuccessSnack] = useState(false)

    const [discountOver350Enable, setDiscountOver350Enable] = useState(false)

    // const dispatch = useDispatch()
    const userLogged = useSelector((state) => state.user_store.user)

    useEffect(() => {
        calculateOrderValues()
    }, [])

    const calculateOrderValues = (newProductsConfirmed) => {
        let prodCount = 0 
        let totalAmount = orderGuideCut + orderPackaging
        let totalSendingCost = 0
        let totalProductsPrice = 0
        let products = order.products
        let newProductsDiscount = []
        let newProductsDiscountErrorOpen = []
        let fastDelivery = true
        if(newProductsConfirmed){
            products = newProductsConfirmed
            setOrderProducts(newProductsConfirmed)
        }
        else{
            if(statuses.length > 1){
                products = order.productsConfirmed
                setOrderProducts(order.productsConfirmed)
            }
        }
        products.forEach((element) => {
            let costValue = element.product.height * element.product.width * element.product.large / 6000
            if(costValue < element.product.weight){
                costValue = element.product.weight
            }
            let sendingCost = order.statuses.length > 1? order.sendingCost : sendingCostValue 
            costValue = costValue * sendingCost
            totalSendingCost += (costValue * element.count)
            const discount = element.product.discount !== undefined? element.product.discount : 0
            totalProductsPrice += ((element.product.price - discount) * element.count)
            prodCount += (1*element.count)
            newProductsDiscount.push(element.product.discount !== undefined? element.product.discount: '0')
            newProductsDiscountErrorOpen.push(false)
            if(!element.product.fastDelivery){
                fastDelivery = false
            }
        });
        if(fastDelivery){
            setOrderGuideCut(0)
            setOrderPackaging(0)
            totalAmount -= (orderGuideCut + orderPackaging)
        }
        else{
            if(totalProductsPrice + totalSendingCost + orderGuideCut + orderPackaging >= discountOverValue){
                setDiscountOver350Enable(true)
                if(orderGuideCut >= discountOverTotalPrice){
                    setOrderGuideCut(orderGuideCut - discountOverTotalPrice)
                    totalAmount -= (discountOverTotalPrice)
                } 
                else{
                    totalAmount -= orderGuideCut
                    setOrderGuideCut(0)
                }
            }
        }
        setProductsDiscount(newProductsDiscount)
        setProductsDiscountErrorOpen(newProductsDiscountErrorOpen)
        setProductsCount(prodCount)
        setOrderTotalAmount(totalAmount + totalSendingCost + totalProductsPrice)
        setOrderTotalCost(totalSendingCost)
        setOrderTotalProductsPrice(totalProductsPrice)
    }

    const handleCloseApiErrorSnack = () => {
        setOpenApiErrorSnack(false)
    }
    const handleCloseUnavailableErrorSnack = () => {
        setOpenUnavailableErrorSnack(false)
    }
    const handleCloseUndeliveredErrorSnack = () => {
        setOpenUndeliveredErrorSnack(false)
    }
    const handleCloseSuccessSnack = () => {
        setOpenSuccessSnack(false)
    }

    const handleCloseEditSuccessSnack = () => {
        setOpenEditSuccessSnack(false)
    }

    const getDate = (currentDate) => {
        const date = new Date(currentDate)
        return `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes() > 9? date.getMinutes() : '0'+date.getMinutes().toString()}`
    }

    const onClickConfirm = (orderId) => {
        if(isImportAdmin){
            setOpenModal({open:true, makeDiscount: false})
        }
        else{
            if(currentStatus === 'Solicitado'){
                const textMessage = `Hola mi pedido es: https://sevendeimport.com/orders/${user._id}/${orderId}`
                const whatsapUrl = `https://wa.me/${supportNumber}?text=${encodeURI(textMessage)}`
                window.open(whatsapUrl)
            }
            else{
                const textMessage = `Hola, me gustaria preguntarle sobre mi pedido: https://sevendeimport.com/orders/${user._id}/${orderId}`
                const whatsapUrl = `https://wa.me/${supportNumber}?text=${encodeURI(textMessage)}`
                window.open(whatsapUrl)
            }
        }
    }

    const getButtonText = () => {
        if(isImportAdmin){
            if(currentStatus === 'Solicitado') return 'Confirmar'
            if(currentStatus === 'Confirmado') return 'Embalado'
            if(currentStatus === 'Embalado') return 'Enviado'
            if(currentStatus === 'Enviado') return 'Recibido'
        }
        else{
            if(currentStatus === 'Solicitado') return 'Efectuar Pago'
            return 'PREGUNTAR'
        }
    }

    const handleClose = () => {
        setOpenModal({open:false, makeDiscount: false})
    }

    const handleCloseEditModal = () => {
        setOpenEditModal(false)
    }

    const getNextState = () => {
        if(currentStatus === 'Solicitado') return '"Confirmado"'
        if(currentStatus === 'Confirmado') return '"Embalado"'
        if(currentStatus === 'Embalado') return '"Enviado"'
        if(currentStatus === 'Enviado') return '"Recibido"'
    }

    const handleChangeGuideHouse = (event) => {
        handleSendingCostDiscountErrorTooltipClose()
        handleBoxesCountErrorTooltipClose()
        if(guideHouseError){
            setGuideHouseError("")
            handleGuideHouseErrorTooltipClose()
        }
        setGuideHouse(event.target.value);
    };

    const handleChangeAWB = (event) => {
        if(AWBError){
            setAWBError("")
            handleAWBErrorTooltipClose()
        }
        setAWB(event.target.value);
    };

    const handleChangeBoxesCount = (event) => {
        handleSendingCostDiscountErrorTooltipClose()
        handleGuideHouseErrorTooltipClose()
        if(boxesCountError){
            setBoxesCountError("")
            handleBoxesCountErrorTooltipClose()
        }
        setBoxesCount(event.target.value);
    };

    const handleChangeSendingCostDiscount = (event) => {
        handleBoxesCountErrorTooltipClose()
        handleGuideHouseErrorTooltipClose()
        if(sendingCostDiscountErrorOpen){
            handleSendingCostDiscountErrorTooltipClose()
        }
        setSendingCostDiscount(event.target.value);
    };

    const handleChangeProductsDiscount = (event, index) => {
        handleBoxesCountErrorTooltipClose()
        handleGuideHouseErrorTooltipClose()
        handleSendingCostDiscountErrorTooltipClose()
        if(productsDiscountErrorOpen[index]){
            let newOpen = [...productsDiscountErrorOpen]
            newOpen[index] = false
            setProductsDiscountErrorOpen(newOpen)
        }
        let newProductsDiscount = [...productsDiscount]
        newProductsDiscount[index] = event.target.value
        setProductsDiscount(newProductsDiscount);
    };

    const handleGuideHouseErrorTooltipClose = () => {
        setGuideHouseErrorOpen(false);
    };
    const handleGuideHouseErrorTooltipOpen = () => {
        setGuideHouseErrorOpen(true);
    };
    const handleAWBErrorTooltipClose = () => {
        setAWBErrorOpen(false);
    };
    const handleAWBErrorTooltipOpen = () => {
        setAWBErrorOpen(true);
    };
    const handleBoxesCountErrorTooltipClose = () => {
        setBoxesCountErrorOpen(false);
    };
    const handleBoxesCountErrorTooltipOpen = () => {
        setBoxesCountErrorOpen(true);
    };
    const handleSendingCostDiscountErrorTooltipClose = () => {
        setSendingCostDiscountErrorOpen(false);
    };
    const handleSendingCostDiscountErrorTooltipOpen = () => {
        setSendingCostDiscountErrorOpen(true);
    };

    const getBodyState = () => {
        if(currentStatus === 'Solicitado'){
            if(discountOver350Enable){
                return {_id: order._id, history: statuses, newStatus: 'Confirmado', sendingCostValue: sendingCostValue, guideCut: orderGuideCut, packaging: orderPackaging, discountOverTotalPrice: discountOverTotalPrice, discountOverTotalPriceMessage: discountOverTotalPriceMessage}
            }
            else{
                return {_id: order._id, history: statuses, newStatus: 'Confirmado', sendingCostValue: sendingCostValue, guideCut: orderGuideCut, packaging: orderPackaging}
            }
        }
        if(currentStatus === 'Confirmado') return {_id: order._id, history: statuses, newStatus: 'Embalado', guideHouse: parseInt(guideHouse), sendingCostValue: sendingCostValue, boxesCount: parseInt(boxesCount)}
        if(currentStatus === 'Embalado') return {_id: order._id, history: statuses, newStatus: 'Enviado'}
        if(currentStatus === 'Enviado') return {_id: order._id, history: statuses, newStatus: 'Recibido'}
    }

    const onClickNextState = async () => {
        if(currentStatus === "Solicitado" && !openModal.makeDiscount){
            for (let index = 0; index < orderProducts.length; index++) {
                const element = orderProducts[index]
                if(element.trackStatus?.currentStatus === "No Disponible" || element.trackStatus?.currentStatus === "Chequeando Disponibilidad"){
                    setOpenUnavailableErrorSnack(true)
                    return
                }
            }
        }
        if(currentStatus === "Enviado" && !openModal.makeDiscount){
            for (let index = 0; index < orderProducts.length; index++) {
                const element = orderProducts[index]
                if(element.trackStatus !== undefined){
                    if(element.trackStatus?.currentStatus !== "Recibido"){
                        setOpenUndeliveredErrorSnack(true)
                        return
                    }
                }
            }
        }
        if(currentStatus === 'Confirmado' && !openModal.makeDiscount){
            if(!guideHouse){
                setGuideHouseError("Debe introducir una Guía House válida.")
                setGuideHouseErrorOpen(true)
                return;
            }
            else{
                if(!parseInt(guideHouse)){
                    setGuideHouseError("La Guía House debe ser un número.")
                    setGuideHouseErrorOpen(true)
                    return
                }
            }
            if(!boxesCount){
                setBoxesCountError("Debe introducir una cantidad de bultos válida.")
                setBoxesCountErrorOpen(true)
                return;
            }
            else{
                if(!parseInt(boxesCount)){
                    setBoxesCountError("La Cantidad de Bultos debe ser un número.")
                    setBoxesCountErrorOpen(true)
                    return
                }
            }
        }
        if(openModal.makeDiscount){
            if(!sendingCostDiscount && sendingCostDiscount !== 0){
                setSendingCostDiscountErrorOpen(true)
                return 
            }
            for (let index = 0; index < productsDiscount.length; index++) {
                const element = productsDiscount[index]
                if(!element && element !== 0){
                    let newOpen = [...productsDiscountErrorOpen]
                    newOpen[index] = true
                    setProductsDiscountErrorOpen(newOpen)
                    return 
                }   
            }
        }
        setUpdatingStateOrder(true)
        let newProductsConfirmed = []
        if(openModal.makeDiscount){
            newProductsConfirmed = [...orderProducts]
            for (let index = 0; index < newProductsConfirmed.length; index++) {
                newProductsConfirmed[index].product.discount = parseInt(productsDiscount[index])
            }
        }
        let body = openModal.makeDiscount? 
        { 
            _id: order._id, 
            sendingCostDiscount: sendingCostDiscount? parseFloat(sendingCostDiscount) : 0,
            currentStatus: currentStatus,
            confirmedMonth: order.confirmedMonth,
            confirmedYear: order.confirmedYear,
            productsConfirmed: newProductsConfirmed
        } 
        : 
        getBodyState()
        const requestOptions = {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json',
                        'Authorization': `${userLogged.accessToken}`
            },
            body: JSON.stringify(
                body  
            )
        };
        let result = await fetch(`${url}/api/${openModal.makeDiscount? 'orders/makeDiscount' : 'orders'}`, requestOptions);
        if(result.status === 200){
            if(newProductsConfirmed.length > 0){
                calculateOrderValues(newProductsConfirmed)
            }
            setUpdatingStateOrder(false)
            setOpenSuccessSnack(true)
            if(openModal.makeDiscount){
                setOrderSendingCostDiscount(body.sendingCostDiscount)
            }
            else{
                const newDate = Date.now()
                setCurrentStatus(body.newStatus)
                setCurrentStatusDate(newDate)
                if(body.guideHouse){
                    setOrderGuideHouse(body.guideHouse)
                }
                if(body.boxesCount){
                    setBoxesCount(body.boxesCount)
                }
                let newStatuses = [...statuses]
                newStatuses.push({status: body.newStatus, date: newDate})
                setStatuses(newStatuses)
            }
            setOpenModal({open:false, makeDiscount: false})
        }
        else{
            setUpdatingStateOrder(false)
            setOpenApiErrorSnack(true)
        }
    }

    const onClickEditOrder = async () => {
        if(!guideHouse){
            setGuideHouseError("Debe introducir una Guía House válida.")
            setGuideHouseErrorOpen(true)
            return;
        }
        else{
            if(!parseInt(guideHouse)){
                setGuideHouseError("La Guía House debe ser un número.")
                setGuideHouseErrorOpen(true)
                return
            }
        }
        if(!AWB){
            setAWBError("Debe introducir un AWB válida.")
            setAWBErrorOpen(true)
            return;
        }
        setUpdatingEditOrder(true)
        const requestOptions = {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json',
                        'Authorization': `${userLogged.accessToken}`
            },
            body: JSON.stringify(
                {
                    orderId: order._id,
                    guideHouse: parseInt(guideHouse),
                    AWB: AWB
                }  
            )
        };
        let result = await fetch(`${url}/api/orders/editOrder`, requestOptions);
        if(result.status === 200){
            setUpdatingEditOrder(false)
            setOpenEditSuccessSnack(true)
            setOrderGuideHouse(guideHouse)
            setOpenEditModal(false)
        }
        else{
            setUpdatingEditOrder(false)
            setOpenApiErrorSnack(true)
        }
    }

    

    const getCurrentStatusColor = () => {
        if(currentStatus === 'Solicitado') return colors.requestedDarkKhaki
        if(currentStatus === 'Confirmado') return colors.confirmedDarkCyan
        if(currentStatus === 'Embalado') return colors.packagingDarkMagenta
        if(currentStatus === 'Enviado') return colors.sendedDarkSeaGreen
        if(currentStatus === 'Recibido') return colors.recieveDarkGreen
    }

    const getCurrentStatusProductColor = (product) => {
        if(product.trackStatus){
            if(product.trackStatus.currentStatus === 'Chequeando Disponibilidad') return colors.requestedDarkKhaki
            if(product.trackStatus.currentStatus === 'Disponible') return colors.confirmedDarkCyan
            if(product.trackStatus.currentStatus === 'No Disponible') return colors.redError
            if(product.trackStatus.currentStatus === 'Confirmado') return colors.secondary
            if(product.trackStatus.currentStatus === 'Recibido') return colors.recieveDarkGreen
        }
    }

    const getStatusButton = () => {
        let style={}
        if(user.isImportAdmin){
            if(currentStatus === 'Solicitado') return {backgroundColor: colors.requestedDarkKhaki}
            if(currentStatus === 'Confirmado') return {backgroundColor: colors.confirmedDarkCyan}
            if(currentStatus === 'Embalado') return {backgroundColor: colors.packagingDarkMagenta}
            if(currentStatus === 'Enviado') return {backgroundColor: colors.sendedDarkSeaGreen}
            if(currentStatus === 'Recibido') return {backgroundColor: colors.recieveDarkGreen}
        }
        return style
    }

    const calculateOriginalPrice = (price, percent) => {
        const originalPrice = (price*100) /  (percent + 100)
        return reduceDecimalValue(originalPrice)
    }

    const calculatePrice = (price, discount, count) => {
        const disc = discount !== undefined? discount: 0
        return reduceDecimalValue((price - disc) * count)
    }

    const getDiscount = (discount) => {
        if(discount !== undefined){
            if(discount !== 0){
                return `${discount} usd`
            }
        } 
    }

    const reduceDecimalValue = (number) => {
        if(Math.floor(number.valueOf()) === number.valueOf()) return number;
        const hasMoreDecimal = number.toString().split(".")[1].length || 0
        if(hasMoreDecimal > 2){
            return number.toFixed(2)
        }
        return number 
    }
    
    const calcCostValue = (product) => {
        let costValue = product.height * product.width * product.large / 6000
        if(costValue < product.weight){
            costValue = product.weight
        }
        let sendingCost = order.statuses.length > 1? order.sendingCost : sendingCostValue 
        costValue = costValue * sendingCost
        return costValue
    }

    const OpenDeleteSuccessSnack = () => {
        setOpenDeleteModal(false)
        setOpenDeleteSuccessSnack(true)
    }
    const OpenDeleteApiErrorSnack = () => {
        setOpenDeleteApiErrorSnack(true)
    }
    const handleCloseDeleteSuccessSnack = () => {
        setOpenDeleteSuccessSnack(false)
        window.location.reload(false)
    }
    const handleCloseDeleteApiErrorSnack = () => {
        setOpenDeleteApiErrorSnack(false)
    }
    const closeDeleteModal = () => {
        setOpenDeleteModal(false)
    }
    const toProductPage = (productId) => {
        history.push(`/products/detail/${productId}`);
    }

    
  
    return (
      <React.Fragment>
        <Snackbar open={openApiErrorSnack} autoHideDuration={2000} onClose={handleCloseApiErrorSnack}>
            <Alert onClose={handleCloseApiErrorSnack} severity="error">
                No se pudo cambiar el estado. Revise su conexión a Internet.
            </Alert>
        </Snackbar>
        <Snackbar open={openUnavailableErrorSnack} autoHideDuration={3000} onClose={handleCloseUnavailableErrorSnack}>
            <Alert onClose={handleCloseUnavailableErrorSnack} severity="error">
                Hay productos de entrega rapida no disponibles o esperando a chequear disponibilidad.
            </Alert>
        </Snackbar>
        <Snackbar open={openUndeliveredErrorSnack} autoHideDuration={3000} onClose={handleCloseUndeliveredErrorSnack}>
            <Alert onClose={handleCloseUndeliveredErrorSnack} severity="error">
                Hay productos de entrega rapida que no se han entregado.
            </Alert>
        </Snackbar>
        <Snackbar open={openSuccessSnack} autoHideDuration={2000} onClose={handleCloseSuccessSnack}>
            <Alert onClose={handleCloseSuccessSnack} severity="success">
                Estado cambiado correctamente.
            </Alert>
        </Snackbar>
        <Snackbar open={openEditSuccessSnack} autoHideDuration={2000} onClose={handleCloseEditSuccessSnack}>
            <Alert onClose={handleCloseEditSuccessSnack} severity="success">
                Orden Editada correctamente.
            </Alert>
        </Snackbar>
        <Snackbar open={openDeleteSuccessSnack} autoHideDuration={1000} onClose={handleCloseDeleteSuccessSnack}>
            <Alert onClose={handleCloseDeleteSuccessSnack} severity="success">
                Se eliminó la orden.
            </Alert>
        </Snackbar>
        <Snackbar open={openDeleteApiErrorSnack} autoHideDuration={2000} onClose={handleCloseDeleteApiErrorSnack}>
            <Alert onClose={handleCloseDeleteApiErrorSnack} severity="error">
                No se pudo eliminar orden. Revise su conexión a Internet.
            </Alert>
        </Snackbar>
        <Modal
            open={openModal.open}
            onClose={handleClose}
            aria-labelledby="next order state"
            aria-describedby="data necessary to process to next state"
        >
            <div className={classes.modalPaper}>
                <Typography className={classes.mainText}>{openModal.makeDiscount? 'Hacer Descuento' : 'Siguiente Estado'}</Typography>
                <Typography>Seguro desea {openModal.makeDiscount? 'Hacer un Descuento' : `pasar la orden al estado: ${getNextState()}`}?</Typography>
                {currentStatus === 'Confirmado' && !openModal.makeDiscount &&
                <React.Fragment>
                <Typography style={{marginTop: '7px'}}>Guia House</Typography>
                <div className={classes.inputHolder}>
                    <InputBase
                        onClick={() => 
                            {
                                setGuideHouseError('')
                            }}
                        elevation={4}
                        placeholder="########"
                        value={guideHouse}
                        onChange={handleChangeGuideHouse}
                        type='number'
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{ 'aria-label': 'search', maxLength:20 }}
                    />
                    {guideHouseError &&
                    <div>
                        <MuiThemeProvider theme={themeMUI}>
                            <Tooltip classes={{arrow: classes.arrow}}
                                arrow
                                PopperProps={{
                                disablePortal: true,
                                }}
                                onClose={handleGuideHouseErrorTooltipClose}
                                open={guideHouseErrorOpen}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={guideHouseError}
                            >
                                <IconButton onClick={handleGuideHouseErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                    <WarningAmberIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </MuiThemeProvider>
                    </div>                       
                    }
                </div>
                <Typography style={{marginTop: '7px'}}>Cantidad de Bultos</Typography>
                <div className={classes.inputHolder}>
                    <InputBase
                        onClick={() => 
                            {
                                setBoxesCountError('')
                            }}
                        elevation={4}
                        placeholder="Cantidad de Bultos"
                        value={boxesCount}
                        onChange={handleChangeBoxesCount}
                        type='number'
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{ 'aria-label': 'search', maxLength:2 }}
                    />
                    {boxesCountError &&
                    <div>
                        <MuiThemeProvider theme={themeMUI}>
                            <Tooltip classes={{arrow: classes.arrow}}
                                arrow
                                PopperProps={{
                                disablePortal: true,
                                }}
                                onClose={handleBoxesCountErrorTooltipClose}
                                open={boxesCountErrorOpen}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={boxesCountError}
                            >
                                <IconButton onClick={handleBoxesCountErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                    <WarningAmberIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </MuiThemeProvider>
                    </div>                       
                    }
                </div>
                </React.Fragment>
                }
                {openModal.makeDiscount &&
                <React.Fragment>
                <div style={{maxHeight: '350px', overflowY: 'scroll', marginTop: '10px'}}>
                <div style={{marginTop: '20px'}}>
                    <Typography>Costo de Envio</Typography>
                    <div className={classes.inputHolder}>
                        <InputBase
                            elevation={4}
                            placeholder="Descuento de Envio"
                            value={sendingCostDiscount}
                            onClick={() => 
                                {
                                    setSendingCostDiscountErrorOpen(false)
                                }}
                            onChange={handleChangeSendingCostDiscount}
                            type='number'
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search', maxLength:20 }}
                        />
                        {sendingCostDiscountErrorOpen &&
                        <div>
                            <MuiThemeProvider theme={themeMUI}>
                                <Tooltip classes={{arrow: classes.arrow}}
                                    arrow
                                    PopperProps={{
                                    disablePortal: true,
                                    }}
                                    onClose={handleSendingCostDiscountErrorTooltipClose}
                                    open={sendingCostDiscountErrorOpen}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={"El descuento debe ser un numero"}
                                >
                                    <IconButton onClick={handleSendingCostDiscountErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                        <WarningAmberIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </MuiThemeProvider>
                        </div>                       
                        }
                    </div>
                </div>
                {orderProducts.map((element, index) => {
                    return(
                        <div style={{marginTop: '20px'}}>
                            <Typography>{element.product.name}</Typography>
                            <div style={{display: 'flex'}}>
                                <Typography>Precio Original: {calculateOriginalPrice(element.product.price, element.product.percent)} usd</Typography>
                                <Typography style={{marginLeft: '20px'}}>Cant: {element.count}</Typography>
                            </div>
                            <div className={classes.inputHolder}>
                                <InputBase
                                    elevation={4}
                                    placeholder="Descuento de Producto"
                                    value={productsDiscount[index]}
                                    onClick={() => 
                                        {
                                            let newDiscountOpen = [...productsDiscountErrorOpen]
                                            newDiscountOpen[index] = false
                                            setProductsDiscountErrorOpen(newDiscountOpen)
                                        }}
                                    onChange={(event) => {handleChangeProductsDiscount(event, index)}}
                                    type='number'
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ 'aria-label': 'search', maxLength:20 }}
                                />
                                {productsDiscountErrorOpen[index] &&
                                <div>
                                    <MuiThemeProvider theme={themeMUI}>
                                        <Tooltip classes={{arrow: classes.arrow}}
                                            arrow
                                            PopperProps={{
                                            disablePortal: true,
                                            }}
                                            onClose={
                                                () => {
                                                    let newOpen = [...productsDiscountErrorOpen]
                                                    newOpen[index] = false
                                                    setProductsDiscountErrorOpen(newOpen)
                                                }
                                            }
                                            open={productsDiscountErrorOpen[index]}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            title={"El descuento debe ser un numero"}
                                        >
                                            <IconButton 
                                                onClick={
                                                    () => {
                                                        let newOpen = [...productsDiscountErrorOpen]
                                                        newOpen[index] = true
                                                        setProductsDiscountErrorOpen(newOpen)
                                                    }
                                                }  
                                                aria-label="warning" className={classes.errorButton}>
                                                <WarningAmberIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </MuiThemeProvider>
                                </div>                       
                                }
                            </div>
                        </div>
                    )
                })
                }
                </div>
                </React.Fragment>
                }
                <div style={{display:'flex', marginTop: '20px', justifyContent: 'flex-end'}}>
                    <Button variant='contained' onClick={() => {setOpenModal({open:false, makeDiscount:false})}} className={classes.confirmButton}>No</Button>
                    {updatingStateOrder?
                        <Button
                        className={classes.makeOrderButton} style={{width: '118px'}}
                        classes={{
                            label: classes.label, // class name, e.g. `classes-nesting-label-x`
                        }}
                        ><CircularProgress size={24} style={{color: 'white'}}/></Button>
                    :
                        <Button variant='contained' onClick={onClickNextState} className={classes.makeOrderButton}>Confirmar</Button>
                    }
                </div>
            </div>
        </Modal>
        <Modal
            open={openEditModal}
            onClose={handleCloseEditModal}
            aria-labelledby="edit order AWB and Guide House"
            aria-describedby="data necessary to process to next state"
        >
            <div className={classes.modalPaper}>
                <Typography className={classes.mainText}>Editar Orden #{order.orderNumber} de {order.user.name + ' ' + order.user.lastName}</Typography>
                <React.Fragment>
                <Typography style={{marginTop: '7px'}}>AWB</Typography>
                <div className={classes.inputHolder}>
                    <InputBase
                        onClick={() => 
                            {
                                setAWBError('')
                            }}
                        elevation={4}
                        placeholder="###-#######"
                        value={AWB}
                        onChange={handleChangeAWB}
                        type='text'
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{ 'aria-label': 'search', maxLength:20 }}
                    />
                    {AWBError &&
                    <div>
                        <MuiThemeProvider theme={themeMUI}>
                            <Tooltip classes={{arrow: classes.arrow}}
                                arrow
                                PopperProps={{
                                disablePortal: true,
                                }}
                                onClose={handleAWBErrorTooltipClose}
                                open={AWBErrorOpen}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={AWBError}
                            >
                                <IconButton onClick={handleAWBErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                    <WarningAmberIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </MuiThemeProvider>
                    </div>                       
                    }
                </div>
                <Typography style={{marginTop: '7px'}}>GUIA HOUSE</Typography>
                <div className={classes.inputHolder}>
                    <InputBase
                        onClick={() => 
                            {
                                setGuideHouseError('')
                            }}
                        elevation={4}
                        placeholder="########"
                        value={guideHouse}
                        onChange={handleChangeGuideHouse}
                        type='number'
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{ 'aria-label': 'search', maxLength:20 }}
                    />
                    {guideHouseError &&
                    <div>
                        <MuiThemeProvider theme={themeMUI}>
                            <Tooltip classes={{arrow: classes.arrow}}
                                arrow
                                PopperProps={{
                                disablePortal: true,
                                }}
                                onClose={handleGuideHouseErrorTooltipClose}
                                open={guideHouseErrorOpen}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={guideHouseError}
                            >
                                <IconButton onClick={handleGuideHouseErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                    <WarningAmberIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </MuiThemeProvider>
                    </div>                       
                    }
                </div>
                </React.Fragment>
                <div style={{display:'flex', marginTop: '20px', justifyContent: 'flex-end'}}>
                    <Button variant='contained' onClick={() => {setOpenEditModal(false)}} className={classes.confirmButton}>No</Button>
                    {updatingEditOrder?
                        <Button
                        className={classes.makeOrderButton} style={{width: '118px'}}
                        classes={{
                            label: classes.label, // class name, e.g. `classes-nesting-label-x`
                        }}
                        ><CircularProgress size={24} style={{color: 'white'}}/></Button>
                    :
                        <Button variant='contained' onClick={onClickEditOrder} className={classes.makeOrderButton}>Confirmar</Button>
                    }
                </div>
            </div>
        </Modal>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell style={{padding: '4px'}}>
            <div>
                <Typography style={userLogged.isImportAdmin? {opacity: '0.6', textAlign: 'center', fontSize: '14px'} : {opacity: '0.6', fontSize: '14px', paddingLeft: '5px'}}>#{order.orderNumber}</Typography>
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                >   
                    {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
            </div>
          </TableCell>
          <TableCell style={{padding: '8px'}} component="th" scope="row">
            <span style={{color: getCurrentStatusColor()}}>{currentStatus}</span><br />{getDate(currentStatusDate)}
          </TableCell>
          {userLogged.isImportAdmin &&
            <TableCell style={{padding: '8px'}}>{order.user?.name? order.user.name + ' ' + order.user.lastName : '--'}</TableCell>
          }
          <TableCell style={{padding: '8px'}}><span style={{color: colors.secondary}}>{reduceDecimalValue(orderTotalAmount - orderSendingCostDiscount)} USD</span><br />{productsCount} Producto{order.products.length > 1? 's' : ''}</TableCell>
          <TableCell className={classes.hideSmall} style={{padding: '8px'}}>{orderGuideHouse}</TableCell>
          <TableCell style={{padding: '8px'}}>
            {currentStatus !== 'Recibido' &&
            <div style={{display: 'flex'}}>
            <Button  variant='contained' onClick={() => {onClickConfirm(order._id)}} 
                className={(currentStatus === 'Solicitado' || isImportAdmin)? classes.makeOrderButton : classes.confirmButton}
                style={getStatusButton()}
            >
                {getButtonText()}
            </Button>
            {user.isSuperAdmin && (currentStatus === 'Confirmado' || currentStatus === 'Embalado') &&
                <Button  variant='contained' onClick={() => {setOpenModal({open: true, makeDiscount: true})}} 
                className={classes.makeDiscountButton}
                >
                    Hacer Descuento
                 </Button>
            }
            {user.isSuperAdmin && (currentStatus === 'Embalado' || currentStatus === "Enviado" || currentStatus === "Confirmado") &&
                <Button  variant='contained' onClick={() => {setOpenEditModal(true)}} 
                className={classes.editButton}
                >
                    Editar AWB
                 </Button>
            }
            {(user.isSuperAdmin || (user._id === order.user._id && currentStatus === 'Solicitado')) &&
                <React.Fragment>
                    <DeleteOrderModal openDeleteModal={openDeleteModal} orderId={order._id} setOpenSuccesSnack={OpenDeleteSuccessSnack} setOpenApiErrorSnack={OpenDeleteApiErrorSnack} closeDeleteModalParent={closeDeleteModal}/>
                    <Button  variant='contained' onClick={() => {setOpenDeleteModal(true)}} 
                    className={classes.deleteButton}
                    >
                        ELIMINAR
                    </Button>
                </React.Fragment>
            }
            </div>
            }
            </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                {statuses.length > 1 &&
                <React.Fragment>
                <Typography variant="h6" gutterBottom component="div">
                  Historial
                </Typography>
                <Table style={{marginBottom: '20px'}} size="small" aria-label="purchases">
                    <TableBody>
                        {statuses.map((status, index) => {
                            return(
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {status.status} {getDate(status.date)}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                  </TableBody>
                </Table>
                </React.Fragment>
                }
                <div style={{display: 'flex', width: '100%'}}>
                    <Typography variant="h6" gutterBottom component="div">
                        Info
                    </Typography>
                    
                    {user.isImportAdmin &&
                    <div>
                    <Suspense 
                        fallback={
                            <Button className={classes.confirmButton} style={{marginLeft: '7px', height: '25px', marginBottom: '5px'}}>
                                Cargando Corte de Guia...
                            </Button>
                        }
                    >
                        <PdfDocumentLoader documentRequested={0} order={order} orderGuideHouse={orderGuideHouse}/>
                    </Suspense>
                    </div>
                    }
                    {currentStatus !== 'Solicitado' &&
                    <div style={ user.isImportAdmin? {display: 'block'} : {display: 'flex'}}>
                    <Suspense 
                        fallback={
                            <Button className={classes.confirmButton} style={{marginLeft: '7px', height: '25px', marginBottom: '5px'}}>
                                Cargando Ticket De Compra...
                            </Button>
                        }
                    >
                        <PdfDocumentLoader documentRequested={1} order={order} products={orderProducts}/>
                    </Suspense>
                    </div>
                    }
                    {user.isImportAdmin &&
                    <div>
                    <Suspense 
                        fallback={
                            <Button className={classes.confirmButton} style={{marginLeft: '7px', height: '25px', marginBottom: '5px'}}>
                                Cargando Lista de Compra...
                            </Button>
                        }
                    >
                        <PdfDocumentLoader documentRequested={2} order={order} orderGuideHouse={orderGuideHouse}  products={orderProducts}/>
                    </Suspense>
                    </div>
                    }
                </div>
                <Typography className={classes.showSmall} style={{marginBottom: '10px'}}><span style={{color: colors.secondary}}>Guía House:</span> {orderGuideHouse? orderGuideHouse : '-'}</Typography>
                {user.isImportAdmin && 
                    <Typography style={{marginBottom: '10px'}}>
                        <span style={{color: colors.secondary}}>Usuario:</span> <span onClick={() => {history.push(`/orders/${order.user?._id}`)}} style={{cursor: 'pointer'}}>{order.user?.name + ' ' + order.user?.lastName}</span>&nbsp;&nbsp; 
                        <span onClick={() => {history.push(`/orders/${order.user?._id}`)}} style={{cursor: 'pointer', fontSize: '14px'}}>({order.user?.email})</span>
                        <a href={`https://wa.me/${order.user?.phone}`} target='_blank' style={{cursor: 'pointer', fontSize: '14px', textDecoration: 'underline', marginLeft: '15px'}}>({order.user?.phone})</a>
                    </Typography>
                }
                <Table style={{marginBottom: '20px'}} size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.header}>Remitente</TableCell>
                      <TableCell  className={classes.header}>Destinatario</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                      <TableRow key={1}>
                        <TableCell component="th" scope="row">
                          {order.sender?.name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {order.addressee?.name}
                        </TableCell>
                      </TableRow>
                      <TableRow key={2}>
                        <TableCell component="th" scope="row">
                          {order.sender?.phone}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {order.addressee?.phone}
                        </TableCell>
                      </TableRow>
                      <TableRow key={3}>
                        <TableCell component="th" scope="row">
                          {order.sender?.ci}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {order.addressee?.ci}
                        </TableCell>
                      </TableRow>
                      <TableRow key={4}>
                        <TableCell component="th" scope="row">
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {order.addressee?.address}
                        </TableCell>
                      </TableRow>
                  </TableBody>
                </Table>

                <Typography variant="h6" gutterBottom component="div">
                  Productos ({productsCount})
                </Typography>
                <Table  style={{marginBottom: '60px'}} size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                        {user.isImportAdmin &&
                            <TableCell style={{padding: '6px 2px', maxWidth: '40px', width: '40px'}} className={classes.header}>Foto</TableCell>
                        }
                        <TableCell className={classes.header}>Nombre</TableCell>
                        {user.isImportAdmin &&
                            <TableCell className={classes.header}>Distribuidor</TableCell>
                        }
                        <TableCell className={classes.header}>Cantidad</TableCell>
                        <TableCell className={classes.header}>Precio(U)</TableCell>
                        {user.isImportAdmin &&
                            <TableCell className={classes.header}>Descuento</TableCell>   
                        }
                        <TableCell className={classes.header}>Costo Envio(U)</TableCell>
                        <TableCell className={classes.header}>Precio Total</TableCell>
                        <TableCell className={classes.header}>Costo Env. Total</TableCell>
                        <TableCell className={classes.header}>Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderProducts.map((product) => (
                      <TableRow hover key={product.product._id}>
                        {user.isImportAdmin &&
                            <TableCell onClick={() => {toProductPage(product.product._id)}} style={{padding: '0px', maxWidth: '40px', width: '40px', cursor: userLogged.isImportAdmin? 'pointer' : 'context-menu'}}><img style={{borderRadius: '3px', height: '30px', width: '30px'}} src={product.product.mainImageUrl}/></TableCell>
                        }
                        <TableCell style={{cursor: userLogged.isImportAdmin? 'pointer' : 'context-menu'}} onClick={() => {toProductPage(product.product._id)}} component="th" scope="row">
                          {product.product.name}
                          {product.product.fastDelivery &&
                            <div>
                                <div style={{display: 'flex'}}>
                                    <img src={b2_truck} style={{height: '12px', width: 'auto', marginTop: '4px'}}/><Typography style={{fontSize: '12px'}}>Entrega Rapida</Typography>
                                </div>
                                {(userLogged.isImportAdmin || product.trackStatus?.currentStatus === "Recibido") &&
                                    <Typography style={{fontSize: '12px', color: getCurrentStatusProductColor(product)}}>({product.trackStatus?.currentStatus}){product.trackStatus?.message? `:${product.trackStatus.message}` : ""}</Typography>
                                }
                            </div>
                          }
                        </TableCell>
                        {user.isImportAdmin &&
                            <TableCell component="th" scope="row">{product.product?.fastDelivery? (product.product.user?.name + ' ' + product.product.user?.lastName) : product.product.distributor?.name}</TableCell>
                        }
                            <TableCell component="th" scope="row">{product.count}</TableCell>
                            <TableCell component="th" scope="row">{calculatePrice(product.product.price, product.product.discount, 1)} usd</TableCell>
                            {user.isImportAdmin &&
                                <TableCell component="th" scope="row">{getDiscount(product.product.discount)}</TableCell>  
                            }
                            <TableCell component="th" scope="row">{reduceDecimalValue(calcCostValue(product.product))} usd</TableCell>
                            <TableCell component="th" scope="row">{calculatePrice(product.product.price, product.product.discount, product.count)} usd</TableCell>
                            <TableCell component="th" scope="row">{reduceDecimalValue(calcCostValue(product.product)*product.count)} usd</TableCell>
                            <TableCell component="th" scope="row">{reduceDecimalValue((product.product.price + calcCostValue(product.product))*product.count)} usd</TableCell>
                        </TableRow>
                    ))}
                    <TableRow hover key={1}>
                        {user.isImportAdmin &&
                            <TableCell></TableCell>
                        }
                        <TableCell component="th" scope="row">
                         {orderTotalAmount + orderPackaging + orderGuideCut >= discountOverValue? 'Embalaje' : 'Corte de Guia + Embalaje'}
                        </TableCell>
                        <TableCell component="th" scope="row">---</TableCell>
                        <React.Fragment>
                            <TableCell component="th" scope="row">---</TableCell>
                            {user.isImportAdmin &&
                                <TableCell component="th" scope="row">---</TableCell>
                            }
                            <TableCell component="th" scope="row">---</TableCell>
                            <TableCell component="th" scope="row">---</TableCell>
                            {user.isImportAdmin &&
                                <TableCell component="th" scope="row">---</TableCell>
                            }
                            <TableCell component="th" scope="row">{orderGuideCut + orderPackaging} usd</TableCell>
                            <TableCell component="th" scope="row">{orderGuideCut + orderPackaging} usd</TableCell>
                        </React.Fragment>
                    </TableRow>
                    {orderSendingCostDiscount > 0 &&
                    <TableRow hover key={2}>
                        {user.isImportAdmin &&
                            <TableCell></TableCell>
                        }
                        <TableCell component="th" scope="row">
                         Descuento De Costo de Envio
                        </TableCell>
                        <TableCell component="th" scope="row">---</TableCell>
                        <React.Fragment>
                            <TableCell component="th" scope="row">---</TableCell>
                            {user.isImportAdmin &&
                                <TableCell component="th" scope="row">---</TableCell>
                            }
                            <TableCell component="th" scope="row">---</TableCell>
                            <TableCell component="th" scope="row">---</TableCell>
                            {user.isImportAdmin &&
                            <TableCell component="th" scope="row">---</TableCell>
                            }
                            <TableCell component="th" scope="row">-{orderSendingCostDiscount} usd</TableCell>
                            <TableCell component="th" scope="row">-{orderSendingCostDiscount} usd</TableCell>
                        </React.Fragment>
                    </TableRow>
                    }
                    {order.walletPayment > 0 &&
                    <TableRow hover key={3}>
                        {user.isImportAdmin &&
                            <TableCell></TableCell>
                        }
                        <TableCell component="th" scope="row">
                         Uso de Saldo de mi Cartera
                        </TableCell>
                        <TableCell component="th" scope="row">---</TableCell>
                        <React.Fragment>
                            <TableCell component="th" scope="row">---</TableCell>
                            {user.isImportAdmin &&
                                <TableCell component="th" scope="row">---</TableCell>
                            }
                            <TableCell component="th" scope="row">---</TableCell>
                            <TableCell component="th" scope="row">---</TableCell>
                            {user.isImportAdmin &&
                                <TableCell component="th" scope="row">-{order.walletPayment} usd</TableCell>
                            }
                            <TableCell component="th" scope="row">---</TableCell>
                            <TableCell component="th" scope="row">-{order.walletPayment} usd</TableCell>
                        </React.Fragment>
                    </TableRow>
                    }
                    <TableRow hover key={4}>
                        {user.isImportAdmin &&
                            <TableCell></TableCell>
                        }
                        <TableCell component="th" scope="row">
                         
                        </TableCell>
                        <TableCell component="th" scope="row"></TableCell>
                        <TableCell component="th" scope="row"></TableCell>
                        {user.isImportAdmin &&
                            <TableCell component="th" scope="row"></TableCell>
                        }
                        {user.isImportAdmin &&
                            <TableCell component="th" scope="row"></TableCell>
                        }
                        <TableCell component="th" align="right" scope="row">TOTAL:</TableCell>
                        <TableCell component="th" scope="row" style={{borderTop: 'solid 2px darkgray'}}>{reduceDecimalValue(orderTotalProductsPrice - order.walletPayment)} usd</TableCell>
                        <TableCell component="th" scope="row" style={{borderTop: 'solid 2px darkgray'}}>{reduceDecimalValue(orderTotalCost - orderSendingCostDiscount + orderGuideCut + orderPackaging)} usd</TableCell>
                        <TableCell component="th" scope="row" style={{borderTop: 'solid 2px darkgray'}}>{reduceDecimalValue(orderTotalAmount - orderSendingCostDiscount - order.walletPayment)} usd</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

export default function OrdersPage(){
    const history = useHistory()
    const search = useLocation().search
    const queryParams = new URLSearchParams(search)
    const classes = useStyles();
    const [loadingOrders, setLoadingOrders] = useState(true)
    const [orders, setOrders] = useState([])
    const [totalOrders, setTotalOrders] = useState(0)
    const [user, setUser] = useState()
    const {id, orderId} = useParams();
    const [openApiErrorSnack, setOpenApiErrorSnack] = useState(false)
    const [openDeniedRequestSnack, setOpenDeniedRequestSnack] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [guideHouse, setGuideHouse] = useState('')
    const [searchParameters, setSearchParameters] = useState({
        page: queryParams.has('page')? parseInt(queryParams.get('page')) : 0,
        limit: 20,
        statusToSearch: 'Todos',
        guideHouseToSearch: '',
        userId: ''
    })
    const [loadingUsers, setLoadingUsers] = useState(false)
    const [username, setUsername] = useState('')
    const [usernameValue, setUsernameValue] = useState('')
    const [userSuggestions, setUserSuggestions] = useState([])
    const [statusToSearch, setStatusToSearch] = useState('Todos')
    const [anchorElStatuses, setAnchorElStatuses] = useState(null);
    const [showAllOrders, setShowAllOrders] = useState(false)
    const openStatuses = Boolean(anchorElStatuses);

    const rows = [0,1,2,3,4,5,6,7,8,9];
    const userLogged = useSelector((state) => state.user_store.user)

    useEffect(() => {
        const fetchOrders = async () => {
            try{
                setLoadingOrders(true)
                if(userLogged){
                    if(id){
                        if(!userLogged.isImportAdmin && id !== userLogged._id){
                            setOpenDeniedRequestSnack(true)
                            return
                        }
                        if(userLogged.isImportAdmin){
                            setShowAllOrders(true)
                        }
                    }
                    if(!user){
                        setUser(userLogged)
                    }
                    let extraParams = ""
                    if(searchParameters.statusToSearch !== 'Todos'){
                        extraParams += `&status=${searchParameters.statusToSearch}`
                    }
                    if(searchParameters.guideHouseToSearch){
                        extraParams += `&guideHouse=${searchParameters.guideHouseToSearch}`
                    }
                    if(searchParameters.userId){
                        extraParams += `&userId=${searchParameters.userId}`
                    }
                    let urlRequest = `/api/orders/ofuser/${userLogged._id}/?page=${searchParameters.page+1}&limit=${searchParameters.limit}${extraParams}` 
                    if(userLogged.isImportAdmin){
                        urlRequest = id? `/api/orders/ofuser/${id}/?page=${searchParameters.page+1}&limit=${searchParameters.limit}${extraParams}`  : `/api/orders/?page=${searchParameters.page+1}&limit=${searchParameters.limit}${extraParams}`
                    }
                    const requestOptions = {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `${userLogged.accessToken}` }
                    };
                    let result = await fetch(`${url}${urlRequest}`, requestOptions)
                    if(result.status === 200){
                        let dataResult = await result.json()
                        setTotalOrders(dataResult.count)
                        setOrders(dataResult.orders)
                        setLoadingOrders(false)
                    }
                    else{
                        setOpenApiErrorSnack(true)
                    }
                }
                
            } catch(error){
                setOpenApiErrorSnack(true)
            }
        }
        fetchOrders()
    }, [searchParameters])

    useEffect(() => {
        const abort = {current: false}
        const fetchUsers = async () => {
            try{
                if(username?.length <= 2){
                    setLoadingUsers(false)
                    setUserSuggestions([])
                    return;
                }
                if(!abort.current){
                    setLoadingUsers(true)
                }
                if(userLogged){
                    let urlRequest = `/api/accountImport/usersuggest/?page=${1}&limit=${searchParameters.limit}&userToSearch=${username}`
                    const requestOptions = {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `${userLogged.accessToken}` }
                    };
                    let result = await fetch(`${url}${urlRequest}`, requestOptions)
                    if(result.status === 200){
                        if(!abort.current){
                            let dataResult = await result.json()
                            setUserSuggestions(dataResult)
                            setLoadingUsers(false)
                        }
                    }
                    else{
                        if(!abort.current){
                            setLoadingUsers(false)
                        }
                    }
                }
                
            } catch(error){
                console.log(error)
                setLoadingUsers(false)
            }
        }
        fetchUsers()
        return(
            abort.current = false
        )
    }, [username])

    const onCLickAllOrders = () => {
        history.push('/orders')
    }

    const handleChangeGuideHouse = (event) => {
        setGuideHouse(event.target.value)
    }

    const handleCloseApiErrorSnack = () => {
        setOpenApiErrorSnack(false)
    }

    const handleCloseDeniedRequestSnack = () => {
        setOpenDeniedRequestSnack(false)
    }

    const handleChangePage = (event, newPage) => {
        setSearchParameters(prevState => ({
                ...prevState,
                page: newPage
        }))
        let queryParams = {}
        queryParams["page"] = newPage
        var params = new URLSearchParams(queryParams)
        history.replace({
            search: params.toString(),
        })
      };

    const getInfoText = () => {
        if(userLogged){
            if(user && id && userLogged.isImportAdmin && orders?.length > 0){
                return `Órdenes de ${orders[0]?.user.name} ${orders[0]?.user.lastName}`
            }
            else{
                if(userLogged.isImportAdmin){
                    return 'Todas las Órdenes'
                }
            }
        }
        return "INFO: Presione en EFECTUAR PAGO para contactar vía WhatsApp a nuestro asesor de ventas y comenzar el proceso de su envío!"
    }

    const handleClose = () => {
        setOpenModal(false)
    }
    const handleClickStatuses = (event) => {
        setAnchorElStatuses(event.currentTarget);
    };
    const handleCloseStatuses = (element) => {
        setAnchorElStatuses(null);
        if (element !== null){
            setStatusToSearch(element)    
        }
    };

    const onClickConfirmFilter = () => {
        setSearchParameters(prevState => ({
            ...prevState,
            page: 0,
            statusToSearch: statusToSearch,
            guideHouseToSearch: guideHouse,
            userId: usernameValue?._id? usernameValue._id : ''
        }))
        setOpenModal(false)
    }

    const filterOptions = (options, state) => {
        let newOptions = [];
        options.forEach((element) => {
            newOptions.push(element)
        });
        return newOptions;
    };

    const getPages = () => {
        if(totalOrders === 0){
            return 1
        }
        if(totalOrders % searchParameters.limit === 0) return totalOrders / searchParameters.limit
        return Math.trunc(totalOrders/searchParameters.limit)+1
    }

    if(openApiErrorSnack){
        return(
            <React.Fragment>
                <Navbar />
                <NoConnection />
            </React.Fragment>
        )
    }
    else{
        return(
            <React.Fragment>
                <Navbar />
                <Snackbar open={openDeniedRequestSnack} autoHideDuration={10000} onClose={handleCloseDeniedRequestSnack}>
                    <Alert onClose={handleCloseDeniedRequestSnack} severity="error">
                        No tiene acceso a estos pedidos.
                    </Alert>
                </Snackbar>
                <Snackbar open={openApiErrorSnack} autoHideDuration={2000} onClose={handleCloseApiErrorSnack}>
                    <Alert onClose={handleCloseApiErrorSnack} severity="error">
                        No se pudo cargar. Revise su conexión a Internet.
                    </Alert>
                </Snackbar>
                <Modal
                    open={openModal}
                    onClose={handleClose}
                    aria-labelledby="filter states"
                    aria-describedby="data necessary to filter states"
                >
                    <div className={classes.modalPaper}>
                        <Typography className={classes.mainText}>Filtrar por:</Typography>
                        <Typography>Estado</Typography>
                        <Button
                            variant="contained"
                            aria-label="more"
                            aria-controls="long-menu-drawer"
                            aria-haspopup="true"
                            className={classes.inputHolder}
                            onClick={handleClickStatuses}
                            endIcon={<ArrowDropDown />}
                            classes={{
                                label: classes.menuButton,
                            }}
                        >{statusToSearch}</Button>
                        <Menu
                            id="long-menu-drawer"
                            anchorEl={anchorElStatuses}
                            keepMounted
                            open={openStatuses}
                            onClose={() => handleCloseStatuses(null)}
                            //PaperProps={{}
                        >
                            {['Todos','Solicitado', 'Confirmado', 'Embalado', 'Enviado', 'Recibido'].map((element) => (
                                <MenuItem key={element} onClick={() => handleCloseStatuses(element)}>
                                    {element}
                                </MenuItem>
                            ))}
                        </Menu>
                        <Typography>Guía House</Typography>
                        <div className={classes.inputHolder}>
                            <InputBase
                                elevation={4}
                                placeholder="--"
                                value={guideHouse}
                                onChange={handleChangeGuideHouse}
                                type='number'
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search', maxLength:20 }}
                            />
                        </div>
                        {!id &&
                        <React.Fragment>
                        <Typography style={{marginTop: '15px'}}>Usuario</Typography>
                        <div className={classes.inputHolder}>
                        <Autocomplete
                            id="combo-box-demo"
                            options={userSuggestions}
                            inputValue={username}
                            value={usernameValue}
                            onChange={(event, newValue) => {
                                setUsernameValue(newValue)
                            }}
                            noOptionsText='Sin opciones'
                            onInputChange={(event, newValue) => {
                                setUsername(newValue)
                            }}
                            getOptionLabel={(option) => option? `${option.name} ${option.lastName}` : ''}
                            style={{ width: '100%' }}
                            loading={loadingUsers}
                            filterOptions={filterOptions}
                            renderOption={(option) => (
                                <React.Fragment>
                                <Typography>{`${option.name} ${option.lastName}`}</Typography>
                                </React.Fragment>
                            )}
                            renderInput={(params) => 
                                <TextField 
                                    {...params} 
                                    label="--" 
                                    variant="outlined" 
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                        <React.Fragment>
                                            {loadingUsers ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                        ),
                                    }}
                                />
                            }
                        /> 
                        </div>
                        </React.Fragment>
                        }
                        <div style={{display:'flex', marginTop: '20px', justifyContent: 'flex-end'}}>
                            <Button variant='contained' onClick={onClickConfirmFilter} className={classes.confirmButton}>Aplicar</Button>
                        </div>
                        
                    </div>
                </Modal>
                {loadingOrders?
                <TableContainer className={classes.orderCard} component={Paper}>
                    <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                        <TableCell style={{padding: '0px'}}></TableCell>
                        <TableCell style={{padding: '8px'}}>Estado</TableCell>
                        {userLogged.isImportAdmin &&
                            <TableCell style={{padding: '8px'}}>Usuario</TableCell>
                        }
                        <TableCell style={{padding: '8px'}}>Total</TableCell>
                        <TableCell className={classes.hideSmall} style={{padding: '8px'}}>Guía House</TableCell>
                        <TableCell style={{padding: '8px'}}>Operación</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row} sx={{ '& > *': { borderBottom: 'unset' } }}>
                                <TableCell>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <Skeleton style={{width: '60px', height: '20px'}} />
                                </TableCell>
                                <TableCell>
                                    <Skeleton style={{width: '60px', height: '20px'}} />
                                </TableCell>
                                <TableCell>
                                    <Skeleton style={{width: '60px', height: '20px'}} />
                                </TableCell>
                                <TableCell>
                                    <Skeleton style={{width: '60px', height: '20px'}} />
                            </TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
                    </Table>
                </TableContainer>
                :
                <React.Fragment>
                <div className={classes.orderInfoHolder} style={{display: 'flex', alignItems: 'center'}}>
                <Typography className={classes.orderInfo}>
                    {getInfoText()}
                </Typography>
                {showAllOrders &&
                    <Button className={classes.confirmButton} style={{marginLeft:'auto'}} onClick={onCLickAllOrders}>Todas las Órdenes</Button>
                }
                </div>
                <TableContainer className={classes.orderCard} component={Paper}>
                    <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                        <TableCell style={{padding: '0px'}}>{user.isImportAdmin && <IconButton onClick={() => {setOpenModal(true)}} style={{padding: '10px', color: 'black'}}><BsFillFunnelFill style={{heigth: '18px', width: '18px'}}/></IconButton>}</TableCell>
                        <TableCell style={{padding: '8px'}}>Estado</TableCell>
                        {userLogged.isImportAdmin &&
                            <TableCell style={{padding: '8px'}}>Usuario</TableCell>
                        }
                        <TableCell style={{padding: '8px'}}>Total</TableCell>
                        <TableCell className={classes.hideSmall} style={{padding: '8px'}}>Guía House</TableCell>
                        <TableCell style={{padding: '8px'}}>Operación</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orders.map((order) => (
                            <Row key={order._id} order={order} isOpen={(orderId && orderId === order._id)? true : false} isImportAdmin={user.isImportAdmin} user={user}/>
                        ))}
                    </TableBody>
                    </Table>
                    <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
                            <IconButton
                                disabled={searchParameters.page === 0}
                                onClick={(event) => {
                                        if(searchParameters.page !== 0){
                                            handleChangePage(event, searchParameters.page - 1)
                                        }
                                    }
                                }
                            >
                                <ArrowForwardIos style={{transform: 'rotate(180deg)'}} />
                            </IconButton>
                            <Typography style={{margin: '0px 5px', paddingTop: '11px'}}>{searchParameters.page * searchParameters.limit !== 0? searchParameters.page * searchParameters.limit: 1}-{(searchParameters.page + 1) * searchParameters.limit > totalOrders? totalOrders: (searchParameters.page + 1) * searchParameters.limit}/{totalOrders}</Typography>
                            <Typography style={{margin: '0px 5px', paddingTop: '11px'}}>Pág: {searchParameters.page + 1}/{totalOrders !== 0? getPages() : 1}</Typography>
                            <IconButton
                                disabled={totalOrders === 0 || Math.trunc(totalOrders/searchParameters.limit) === searchParameters.page}
                                onClick={(event) => {
                                        handleChangePage(event, searchParameters.page + 1)
                                    }
                                }
                            >
                                <ArrowForwardIos />
                            </IconButton>
                        </div>
                </TableContainer>
            </React.Fragment>
                }
            </React.Fragment>
        )
    }
}