import { UPDATE_PRODUCTS, UPDATE_SEARCH_PARAMS } from "../actions/productsAction";

const default_cart = {
    products: [],
    page: 0,
    reachFinalProduct: false,
    searchParameters:{
        selected: "0",
        selectedProvince: "Todas las Provincias",
        searchPhrase: '',
        minPrice: '',
        maxPrice: '',
        page: 1,
        statusToSearch: ["Nuevo", "Como Nuevo", "Poco uso", "Usado"],
        selectedCurrency: "TODAS",
        onBackPressed: false
    }
}

function products_store(state = default_cart, action){
    switch(action.type) {
        case UPDATE_PRODUCTS: {
            return{
                ...state,
                products: action.payload.products,
                reachFinalProduct: action.payload.reachFinalProduct,
                page: action.payload.page
            }
        }
        case UPDATE_SEARCH_PARAMS: {
            return{
                ...state,
                searchParameters: action.payload
            }
        }
        default: return state;
    }
}

export default products_store