import { UPDATE_USER, REMOVE_USER } from "../actions/userAction";

const set_default_user = () => {
    const isUserLogged = window.localStorage.getItem('user')
    if(isUserLogged)
    { 
        try{
            const user = JSON.parse(isUserLogged)
            return {
                ...user
            }
        }
        catch(e){
            console.log(e)
        }
    }
    else{
        return null
    }
}

const default_user = {
    user: set_default_user()
}

function user_store(state = default_user, action){
    switch(action.type) {
        case UPDATE_USER: {
            return{
                user : {...action.payload}
            }
        }
        case REMOVE_USER: {
            return{
                user: null
            }
        }
        default: return state;
    }
}

export default user_store