import React, { useRef } from 'react'
import { createTheme } from '@material-ui/core/styles';
import { alpha, Button, Card, CircularProgress, FormControl, IconButton, InputBase, makeStyles, MenuItem, Modal, MuiThemeProvider, Select, Snackbar, Tooltip, Typography } from '@material-ui/core'
import url, { colors, sendingCostValue, guideCut, packaging, provinces, municipalities, discountOverTotalPrice, discountOverValue } from '../../config'
import { useDispatch, useSelector } from 'react-redux'
import Navbar from '../global/navbar'
import { useEffect } from 'react'
import { useState } from 'react'
import { Cancel, CreditCard, LocationOn, Person, RemoveShoppingCart } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { restart_cart_action, update_cart_action } from '../../redux/actions/cartAction'
import WarningAmberIcon from '@material-ui/icons/PriorityHigh'
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import b2_truck from '../../assets/promotional_banner/b2_truck.png'

const themeMUI = createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          color: "white",
          backgroundColor: "rgba(251,82,100,0.85)",
          borderTop: 'solid 2px rgb(251,82,100)'
        }
      }
    }
  });

const useStyles = makeStyles((theme) => ({
    extraBar: {
        height: '65px',
        width: '100%',
        backgroundColor: colors.primary
    },
    cartHolder: {
        position: 'absolute',
        top: '65px',
        width: '70%',
        margin: '5px 15% 30px 15%',
        zIndex: '101',
        [theme.breakpoints.down('md')]: {
            margin: '5px 10% 30px 10%',
            width: '80%',
        },
        [theme.breakpoints.down('sm')]:{
            top: '100px',
            margin: '5px 2.5% 30px 2.5%',
            width: '95%',
        }
    },
    cardContainer: {
        margin: '10px 20px',
    },
    informativeCartNote: {
        marginBottom: '15px',
        fontSize: '13px',
        textAlign: 'center',
        opacity: '0.8'
    },
    cartContainer: {
        display: 'flex',
        padding: '0px 5%',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]:{
            flexDirection: 'column',
            padding: '0px 5px'
        }
    },
    miscellanyContainer: {
        flex: 1,
    },
    miscellanyItems: {
        position: 'relative',
        //borderRight: `solid 1.5px ${colors.primary}`,
        //minHeight: '280px',
        marginBottom: '5px',
        [theme.breakpoints.down('sm')]:{
            borderRight: `solid 0px ${colors.primary}`,
        }
    },
    otherProductsContainer: {
        flex: 1
    }, 
    otherProductsItems: {
    },
    cartTypeText: {
        fontSize: '18px',
        marginLeft: '10px',
        fontWeight: '600',
        marginBottom: '15px',
        //textAlign: 'center',
        color: colors.secondary
    }, 
    emptyCartContainer: {
        paddingTop: '80px',
        paddingBottom: '130px', 
        marginBottom: '30px'
    },
    emptyCartIcon: {
        height: '80px',
        width: '80px',
        margin: '0px auto 10px auto',
        display: 'block',
        color: colors.secondary,
    }, 
    emptyCartPrimaryText:{
        textAlign: 'center',
        fontWeight: '600',
    },
    emptyCartSecondaryText: {
        textAlign: 'center',
        fontSize: '14px'
    },
    elementHolder: {
        display: 'flex',
        margin: '10px 0px 20px 0px'
    },
    elementImage: {
        height: '60px',
        width: '60px',
        borderRadius: '3px',
        marginRight: '6px'
    },
    elementInfo: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 'auto',
        justifyContent: 'center',
    },
    elementText: {
        fontSize: '14px',
        fontWeight: '600' 
    }, 
    elementPrice: {
        fontSize: '14px',
        fontWeight: '600',
        color: colors.secondary 
    }, 
    elementTotal: {
        display: 'flex',
        paddingBottom: '10px',
        justifyContent: 'flex-end',
        paddingTop: '5px',
        width: 'calc(100%)',
        borderTop: `solid 1.5px ${colors.primary}`
    },
    elementHeader: {
        textAlign: 'end',
        fontSize: '13px',
        fontWeight: '600',
        height: '20px'
    },
    elementUnit: {
        textAlign: 'end',
        paddingLeft: '5px',
        fontSize: '13px',
        fontWeight: '600',
        color: colors.secondary,
        height: '20px'
    },
    elementSecondaryInfoHeader: {
        fontSize: '12px',
        fontWeight: '600',
        opacity: '0.8'
    },
    elementSecondaryInfoUnit: {
        marginLeft: '3px',
        fontSize: '12px',
        fontWeight: '600',
        opacity: '0.8'
    },
    minusCart: { 
        border: `solid 1.9px ${colors.greenCart}`,
        marginLeft: 'auto',
        borderRadius: '10px',
        width: '35px',
        borderRight: `solid 1px ${colors.greenCart}`,
        borderTopRightRadius: '0px',
        borderBottomRightRadius: '0px',
        fontSize: '18px',
        textAlign: 'center',
        lineHeight: '17px',
        fontWeight: '800',
        color: colors.greenCart,
        cursor: 'pointer',
        userSelect: 'none',
        '&:hover': {
            backgroundColor: alpha(colors.greenCart, 0.2)
        }
    },
    sumCart: {
        border: `solid 1.9px ${colors.greenCart}`,
        marginRight: 'auto',
        borderRadius: '10px',
        width: '35px',
        borderLeft: `solid 1px ${colors.greenCart}`,
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
        fontSize: '18px',
        textAlign: 'center',
        lineHeight: '20px',
        fontWeight: '800',
        userSelect: 'none',
        color: colors.greenCart,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: alpha(colors.greenCart, 0.2)
        }
    },
    cartCount: {
        borderTop: `solid 1.9px ${colors.greenCart}`,
        borderBottom: `solid 1.9px ${colors.greenCart}`,
        fontSize: '14px',
        paddingLeft: '8px',
        paddingRight: '8px',
        textAlign: 'center',
        lineHeight: '22px',
        fontWeight: '600',
    },
    makeOrderButton: {
        height: '35px',
        color: 'white',
        //border: `solid 1.5px ${colors.secondary}`,
        marginTop: '10px',
        marginBottom: '20px',
        marginLeft: 'auto',
        backgroundColor: colors.secondary,
        '&:hover':{
            backgroundColor: alpha(colors.secondary, 0.9)
        }
    },
    confirmButton: {
        height: '35px',
        color: colors.secondary,
        border: `solid 1.5px ${colors.secondary}`,
        marginTop: '10px',
        marginBottom: '2px',
        backgroundColor: 'white',
        '&:hover':{
            backgroundColor: alpha(colors.secondary, 0.2)
        }
    },
    countAndPriceHolder: {
        marginLeft: '10px', 
        display: 'flex',  
        alignItems: 'center',
        [theme.breakpoints.down('sm')]:{
            flexDirection: 'column',
        }
    },
    inputHolder: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        marginTop:'5px',
        marginBottom: '10px',
        backgroundColor:'#ffffff', 
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);', 
        borderRadius: '3px',
        '&:hover': {
          boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
        },
        '&:focus': {
          boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
        }
    },
    inputHolderFlex: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    inputRoot: {
        width: '100%',
    },
    inputInput: {
        height: 24,
        fontSize: '1rem',
        fontWeight: 400,
        // vertical padding + font size from searchIcon
        transition: theme.transitions.create('width'),
        width: '100%',
        paddingLeft: '5px',
        paddingRight: '5px',
        '&::placeholder': { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: 'rgba(0, 0, 0, 0.54)',
            opacity: 1 /* Firefox */
        },
        
        '&:-ms-input-placeholder': { /* Internet Explorer 10-11 */
            color: 'rgba(0, 0, 0, 0.54)'
        },
        
        '&::-ms-input-placeholder': { /* Microsoft Edge */
            color: 'rgba(0, 0, 0, 0.54)'
        },
    },
    modalPaper: {
        position: 'absolute',
        top: `50%`,
        left: `50%`,
        width: '400px',
        maxHeight: '90%',
        maxWidth: '95%',
        overflowY: 'scroll',
        transform: `translate(-50%, -50%)`,
        borderRadius: '10px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    cancelButton: {
        display: 'block',
        marginLeft: 'auto',
        padding: '6px'
    },
    errorButton: {
        color: colors.redError,
        padding: '3px',
        marginTop:'5px',
        "&:hover, &.Mui-focusVisible": { 
            backgroundColor: colors.redErrorOpacity,
        }
    },
    arrow: {
        fontSize: 20,
        color: "#4A4A4A",
        "&::before": {
            backgroundColor: colors.redError,
        }
    },
    dataSelect: {
        paddingLeft: '10px',
    },
    formControl: {
        flex: 1
    },
    dividerLongSize: {
        width: '10px',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    colorIcon: {
        color: colors.primary,
        padding:'0px',
        paddingLeft: '6px'
    },
}))

const CartPage = () => {
    const classes = useStyles()
    const history = useHistory()
    const [totalOthersPrice, setTotalOthersPrice] = useState(0)
    const [otherProducts, setOtherProducts] = useState([])

    const [cartText, setCartText] = useState('')
    const [openCartSnack, setOpenCartSnack] = useState(false)
    const [openOtherInProcessSnack, setOpenOtherInProcessSnack] = useState(false)
    const [openOtherSuccessSnack, setOpenOtherSuccessSnack] = useState(false)
    const [openRedirectingSnack, setOpenRedirectingSnack] = useState(false)
    const [openApiErrorSnack, setOpenApiErrorSnack] = useState(false)
    const [openCartErrorSnack, setOpenCartErrorSnack] = useState(false)
    const [updatingCartIndexMinus, setUpdatingCartIndexMinus] = useState(-1)
    const [updatingCartIndexAdd, setUpdatingCartIndexAdd] = useState(-1)

    const [wallet, setWallet] = useState(0)

    const [open, setOpen] = useState(false)
    const [remName, setRemName] = useState("")
    const [remNameErrorOpen, setRemNameErrorOpen] = useState(false)
    const [remNameError, setRemNameError] = useState("")

    const [remPhone, setRemPhone] = useState("")
    const [remPhoneErrorOpen, setRemPhoneErrorOpen] = useState(false)
    const [remPhoneError, setRemPhoneError] = useState("")

    const [remCI, setRemCI] = useState("")
    const [remCIErrorOpen, setRemCIErrorOpen] = useState(false)
    const [remCIError, setRemCIError] = useState("")

    const [adrName, setAdrName] = useState("")
    const [adrNameErrorOpen, setAdrNameErrorOpen] = useState(false)
    const [adrNameError, setAdrNameError] = useState("")

    const [adrAddress, setAdrAddress] = useState("")
    const [adrAddressErrorOpen, setAdrAddressErrorOpen] = useState(false)
    const [adrAddressError, setAdrAddressError] = useState("")

    const [adrRegionSelected, setAdrRegionSelected] = useState("La Habana")
    const [adrRegionSelectedErrorOpen, setAdrRegionSelectedErrorOpen] = useState(false)
    const [adrRegionSelectedError, setAdrRegionSelectedError] = useState("")

    const [adrMunicipalityIndex, setAdrMunicipalityIndex] = useState(9)
    const [adrMunicipalitySelected, setAdrMunicipalitySelected] = useState("Municipio")
    const [adrMunicipalitySelectedErrorOpen, setAdrMunicipalitySelectedErrorOpen] = useState(false)
    const [adrMunicipalitySelectedError, setAdrMunicipalitySelectedError] = useState("")

    const [adrPhone, setAdrPhone] = useState("")
    const [adrPhoneErrorOpen, setAdrPhoneErrorOpen] = useState(false)
    const [adrPhoneError, setAdrPhoneError] = useState("")

    const [adrCI, setAdrCI] = useState("")
    const [adrCIErrorOpen, setAdrCIErrorOpen] = useState(false)
    const [adrCIError, setAdrCIError] = useState("")

    const [confirmingOrder, setConfirmingOrder] = useState(false)

    const [fastDeliveryAmount, setFastDeliveryAmount] = useState(0)


    const cart_store_selector = useSelector((state)=> state.cart_store)
    const user = useSelector((state) => state.user_store.user)

    const reduceDecimalValue = (number) => {
        //console.log(number)
        if(Math.floor(number.valueOf()) === number.valueOf()) return number;
        const hasMoreDecimal = number.toString().split(".")[1].length || 0
        if(hasMoreDecimal > 2){
            return number.toFixed(2)
        }
        return number 
    }

    const calculateCost = (product) => {
        let weightCost = 0
        let otherCost = 0
        otherCost = parseInt(product.large) * parseInt(product.height) * parseInt(product.width) / 6000
        weightCost = (parseFloat(product.weight))
        if(weightCost >= otherCost){
            return weightCost * sendingCostValue
        }
        return otherCost * sendingCostValue
    }

    useEffect(() => {
        const updateWallet = async () => {
            try{
                if(user){
                    const requestOptions = {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `${user.accessToken}` }
                    };
                    let result = await fetch(`${url}/api/accountImport/${user._id}`, requestOptions)
                    if(result.status === 200){
                        let dataResult = await result.json()
                        setWallet(dataResult.wallet? dataResult.wallet : 0)
                    }
                }
                
            } catch(error){
                setWallet(0);
            }
        }
        updateWallet()
    },[])

    useEffect(() => {
        const updateProducts = () => {
            if(user)
            { 
                setRemName(user.name)
                setRemPhone(user.phone)
            }
            let fastDelivery = 0
            
            if(cart_store_selector.products?.length > 0){
                const othProducts = cart_store_selector.products
                if(othProducts.length > 0) {
                    let totalOthersP = 0
                    othProducts.forEach(element => {
                        totalOthersP += ((element.product.price+calculateCost(element.product))*element.count)
                        if(!element.product.fastDelivery){
                            fastDelivery = guideCut + packaging
                        }
                    });
                    setTotalOthersPrice(totalOthersP)
                    if(totalOthersP + guideCut + packaging >= discountOverValue){
                        if(fastDelivery >= discountOverTotalPrice){
                            setFastDeliveryAmount(fastDelivery - discountOverTotalPrice)
                        }
                        else{
                            setFastDeliveryAmount(0)
                        }
                    }
                    else{
                        setFastDeliveryAmount(fastDelivery)
                    }
                    setOtherProducts(othProducts)
                }
            }
            else{
                setTotalOthersPrice(0)
                setOtherProducts([])
            }
        }
        updateProducts()
    },[cart_store_selector.products])

    const dispatch = useDispatch()

    const handleCloseCartSnack = () => {
        setOpenCartSnack(false)
    }
    const handleCloseCartErrorSnack = () => {
        setOpenCartErrorSnack(false)
    }
    const handleCloseOtherInProcessSnack = () => {
        setOpenOtherInProcessSnack(false)
    }
    const handleCloseOtherSuccessSnack = () => {
        setOpenOtherSuccessSnack(false)
    }
    const handleCloseRedirectingSnack = () => {
        setConfirmingOrder(false)
        setOpenRedirectingSnack(false)
        history.push('/orders')
    }
    const handleCloseApiErrorSnack = () => {
        setOpenApiErrorSnack(false)
    }

    const elementCard = (element, index) => {
        return(
            <div className={classes.elementHolder}>
                <img alt="" className={classes.elementImage} src={element.product.mainImageUrl}/>
                <div className={classes.elementInfo}>
                    <Typography className={classes.elementText}>{element.product.name}</Typography>
                    {element.product.fastDelivery && 
                        <div style={{display: 'flex'}}>
                            <img src={b2_truck} style={{height: '12px', width: 'auto', marginTop: '4px'}}/><Typography style={{fontSize: '12px'}}>Entrega Rapida</Typography>
                        </div>
                    }
                    <Typography className={classes.elementPrice}>{reduceDecimalValue(element.product.price)} usd + {reduceDecimalValue(calculateCost(element.product)) === 0? "(Envío Gratis)" : `${reduceDecimalValue(calculateCost(element.product))} usd (envío)`}</Typography>
                    <div style={{display: 'flex'}}>
                        <div>
                            <div style={{display: 'flex'}}>
                                <Typography className={classes.elementSecondaryInfoHeader}>Ptos Unit:</Typography>
                                <Typography className={classes.elementSecondaryInfoUnit}>{`${reduceDecimalValue(element.product.points)} pts`}</Typography>
                            </div>
                            {/* <div style={{display: 'flex'}}>
                                <Typography className={classes.elementSecondaryInfoHeader}>Peso T:</Typography>
                                <Typography className={classes.elementSecondaryInfoUnit}>{element.product.weight*element.count} kg</Typography>
                            </div> */}
                        </div>
                        <div style={{display: 'flex', marginLeft: '10px'}}>
                            <Typography className={classes.elementSecondaryInfoHeader}>Cant. Max:</Typography>
                            <Typography className={classes.elementSecondaryInfoUnit}>{element.product.maxCount}</Typography>
                        </div>
                    </div>
                </div>
                <div className={classes.countAndPriceHolder}>
                    <div style={{display:'flex', marginBottom: '5px', marginTop: '5px', justifyContent: 'center'}}>
                        {updatingCartIndexMinus === index?
                            <div className={classes.minusCart}>
                                <CircularProgress style={{color: colors.greenCart, height: '14px', width: '14px', marginTop: '3px'}}/>
                            </div>
                            : 
                            <Typography onClick={() => {removeFromCart(index)}} className={classes.minusCart}>-</Typography>
                        }
                        <Typography className={classes.cartCount}>{element.count}</Typography>
                        {updatingCartIndexAdd === index?
                            <div className={classes.sumCart}>
                                <CircularProgress style={{color: colors.greenCart, height: '14px', width: '14px', marginTop: '3px'}}/>
                            </div>
                            :
                            <Typography onClick={() => {addToCart(index)}} className={classes.sumCart}>+</Typography>
                        }
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '15px'}}>
                        <Typography className={classes.elementHeader}>Total:</Typography>
                        <Typography className={classes.elementUnit} style={{whiteSpace: 'nowrap'}}>{reduceDecimalValue((element.product.price+calculateCost(element.product))*element.count)} usd</Typography>
                    </div>
                </div>
            </div>
        )
    }

    const updateCartProducts = async (quantity, product) => {
        if(user){
            try{
                let newCart = JSON.parse(JSON.stringify(otherProducts));
                for (let index = 0; index < newCart.length; index++) {
                    if(newCart[index].product._id === product._id){
                        if(newCart[index].count + quantity !== 0)
                        {
                            newCart[index].count += quantity
                        }
                        else{
                            newCart.splice(index,1)
                            let allFastDelivery = true
                            newCart.forEach(element => {
                                if(!element.product.fastDelivery){
                                    allFastDelivery = false
                                }
                            });
                            if(allFastDelivery)setFastDeliveryAmount(0)
                            else setFastDeliveryAmount(packaging+guideCut)
                        }
                        break;
                    }
                }
                let requestOptions = {
                    method: 'PATCH',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `${user.accessToken}`
                    },
                    body:JSON.stringify({ 
                        cart: newCart
                    })
                };
                let urlGetProduct = `${url}/api/accountImport/updateCart`
                let result = await fetch(urlGetProduct, requestOptions)
                if(result.status === 200){
                    return {
                        ok: true,
                        products: newCart
                    }
                }
                else{
                    return { 
                        ok: false,
                        products: []
                    }
                }
            }catch(error){
                console.log(error)
                return { 
                    ok: false,
                    products: []
                }
            }
        }
    }

    const addToCart = async (index) => {
        if(updatingCartIndexMinus !== -1 || updatingCartIndexAdd !== -1) return
        let products = otherProducts
        if(products[index].count >= products[index].product.maxCount){
            setCartText(`Este producto tiene un máximo de ${products[index].product.maxCount} por pedido. Haga su pedido actual para poder añadir más de este producto a un nuevo pedido.`)
            setOpenCartSnack(true)
            return
        }
        if(cart_store_selector.points + products[index].product.points > 200){
            setCartText(`No puede exceder los 200 puntos permitidos por pedido. Haga su pedido actual para poder añadir más de este producto a un nuevo pedido.`)
            setOpenCartSnack(true)
            return
        }
        setUpdatingCartIndexAdd(index)
        const newProducts = await updateCartProducts(1, products[index].product)
        if(newProducts.ok){
            const newWeight = cart_store_selector.weight+products[index].product.weight
            const newPoints = cart_store_selector.points+products[index].product.points
            dispatch(update_cart_action(1, newProducts.products, newWeight, newPoints))
        }
        else{
            setOpenCartErrorSnack(true)
        }
        setUpdatingCartIndexAdd(-1)
    }

    const removeFromCart = async (index) => {
        if(updatingCartIndexMinus !== -1 || updatingCartIndexAdd !== -1) return
        let products = otherProducts
        if(products[index] === 0){
            return
        }
        else{
            setUpdatingCartIndexMinus(index)
            const newProducts = await updateCartProducts(-1, products[index].product)
            if(newProducts.ok){
                const newWeight = cart_store_selector.weight-products[index].product.weight
                const newPoints = cart_store_selector.points-products[index].product.points
                dispatch(update_cart_action(-1, newProducts.products, newWeight, newPoints))
            }
            else{
                setOpenCartErrorSnack(true)
            }
            setUpdatingCartIndexMinus(-1)
        }
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleOpen = () => {
        if(cart_store_selector.points > 200){
            setCartText("Su carrito excede los 200 puntos")
            setOpenCartSnack(true)
            return
        }
        setOpen(true)
    }

    const handleTooltipErrors = (tooltipSelected) => {
        handleRemNameErrorTooltipClose()
        handleRemPhoneErrorTooltipClose()
        handleRemCIErrorTooltipClose()
        handleAdrNameErrorTooltipClose()
        handleAdrAddressErrorTooltipClose()
        handleAdrRegionErrorTooltipClose()
        handleAdrMunicipalityErrorTooltipClose()
        handleAdrCIErrorTooltipClose()
        handleAdrPhoneErrorTooltipClose()
        switch (tooltipSelected) {
          case 'remName':{
            if(remNameError) handleRemNameErrorTooltipOpen()
            break;
          }
          case 'remPhone':{
            if(remPhoneError) handleRemPhoneErrorTooltipOpen()
            break;
          }
          case 'remCI':{
            if(remCIError) handleRemCIErrorTooltipOpen()
            break;
          }
          case 'adrName':{
            if(adrNameError) handleAdrNameErrorTooltipOpen()
            break;
          }
          case 'adrAddress':{
            if(adrAddressError) handleAdrAddressErrorTooltipOpen()
            break;
          }
          case 'adrRegion':{

            break;
          }
          case 'adrMunicipality':{
            
            break;
          }
          case 'adrCI':{
            if(adrCIError) handleAdrCIErrorTooltipOpen()
            break;
          }
          default:{
            if(adrPhoneError) handleAdrPhoneErrorTooltipOpen()
            break;
          }
        }
    }

    const handleChangeRemName = (event) => {
        if(remNameError){
            setRemNameError("")
            handleRemNameErrorTooltipClose()
        }
        setRemName(event.target.value);
    };
    const handleChangeRemPhone = (event) => {
        if(remPhoneError){
            setRemPhoneError("")
            handleRemPhoneErrorTooltipClose()
        }
        setRemPhone(event.target.value);
    };
    const handleChangeRemCI = (event) => {
        if(remCIError){
            setRemCIError("")
            handleRemCIErrorTooltipClose()
        }
        setRemCI(event.target.value);
    };
    const handleChangeAdrName = (event) => {
        if(adrNameError){
            setAdrNameError("")
            handleAdrNameErrorTooltipClose()
        }
        setAdrName(event.target.value);
    };
    const handleChangeAdrAddress = (event) => {
        if(adrAddressError){
            setAdrAddressError("")
            handleAdrAddressErrorTooltipClose()
        }
        setAdrAddress(event.target.value);
    };
    const handleChangeAdrRegion = (event) => {
        handleTooltipErrors("adrRegion")
        setAdrMunicipalitySelected("Municipio")
        setAdrMunicipalityIndex(provinces.indexOf(event.target.value))
        setAdrRegionSelected(event.target.value);
    };
    const handleChangeAdrMunicipality = (event) => {
        handleTooltipErrors("adrMunicipality")
        setAdrMunicipalitySelected(event.target.value);
    };
    const handleChangeAdrPhone = (event) => {
        if(adrPhoneError){
            setAdrPhoneError("")
            handleAdrPhoneErrorTooltipClose()
        }
        setAdrPhone(event.target.value);
    };
    const handleChangeAdrCI = (event) => {
        if(adrCIError){
            setAdrCIError("")
            handleAdrCIErrorTooltipClose()
        }
        setAdrCI(event.target.value);
    };

    const handleRemNameErrorTooltipClose = () => {
        setRemNameErrorOpen(false);
    };
    const handleRemNameErrorTooltipOpen = () => {
        setRemNameErrorOpen(true);
    };
    const handleRemPhoneErrorTooltipClose = () => {
        setRemPhoneErrorOpen(false);
    };
    const handleRemPhoneErrorTooltipOpen = () => {
        setRemPhoneErrorOpen(true);
    };
    const handleRemCIErrorTooltipClose = () => {
        setRemCIErrorOpen(false);
    };
    const handleRemCIErrorTooltipOpen = () => {
        setRemCIErrorOpen(true);
    };
    const handleAdrNameErrorTooltipClose = () => {
        setAdrNameErrorOpen(false);
    };
    const handleAdrNameErrorTooltipOpen = () => {
        setAdrNameErrorOpen(true);
    };
    const handleAdrAddressErrorTooltipClose = () => {
        setAdrAddressErrorOpen(false);
    };
    const handleAdrAddressErrorTooltipOpen = () => {
        setAdrAddressErrorOpen(true);
    };
    const handleAdrRegionErrorTooltipClose = () => {
        setAdrRegionSelectedErrorOpen(false);
    };
    const handleAdrRegionErrorTooltipOpen = () => {
        setAdrRegionSelectedErrorOpen(true);
    };
    const handleAdrMunicipalityErrorTooltipClose = () => {
        setAdrMunicipalitySelectedErrorOpen(false);
    };
    const handleAdrMunicipalityErrorTooltipOpen = () => {
        setAdrMunicipalitySelectedErrorOpen(true);
    };
    const handleAdrPhoneErrorTooltipClose = () => {
        setAdrPhoneErrorOpen(false);
    };
    const handleAdrPhoneErrorTooltipOpen = () => {
        setAdrPhoneErrorOpen(true);
    };
    const handleAdrCIErrorTooltipClose = () => {
        setAdrCIErrorOpen(false);
    };
    const handleAdrCIErrorTooltipOpen = () => {
        setAdrCIErrorOpen(true);
    };

    const onClickConfirm = async (event) => {
        event.preventDefault();
        if(confirmingOrder)return
        let errorType = ""
        try{
            if(!adrCI){
                setAdrCIError("Debe poner un # de Carnet de Identidad.")
                errorType = "9"
            }
            else{
                const adrCINumber = parseInt(adrCI)
                if(!adrCINumber && adrCINumber.length !== 11){
                    setAdrCIError("Debe poner un número de 11 dígitos.")
                    errorType = "9"
                }
            }
            if(!adrPhone){
                setAdrPhoneError("Debe poner un # de Teléfono.")
                errorType = "8"
            }
            else{
                const adrPhoneNumber = parseInt(adrPhone)
                if(!adrPhoneNumber){
                    setAdrPhoneError("Debe poner un número válido.")
                    errorType = "8"
                }
            }
            if(adrMunicipalitySelected === 'Municipio'){
                setAdrMunicipalitySelectedError("Debe poner un Municipio.")
                errorType = "7"
            }
            if(adrRegionSelected === 'Provincia'){
                setAdrRegionSelectedError("Debe poner una Provincia.")
                errorType = "6"
            }
            if(!adrAddress){
                setAdrAddressError("Debe poner una dirección.")
                errorType = "5"
            }
            if(!adrName){
                setAdrNameError("Debe poner el nombre y los apellidos.")
                errorType = "4"
            }
            if(!remCI){
                setRemCIError("Debe poner un # de Carnet de Identidad o Pasaporte.")
                errorType = "3"
            }
            if(!remPhone){
                setRemPhoneError("Debe poner un # de Teléfono.")
                errorType = "2"
            }
            else{
                const remPhoneNumber = parseInt(remPhone)
                if(!remPhoneNumber){
                    setRemPhoneError("Debe poner un número válido.")
                    errorType = "2"
                }
            }
            if(!remName){
                setRemNameError("Debe poner el nombre y los apellidos.")
                errorType = "1"
            }
            if(errorType){
                switch(errorType){
                    case "1":{
                        handleRemNameErrorTooltipOpen()
                        break;
                    }
                    case "2":{
                        handleRemPhoneErrorTooltipOpen()
                        break;
                    }
                    case "3":{
                        handleRemCIErrorTooltipOpen()
                        break;
                    }
                    case "4":{
                        handleAdrNameErrorTooltipOpen()
                        break;
                    }
                    case "5":{
                        handleAdrAddressErrorTooltipOpen()
                        break;
                    }
                    case "6":{
                        handleAdrRegionErrorTooltipOpen()
                        break;
                    }
                    case "7":{
                        handleAdrMunicipalityErrorTooltipOpen()
                        break;
                    }
                    case "8":{
                        handleAdrPhoneErrorTooltipOpen()
                        break;
                    }
                    case "9":{
                        handleAdrCIErrorTooltipOpen()
                        break;
                    }
                    default: 
                        break;
                }
            }
            else{
                setConfirmingOrder(true)
                try{
                    if(otherProducts.length > 0){
                        setOpenOtherInProcessSnack(true)
                        let order = {
                            user : user._id,
                            totalAmount: totalOthersPrice + fastDeliveryAmount,
                            sender: {
                                name: remName,
                                phone: remPhone,
                                ci: remCI
                            },
                            addressee: {
                                name: adrName,
                                address: adrAddress + ', ' + adrMunicipalitySelected + ', ' + adrRegionSelected,
                                phone: adrPhone,
                                ci: adrCI
                            },
                            products: otherProducts,
                            walletPayment: wallet >= (totalOthersPrice + fastDeliveryAmount)? totalOthersPrice+ fastDeliveryAmount : wallet
                        }
                        const requestOptions = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json',
                                        'Authorization': `${user.accessToken}`
                            },
                            body: JSON.stringify(
                                order  
                            )
                        };
                        let result = await fetch(`${url}/api/orders`, requestOptions);
                        //console.log(result)
                        if(result.status === 200){
                            setOpenOtherSuccessSnack(true)
                        }
                        else{
                            setOpenApiErrorSnack(true)
                            setConfirmingOrder(false)
                            return
                        }
                    }
                    dispatch(restart_cart_action())
                    setOpenRedirectingSnack(true)
                }
                catch(e){
                    setConfirmingOrder(false)
                    setOpenApiErrorSnack(true)
                }
            }
        }
        catch(e){
            //console.log("ERROR")
            console.log(e)
            setConfirmingOrder(false)
        }
    }

    return(
        <React.Fragment>
            <Snackbar open={openApiErrorSnack} autoHideDuration={2000} onClose={handleCloseApiErrorSnack}>
                <Alert onClose={handleCloseApiErrorSnack} severity="error">
                    No se pudo crear el pedido. Revise su conexión a Internet.
                </Alert>
            </Snackbar>
            <Snackbar open={openCartSnack} autoHideDuration={5000} onClose={handleCloseCartSnack}>
                <Alert onClose={handleCloseCartSnack} severity="warning">
                    {cartText}
                </Alert>
            </Snackbar>
            <Snackbar open={openCartErrorSnack} autoHideDuration={3000} onClose={handleCloseCartErrorSnack}>
                <Alert onClose={handleCloseCartErrorSnack} severity="error">
                    No se pudo actualizar el carrito. Revise su conexión a internet.
                </Alert>
            </Snackbar>
            <Snackbar open={openOtherInProcessSnack} autoHideDuration={2000} onClose={handleCloseOtherInProcessSnack}>
                <Alert onClose={handleCloseOtherInProcessSnack} severity="info">
                    Creando Pedido
                </Alert>
            </Snackbar>
            <Snackbar open={openOtherSuccessSnack} autoHideDuration={2000} onClose={handleCloseOtherSuccessSnack}>
                <Alert onClose={handleCloseOtherSuccessSnack} severity="success">
                    Pedido Creado
                </Alert>
            </Snackbar>
            <Snackbar open={openRedirectingSnack} autoHideDuration={1000} onClose={handleCloseRedirectingSnack}>
                <Alert onClose={handleCloseRedirectingSnack} severity="success">
                    Redireccionando a Página de Pedidos
                </Alert>
            </Snackbar>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="make order"
                aria-describedby="data necessary to process order"
                >
                <div className={classes.modalPaper}>
                    <IconButton size="large" onClick={handleClose} aria-label="warning" className={classes.cancelButton}>
                        <Cancel />
                    </IconButton> 
                    <Typography className={classes.cartTypeText} style={{marginBottom: '10px'}}>Remitente</Typography>
                    <div className={classes.inputHolder}>
                        <Person className={classes.colorIcon}/>
                        <InputBase
                            onClick={() => 
                                {
                                handleTooltipErrors('remName')
                                }}
                            elevation={4}
                            placeholder="Nombre(s) y Apellidos"
                            value={remName}
                            onChange={handleChangeRemName}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search', maxLength:'80' }}
                        />
                        {remNameError &&
                        <div>
                            <MuiThemeProvider theme={themeMUI}>
                                <Tooltip classes={{arrow: classes.arrow}}
                                    arrow
                                    PopperProps={{
                                    disablePortal: true,
                                    }}
                                    onClose={handleRemNameErrorTooltipClose}
                                    open={remNameErrorOpen}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={remNameError}
                                >
                                    <IconButton onClick={handleRemNameErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                        <WarningAmberIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </MuiThemeProvider>
                        </div>                       
                        }
                    </div>
                    
                    <div className={classes.inputHolderFlex}>
                        <div style={{flex: 1}} className={classes.inputHolder}>
                            <LocalPhoneIcon className={classes.colorIcon}/>
                            <InputBase
                                onClick={() => 
                                    {
                                    handleTooltipErrors('remPhone')
                                    }}
                                elevation={4}
                                type="text"
                                placeholder="Teléfono"
                                value={remPhone}
                                onChange={handleChangeRemPhone}
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search', maxLength:'20' }}
                            />
                            {remPhoneError &&
                            <div>
                                <MuiThemeProvider theme={themeMUI}>
                                    <Tooltip classes={{arrow: classes.arrow}}
                                        arrow
                                        PopperProps={{
                                        disablePortal: true,
                                        }}
                                        onClose={handleRemPhoneErrorTooltipClose}
                                        open={remPhoneErrorOpen}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        title={remPhoneError}
                                    >
                                        <IconButton onClick={handleRemPhoneErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                            <WarningAmberIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </MuiThemeProvider>
                            </div>                       
                            }
                        </div>
                        <div className={classes.dividerLongSize}/>
                        <div style={{flex: 1}} className={classes.inputHolder}>
                            <CreditCard className={classes.colorIcon}/>
                            <InputBase
                                onClick={() => 
                                    {
                                    handleTooltipErrors('remCI')
                                    }}
                                elevation={4}
                                placeholder="CI o Pasaporte"
                                value={remCI}
                                onChange={handleChangeRemCI}
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search', maxLength:'20' }}
                            />
                            {remCIError &&
                            <div>
                                <MuiThemeProvider theme={themeMUI}>
                                    <Tooltip classes={{arrow: classes.arrow}}
                                        arrow
                                        PopperProps={{
                                        disablePortal: true,
                                        }}
                                        onClose={handleRemCIErrorTooltipClose}
                                        open={remCIErrorOpen}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        title={remCIError}
                                    >
                                        <IconButton onClick={handleRemCIErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                            <WarningAmberIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </MuiThemeProvider>
                            </div>                       
                            }
                        </div>
                    </div>
                    <Typography className={classes.cartTypeText} style={{marginBottom: '5px', marginTop: '20px'}}>Destinatario</Typography>
                    <Typography style={{marginBottom: '10px', marginTop: '5px', opacity: '0.8', fontSize: '14px', color: colors.redError}}>
                        NOTA: Deben coincidir con los datos del carnet de identidad. Debe incluir el BARRIO en la dirección.
                    </Typography>
                    <div className={classes.inputHolder}>
                        <Person className={classes.colorIcon}/>
                        <InputBase
                            onClick={() => 
                                {
                                    handleTooltipErrors('adrName')
                                }}
                            elevation={4}
                            placeholder="Nombre(s) y Apellidos"
                            value={adrName}
                            onChange={handleChangeAdrName}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search', maxLength:'80' }}
                        />
                        {adrNameError &&
                        <div>
                            <MuiThemeProvider theme={themeMUI}>
                                <Tooltip classes={{arrow: classes.arrow}}
                                    arrow
                                    PopperProps={{
                                    disablePortal: true,
                                    }}
                                    onClose={handleAdrNameErrorTooltipClose}
                                    open={adrNameErrorOpen}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={adrNameError}
                                >
                                    <IconButton onClick={handleAdrNameErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                        <WarningAmberIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </MuiThemeProvider>
                        </div>                       
                        }
                    </div>

                    <div className={classes.inputHolder}>
                        <LocationOn className={classes.colorIcon} style={{alignSelf: 'flex-start', marginTop: '4px'}}/>
                        <InputBase
                            onClick={() => 
                                {
                                handleTooltipErrors('adrAddress')
                                }}
                            elevation={4}
                            placeholder="Dirección"
                            value={adrAddress}
                            minRows={4}
                            maxRows={4}
                            multiline
                            onChange={handleChangeAdrAddress}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search', maxLength:'160' }}
                        />
                        {adrAddressError &&
                        <div style={{alignSelf: 'flex-start'}}>
                            <MuiThemeProvider theme={themeMUI}>
                                <Tooltip classes={{arrow: classes.arrow}}
                                    arrow
                                    PopperProps={{
                                    disablePortal: true,
                                    }}
                                    onClose={handleAdrAddressErrorTooltipClose}
                                    open={adrAddressErrorOpen}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={adrAddressError}
                                >
                                    <IconButton onClick={handleAdrAddressErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                        <WarningAmberIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </MuiThemeProvider>
                        </div>                       
                        }
                    </div>

                    <div className={classes.inputHolderFlex}>
                        <div style={{flex: 1}} className={classes.inputHolder}>
                            <FormControl className={classes.formControl}>
                                <Select
                                value={adrRegionSelected}
                                onChange={handleChangeAdrRegion}
                                disableUnderline
                                className={classes.dataSelect}
                                inputProps={{ 'aria-label': 'region' }}
                                >
                                    {provinces.map(provinceName => (  
                                        <MenuItem value={provinceName}>{provinceName}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {adrRegionSelectedError &&
                            <div>
                                <MuiThemeProvider theme={themeMUI}>
                                    <Tooltip classes={{arrow: classes.arrow}}
                                        arrow
                                        PopperProps={{
                                        disablePortal: true,
                                        }}
                                        onClose={handleAdrRegionErrorTooltipClose}
                                        open={adrRegionSelectedErrorOpen}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        title={adrRegionSelectedError}
                                    >
                                        <IconButton onClick={handleAdrRegionErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                            <WarningAmberIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </MuiThemeProvider>
                            </div>                       
                            }
                        </div>
                        <div className={classes.dividerLongSize}/>
                        <div style={{flex: 1}} className={classes.inputHolder}>
                        <FormControl className={classes.formControl}>
                            <Select
                            value={adrMunicipalitySelected}
                            onChange={handleChangeAdrMunicipality}
                            disableUnderline
                            className={classes.dataSelect}
                            inputProps={{ 'aria-label': 'municipality' }}
                            >
                                <MenuItem value={"Municipio"}>Municipio</MenuItem>
                                {municipalities[adrMunicipalityIndex].map(municipalityName => (  
                                    <MenuItem value={municipalityName}>{municipalityName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                            {adrMunicipalitySelectedError &&
                            <div>
                                <MuiThemeProvider theme={themeMUI}>
                                    <Tooltip classes={{arrow: classes.arrow}}
                                        arrow
                                        PopperProps={{
                                        disablePortal: true,
                                        }}
                                        onClose={handleAdrMunicipalityErrorTooltipClose}
                                        open={adrMunicipalitySelectedErrorOpen}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        title={adrMunicipalitySelectedError}
                                    >
                                        <IconButton onClick={handleAdrMunicipalityErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                            <WarningAmberIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </MuiThemeProvider>
                            </div>                       
                            }
                        </div>
                    </div>
                    
                    <div className={classes.inputHolderFlex}>
                        <div style={{flex: 1}} className={classes.inputHolder}>
                            <LocalPhoneIcon className={classes.colorIcon}/>
                            <InputBase
                                onClick={() => 
                                    {
                                    handleTooltipErrors('adrPhone')
                                    }}
                                elevation={4}
                                type="text"
                                placeholder="Teléfono (Cuba)"
                                value={adrPhone}
                                onChange={handleChangeAdrPhone}
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search', maxLength:'20' }}
                            />
                            {adrPhoneError &&
                            <div>
                                <MuiThemeProvider theme={themeMUI}>
                                    <Tooltip classes={{arrow: classes.arrow}}
                                        arrow
                                        PopperProps={{
                                        disablePortal: true,
                                        }}
                                        onClose={handleAdrPhoneErrorTooltipClose}
                                        open={adrPhoneErrorOpen}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        title={adrPhoneError}
                                    >
                                        <IconButton onClick={handleAdrPhoneErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                            <WarningAmberIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </MuiThemeProvider>
                            </div>                       
                            }
                        </div>
                        <div className={classes.dividerLongSize}/>
                        <div style={{flex: 1}} className={classes.inputHolder}>
                            <CreditCard className={classes.colorIcon}/>
                            <InputBase
                                onClick={() => 
                                    {
                                    handleTooltipErrors('adrCI')
                                    }}
                                elevation={4}
                                placeholder="C. de Identidad"
                                value={adrCI}
                                onChange={handleChangeAdrCI}
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search', maxLength:'20' }}
                            />
                            {adrCIError &&
                            <div>
                                <MuiThemeProvider theme={themeMUI}>
                                    <Tooltip classes={{arrow: classes.arrow}}
                                        arrow
                                        PopperProps={{
                                        disablePortal: true,
                                        }}
                                        onClose={handleAdrCIErrorTooltipClose}
                                        open={adrCIErrorOpen}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        title={adrCIError}
                                    >
                                        <IconButton onClick={handleAdrCIErrorTooltipOpen} aria-label="warning" className={classes.errorButton}>
                                            <WarningAmberIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </MuiThemeProvider>
                            </div>                       
                            }
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        {confirmingOrder?
                            <Button
                            className={classes.confirmButton} style={{width: '118px'}}
                            classes={{
                                label: classes.label, // class name, e.g. `classes-nesting-label-x`
                            }}
                            ><CircularProgress size={24} style={{color: colors.secondary}}/></Button>
                        :
                            <Button onClick={onClickConfirm} className={classes.confirmButton} variant='contained'>
                                CONFIRMAR
                            </Button>
                        }
                    </div>
                </div>
            </Modal>
            <Navbar />
            <div className={classes.extraBar} />
            <div className={classes.cartHolder}>
                <Card style={{borderRadius: '10px'}}> 
                    <div className={classes.cardContainer}>           
                        <Typography className={classes.informativeCartNote}>
                            NOTA: El carrito puede tener un máximo de 200 puntos. Si desea
                            comprar más productos haga el pedido para añadir productos a un nuevo
                            carrito.
                        </Typography>
                    </div>
                    <div className={classes.cartContainer}>
                        {otherProducts.length > 0 &&
                        <div className={classes.otherProductsContainer}>
                            <Typography className={classes.cartTypeText} style={{textAlign: 'center'}}>CARRITO</Typography>
                            <div className={classes.otherProductsItems} style={{marginTop: '15px'}}>
                                <div>
                                    {otherProducts.map((element, index) => {
                                        return elementCard(element, index)
                                    })}
                                    <div style={{justifyContent: 'flex-end', display: 'flex'}}>
                                        <div>
                                            <Typography className={classes.elementHeader}>{totalOthersPrice + guideCut + packaging >= 350? 'Embalaje' : 'Corte de Guía y Embalaje'}</Typography>
                                            {/* <Typography className={classes.elementHeader}>Embalaje</Typography> */}
                                            {(wallet > 0) && <Typography className={classes.elementHeader}>Uso de Saldo de mi cartera</Typography>}
                                        </div>
                                        <div>
                                            <Typography className={classes.elementUnit}>{fastDeliveryAmount} usd</Typography>
                                            {/* <Typography className={classes.elementUnit}>{packaging} usd</Typography> */}
                                            {(wallet > 0) && <Typography className={classes.elementUnit}>-{wallet >= (totalOthersPrice+fastDeliveryAmount)? totalOthersPrice+fastDeliveryAmount : wallet} usd</Typography>}
                                        </div>
                                    </div>
                                    <div className={classes.elementTotal}>
                                        <div>
                                            <Typography className={classes.elementHeader}>PRECIO TOTAL</Typography>
                                            <Typography className={classes.elementHeader}>PUNTOS</Typography>
                                        </div>
                                        <div>
                                            <Typography className={classes.elementUnit} style={{marginLet: 'auto'}}>{wallet >= (totalOthersPrice+fastDeliveryAmount)? 0 : reduceDecimalValue(totalOthersPrice+fastDeliveryAmount-wallet)} usd</Typography>
                                            <Typography className={classes.elementUnit} style={{marginLet: 'auto'}}>{reduceDecimalValue(cart_store_selector.points)} pts</Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        {(otherProducts.length === 0)?
                            [cart_store_selector.initLoading?
                                <div className={classes.emptyCartContainer}>
                                    <CircularProgress style={{marginLeft: 'auto', marginRight: 'auto', display: 'block', marginBottom: '10px', height: '50px', width: '50px'}}/>
                                    <Typography className={classes.emptyCartPrimaryText}>Cargando Carrito...</Typography>
                                </div>
                            :
                            <div className={classes.emptyCartContainer}>
                                <RemoveShoppingCart className={classes.emptyCartIcon}/>
                                <Typography className={classes.emptyCartPrimaryText}>Carrito Vacío</Typography>
                                <Typography className={classes.emptyCartSecondaryText}>Agregue un Producto y aparecerá aquí :)</Typography>
                            </div>
                            ]
                        :
                            <Button onClick={handleOpen} className={classes.makeOrderButton} variant='contained'>
                                HACER PEDIDO
                            </Button>
                        }
                    </div>
                </Card>
                <div style={{height: '20px'}}/>
            </div>
        </React.Fragment>
    )
}

export default CartPage