import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.jsx';
import reportWebVitals, { sendToVercelAnalytics } from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker'
import { subscribeUser } from './subscription'

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);
serviceWorker.register()
subscribeUser()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// function sendToAnalytics(metric) {
//   console.log(metric)
//   const body = JSON.stringify(metric);
//   const url = 'https://vitals.vercel-analytics.com/v1/vitals';

//   // Use `navigator.sendBeacon()` if available, falling back to `fetch()`
//   if (navigator.sendBeacon) {
//     navigator.sendBeacon(url, body);
//   } else {
//     fetch(url, { body, method: 'POST', keepalive: true });
//   }
// }
reportWebVitals(sendToVercelAnalytics);
